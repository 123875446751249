const permissions = {
  SALES: {
    users: {
      enabled: false,
      list: false,
      show: false,
      edit: false,
      create: false,
      delete: false,
    },
    listings: {
      enabled: true,
      list: true,
      show: true,
      edit: true,
      create: true,
      delete: true,
    },
    contracts: {
      enabled: true,
      list: true,
      show: true,
      edit: true,
      create: true,
      delete: true,
    },
    receipts: {
      enabled: false,
      list: false,
      show: false,
      edit: false,
      create: false,
      delete: false,
    },
  },
  ADMIN: {
    users: {
      enabled: true,
      list: true,
      show: true,
      edit: true,
      create: true,
      delete: true,
    },
    listings: {
      enabled: true,
      list: true,
      show: true,
      edit: true,
      create: true,
      delete: true,
    },
    contracts: {
      enabled: true,
      list: true,
      show: true,
      edit: true,
      create: true,
      delete: true,
    },
    receipts: {
      enabled: true,
      list: true,
      show: true,
      edit: true,
      create: true,
      delete: true,
    },
    authentication_log: {
      enabled: true,
      list: true,
      show: false,
      edit: false,
      create: false,
      delete: false,
    },
  },
};

export default permissions;
