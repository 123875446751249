import {
  Box,
  Chip,
  Divider,
  Fade,
  Grid,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import PrintIcon from "@material-ui/icons/Print";
import SyncIcon from "@material-ui/icons/Sync";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { get, upperFirst } from "lodash";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  ArrayField,
  BooleanField,
  Button,
  Confirm,
  Datagrid,
  DateField,
  EditButton,
  FileField,
  // Tab,
  ImageField,
  NumberField,
  ReferenceManyField,
  RichTextField,
  SelectField,
  Show,
  ShowButton,
  TextField,
  TopToolbar,
  useGetIdentity,
  useNotify,
  useRefresh,
  useShowController,
  useUpdate,
} from "react-admin";
import { Link } from "react-router-dom";
import { useNewACL } from "../acl";
import {
  certificateType,
  communitySpecList,
  externalSpecList,
  internalSpecList,
  listingType,
} from "../mapping";

import { Alert, Skeleton } from "@material-ui/lab";
import useAxios from "axios-hooks";
import moment from "moment";
import { CompactShowLayout, RaBox, RaGrid } from "ra-compact-ui";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LineIcon,
  LineShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { R123DevMode } from "../components";

RichTextField.displayName = "RichTextField";
BooleanField.displayName = "BooleanField";
DateField.displayName = "DateField";

const Actions = ({
  permissions,
  basePath,
  data,
  resource,
  refresh123 = () => null,
}) => {
  const contract = useNewACL("contracts");
  const { identity } = useGetIdentity();
  const [open, setOpen] = useState(false);
  const [open123, setOpen123] = useState(false);
  const [update, { loading }] = useUpdate();
  const refresh = useRefresh();
  const notify = useNotify();
  const [{ loading: loading123, error: error123 }, refetch] = useAxios(
    {},
    { manual: true }
  );

  useEffect(() => {
    if (!loading123 && error123) {
      console.log(error123);
      setOpen123(false);
      notify(error123.message, "error");
    }
  }, [loading123, error123]);

  const handleOpen = () => setOpen(!open);
  const handleOpen123 = () => setOpen123(!open123);

  const handleConfirm = () => {
    update(resource, data?.id, { ...data, verified: true }, data, {
      onSuccess: () => refresh(),
    });
  };

  const handleConfirm123 = async () => {
    try {
      const data123 = await refetch({
        url: `/api/rumah123/listing/${data?.id}`,
        method: "POST",
      });

      console.log(data123);
      setOpen123(false);

      if (data123.data.code === 200) {
        notify(
          "Sukses kirim permintaan sinkronisasi data ke Rumah 123, silahkan cek status permintaan",
          "success"
        );
        refresh123();
      }
    } catch (error) {}
  };

  return (
    <TopToolbar>
      {contract.create && data?.active === true && (
        <Box clone marginRight={1}>
          <Button
            // onClick={goTo("/login")}
            component={Link}
            to={{
              pathname: "/contracts/create",
              state: { record: { listing: { id: data?.id } } },
            }}
            label="Buat Kontrak"
          >
            <AddIcon />
          </Button>
        </Box>
      )}
      {identity?.role === "ADMIN" &&
        data?.active === true &&
        data?.verified?.is === false && (
          <>
            <Box clone marginRight={1}>
              <Button onClick={handleOpen} label="Aktifkan Pemasaran">
                <CheckIcon />
              </Button>
            </Box>
            <Confirm
              isOpen={open}
              loading={loading}
              title="Set PAP ini dapat dipasarkan?"
              onConfirm={handleConfirm}
              onClose={handleOpen}
            />
          </>
        )}
      <Box clone marginRight={1}>
        <Button
          // onClick={goTo("/login")}
          component="a"
          href={`/api/listings/${data?.id}?print=form`}
          target="_blank"
          label="Cetak Formulir"
        >
          <PrintIcon />
        </Button>
      </Box>
      {identity?.role === "ADMIN" && (
        <Box clone marginRight={1}>
          <Button
            // onClick={goTo("/login")}
            component="a"
            href={`/api/listings/${data?.id}?print=closing`}
            target="_blank"
            label="Cetak Form Closing"
          >
            <PrintIcon />
          </Button>
        </Box>
      )}
      {(identity?.role === "ADMIN" ||
        (identity?.role === "SALES" && data?.active === true)) && (
        <Box clone marginRight={1}>
          <Button
            // onClick={goTo("/login")}
            component={Link}
            to={{
              pathname: `/catalog-detail/${data?.id}`,
            }}
            label="Lihat di katalog"
          >
            <VisibilityIcon />
          </Button>
        </Box>
      )}
      {(identity?.role === "ADMIN" ||
        (identity?.role === "SALES" &&
          data?.active === true &&
          identity?.rumah_id)) &&
        data?.verified_at !== null && (
          <>
            <Box clone marginRight={1}>
              <Button onClick={handleOpen123} label="Sinkron ke Rumah123">
                <SyncIcon />
              </Button>
            </Box>
            <Confirm
              isOpen={open123}
              loading={loading123}
              title="Sinkronkan status PAP dengan Rumah123?"
              onConfirm={handleConfirm123}
              onClose={handleOpen123}
            />
          </>
        )}
      {(identity?.role === "ADMIN" ||
        (identity?.role === "SALES" && data?.active === true)) && (
        <EditButton basePath={basePath} record={data} />
      )}
      {/* {permissions === "admin" && (
      <DeleteButton basePath={basePath} record={data} resource={resource} />
    )} */}
    </TopToolbar>
  );
};

const ChipsField = ({ record, choices, source, label, ...props }) => {
  const values = get(record, source);

  return (
    <>
      <Typography variant="caption" color="textSecondary" gutterBottom>
        {label}
      </Typography>
      <Grid container spacing={1}>
        {(values || []).map((v, idx) => (
          <Grid item key={idx}>
            <Chip label={choices.find(({ id }) => v === id)?.name} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

ChipsField.displayName = "ChipsField";

const MapField = ({ record, label }) => (
  <>
    <Typography variant="caption" color="textSecondary" gutterBottom>
      {label}
    </Typography>
    {record.embed?.map ? (
      <Box clone border="none">
        <iframe
          src={record.embed?.map}
          // src={
          //   "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1973.8678097909187!2d115.02988698357872!3d-8.329047522646684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd1876f642ec5b3%3A0xe36472235e3b4e11!2sPujungan%2C%20Pupuan%2C%20Kabupaten%20Tabanan%2C%20Bali%2082163!5e0!3m2!1sid!2sid!4v1627393799468!5m2!1sid!2sid"
          // }
          width="100%"
          height="400"
          allowFullScreen
          loading="lazy"
        />
      </Box>
    ) : (
      <Typography variant="body2">Tidak tersedia</Typography>
    )}
  </>
);

MapField.displayName = "MapField";

const TabPanel = ({ value, currentValue, children }) => (
  <Fade in={value === currentValue} mountOnEnter unmountOnExit key={value}>
    <Box p={1}>{children}</Box>
  </Fade>
);

TabPanel.displayName = "TabPanel";

const R123List = forwardRef(({ id }, ref) => {
  const notify = useNotify();
  const [{ data, loading, error }, refetch] = useAxios(
    { url: `/api/rumah123/listing/${id}` },
    { manual: true }
  );

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (!loading && error) {
      notify(error.message, "error");
    }
  }, [loading, error]);

  useImperativeHandle(ref, () => ({
    refreshData: () => {
      refetch();
    },
  }));

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell component="th">Tanggal</TableCell>
          <TableCell component="th">Status Ubah Listing</TableCell>
          <TableCell component="th">Status Ubah Profil</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {!loading && !error && data
          ? data
              .sort(
                (a, b) =>
                  moment(b.timestamp).unix() - moment(a.timestamp).unix()
              )
              .map((d, idx) => (
                <TableRow key={idx}>
                  <TableCell>
                    {moment(d.timestamp).format("DD MMM YYYY HH:mm")}
                  </TableCell>
                  <TableCell>
                    {d.listing.message}
                    {d.listing.url_listing ? (
                      <Box>
                        <Button
                          variant="outlined"
                          component="a"
                          target="_blank"
                          href={d.listing.url_listing}
                          label="Lihat di Rumah 123"
                        />
                      </Box>
                    ) : (
                      <></>
                    )}
                  </TableCell>
                  <TableCell>{d.profile.message}</TableCell>
                </TableRow>
              ))
          : [...Array(5).keys()].map((k) => (
              <TableRow key={k}>
                <TableCell colSpan={3}>
                  <Skeleton width="100%" height={48} />
                </TableCell>
              </TableRow>
            ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell colSpan={3}>
            <Button
              label="Refresh Status"
              onClick={() => refetch()}
              disabled={loading}
            />
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
});

R123List.displayName = "R123List";

const PapShow = (props) => {
  const { identity } = useGetIdentity();
  const [tab, setTab] = useState("pap");
  const [internalSpec, setInternalSpec] = useState(internalSpecList);
  const [externalSpec, setExternalSpec] = useState(externalSpecList);
  const [communitySpec, setCommunitySpec] = useState(communitySpecList);
  const ref = useRef(null);

  const { record } = useShowController(props);

  const contract = useNewACL("contracts");

  useEffect(() => {
    if (record) {
      setInternalSpec([
        ...internalSpec,
        ...(record.facilities.specification || [])
          .map((s) =>
            !internalSpec.find((i) => i.id === s.id)
              ? { id: s.id, name: upperFirst(s.type) }
              : null
          )
          .filter(Boolean),
      ]);
      setExternalSpec([
        ...externalSpec,
        ...(record.facilities.internal || [])
          .map((s) =>
            !externalSpec.find((i) => i.id === s.id)
              ? { id: s.id, name: upperFirst(s.type) }
              : null
          )
          .filter(Boolean),
      ]);
      setCommunitySpec([
        ...communitySpec,
        ...(record.facilities.external || [])
          .map((s) =>
            !communitySpec.find((i) => i.id === s.id)
              ? { id: s.id, name: upperFirst(s.type) }
              : null
          )
          .filter(Boolean),
      ]);
    }
  }, [record]);

  const handleTabChange = (event, newValue) => setTab(newValue);

  const handleRefresh123 = () => {
    if (tab !== "r123") {
      setTab("r123");
    } else if (ref.current) {
      ref.current.refreshData();
    }
  };

  return (
    <Show actions={<Actions refresh123={handleRefresh123} />} {...props}>
      <CompactShowLayout layoutComponents={[TabPanel, RaBox, RaGrid]}>
        <RaGrid
          container
          justify="space-between"
          alignItems="center"
          alignContent="center"
        >
          <RaGrid item>
            <TextField
              source="code"
              label="No."
              variant="h4"
              color={record?.active === true ? "primary" : "error"}
            />
          </RaGrid>
          <RaGrid item>
            <Box>
              <Typography
                variant="caption"
                color="textSecondary"
                component="div"
                gutterBottom
              >
                Share
              </Typography>
              <Grid
                container
                spacing={1}
                alignItems="center"
                alignContent="center"
              >
                <Grid item>
                  <EmailShareButton
                    disabled={record?.active !== true}
                    title={`[MYPRO INDONESIA] ${record?.title}`}
                    url={`${window.location.protocol}//${window.location.hostname}/c/${record?.code}`}
                  >
                    <EmailIcon size={32} round={true} />
                  </EmailShareButton>
                </Grid>
                <Grid item>
                  <FacebookShareButton
                    disabled={record?.active !== true}
                    title={`[MYPRO INDONESIA] ${record?.title}`}
                    url={`${window.location.protocol}//${window.location.hostname}/c/${record?.code}`}
                  >
                    <FacebookIcon size={32} round={true} />
                  </FacebookShareButton>
                </Grid>
                <Grid item>
                  <LineShareButton
                    disabled={record?.active !== true}
                    title={`[MYPRO INDONESIA] ${record?.title}`}
                    url={`${window.location.protocol}//${window.location.hostname}/c/${record?.code}`}
                  >
                    <LineIcon size={32} round={true} />
                  </LineShareButton>
                </Grid>
                <Grid item>
                  <TelegramShareButton
                    disabled={record?.active !== true}
                    title={`[MYPRO INDONESIA] ${record?.title}`}
                    url={`${window.location.protocol}//${window.location.hostname}/c/${record?.code}`}
                  >
                    <TelegramIcon size={32} round={true} />
                  </TelegramShareButton>
                </Grid>
                <Grid item>
                  <TwitterShareButton
                    disabled={record?.active !== true}
                    title={`[MYPRO INDONESIA] ${record?.title}`}
                    url={`${window.location.protocol}//${window.location.hostname}/c/${record?.code}`}
                  >
                    <TwitterIcon size={32} round={true} />
                  </TwitterShareButton>
                </Grid>
                <Grid item>
                  <WhatsappShareButton
                    disabled={record?.active !== true}
                    title={`[MYPRO INDONESIA] ${record?.title}`}
                    url={`${window.location.protocol}//${window.location.hostname}/c/${record?.code}`}
                  >
                    <WhatsappIcon size={32} round={true} />
                  </WhatsappShareButton>
                </Grid>
              </Grid>
            </Box>
          </RaGrid>
        </RaGrid>
        <Divider component="div" />
        <Tabs variant="scrollable" value={tab} onChange={handleTabChange}>
          <Tab value="pap" label="Data PAP" />
          <Tab value="katalog" label="Data E-Katalog" />
          <Tab value="r123" label="Data Rumah123" />
          <Tab value="kontrak" label="Daftar Kontrak" />
        </Tabs>
        <TabPanel value="pap" currentValue={tab}>
          <RaGrid container spacing={2}>
            <RaGrid item md={6}>
              <RaBox p={1}>
                <Typography variant="h6" gutterBottom>
                  PEMILIK / VENDOR (PIHAK I)
                </Typography>
                <TextField source="owner.name" label="Nama Lengkap" />
                <TextField source="owner.nik" label="NIK" />
                <TextField source="owner.address" label="Alamat" />
                <TextField source="owner.postal_code" label="Kodepos" />
                <RaGrid container spacing={1}>
                  <RaGrid item md={6}>
                    <TextField source="owner.phone" label="No. Telepon" />
                  </RaGrid>
                  <RaGrid item md={6}>
                    <TextField source="owner.fax" label="No. Fax" />
                  </RaGrid>
                </RaGrid>
                <TextField source="owner.cellphone" label="No. HP" />
                <TextField source="owner.email" label="Email" />
                <Typography variant="h6" gutterBottom>
                  SALES CONSULTANT MYPRO (PIHAK II)
                </Typography>
                <TextField source="sales.name" label="Nama Lengkap" />
                <TextField source="sales.phone" label="No. HP" />
                <TextField source="sales.email" label="Email" />
                <TextField source="branch" label="Cabang" />
              </RaBox>
            </RaGrid>
            <RaGrid item md={6}>
              <RaBox p={1}>
                <Typography variant="h6" gutterBottom>
                  DATA PROPERTI
                </Typography>
                <TextField source="property.address" label="Alamat" />
                <RaGrid container spacing={1}>
                  <RaGrid item xs={6}>
                    <TextField source="property.rt" label="RT" />
                  </RaGrid>
                  <RaGrid item xs={6}>
                    <TextField source="property.rw" label="RW" />
                  </RaGrid>
                </RaGrid>
                <RaGrid container spacing={1}>
                  <RaGrid item md={6}>
                    <TextField source="property.village" label="Kelurahan" />
                  </RaGrid>
                  <RaGrid item md={6}>
                    <TextField source="property.district" label="Kecamatan" />
                  </RaGrid>
                </RaGrid>
                <RaGrid container spacing={1}>
                  <RaGrid item md={6}>
                    <TextField source="property.city" label="Kota" />
                  </RaGrid>
                  <RaGrid item md={6}>
                    <TextField source="property.province" label="Provinsi" />
                  </RaGrid>
                </RaGrid>
                <RaGrid container spacing={1}>
                  <RaGrid item md={6}>
                    <NumberField source="area.land" label="Luas Tanah (M2)" />
                  </RaGrid>
                  <RaGrid item md={6}>
                    <NumberField
                      source="area.building"
                      label="Luas Bangunan (M2)"
                    />
                  </RaGrid>
                </RaGrid>
                <TextField source="view" label="Hadap / View" />
                <NumberField
                  source="price.sell"
                  label="Harga Jual"
                  locales="id-ID"
                  options={{ style: "currency", currency: "IDR" }}
                />
                <RaGrid
                  container
                  spacing={2}
                  alignItems="center"
                  alignContent="center"
                >
                  <RaGrid item md={6}>
                    <NumberField
                      source="price.rent"
                      label="Harga Sewa"
                      locales="id-ID"
                      options={{ style: "currency", currency: "IDR" }}
                    />
                  </RaGrid>
                  <RaGrid item md={6}>
                    <SelectField
                      source="rent.type"
                      choices={[
                        { id: "monthly", name: "Per Bulan" },
                        {
                          id: "annually",
                          name: "Per Tahun",
                        },
                      ]}
                      label="Jenis Sewa"
                    />
                  </RaGrid>
                </RaGrid>
                <RaGrid
                  container
                  spacing={2}
                  alignItems="center"
                  alignContent="center"
                >
                  <RaGrid item md={4}>
                    <NumberField source="rent.duration" label="Masa Sewa" />
                  </RaGrid>
                  <RaGrid item md={4}>
                    <SelectField
                      source="rent.duration_type"
                      choices={[
                        { id: "month", name: "Bulan" },
                        {
                          id: "year",
                          name: "Tahun",
                        },
                      ]}
                      label="Jenis Masa Sewa"
                    />
                  </RaGrid>
                  <RaGrid item md={4}>
                    <NumberField
                      source="rent.deposit"
                      label="Deposit"
                      locales="id-ID"
                      options={{ style: "currency", currency: "IDR" }}
                    />
                  </RaGrid>
                </RaGrid>
                <TextField source="condition" label="Kondisi" />
              </RaBox>
            </RaGrid>
          </RaGrid>
          <Divider component="div" />
          <RaGrid container spacing={1}>
            <RaGrid item md={6}>
              <RaBox p={1}>
                <SelectField
                  source="marketing.rights"
                  choices={[
                    { id: "open", name: "Open" },
                    {
                      id: "sole",
                      name: "Sole Agent",
                    },
                    {
                      id: "exclusive",
                      name: "Exclusive",
                    },
                  ]}
                  label="Jenis Hak Pemasaran"
                />
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                  gutterBottom
                >
                  {record?.marketing?.rights === "open"
                    ? "Pemilik bebas menjual sendiri serta menunjuk agen properti lain."
                    : record?.marketing?.rights === "sole"
                    ? "Pemilik dapat menjual sendiri namun hanya menunjuk satu agen properti, yaitu Mypro."
                    : record?.marketing?.rights === "exclusive"
                    ? "                      Pemilik tidak dapat menjual sendiri dan menyerahkan hak pemasaran sepenuhnya kepada Mypro."
                    : "-"}
                </Typography>
                <ChipsField
                  source="marketing.type"
                  choices={[
                    { id: "sell", name: "Dijual" },
                    { id: "auction", name: "Dilelang" },
                    {
                      id: "rent",
                      name: "Disewakan",
                    },
                  ]}
                  label="Jenis Pemasaran"
                />
                {(record?.marketing?.type || []).includes("sell") ? (
                  <SelectField
                    source="marketing.sell_type"
                    choices={[
                      {
                        id: "primary",
                        name: "Primary",
                      },
                      {
                        id: "secondary",
                        name: "Secondary",
                      },
                    ]}
                    label="Primary / Secondary"
                  />
                ) : (
                  <></>
                )}
                {(record?.marketing?.type || []).includes("auction") ? (
                  <NumberField
                    source="price.bank"
                    label="Limit Bank"
                    locales="id-ID"
                    options={{ style: "currency", currency: "IDR" }}
                  />
                ) : (
                  <></>
                )}
                <RaGrid
                  container
                  spacing={2}
                  alignItems="center"
                  alignContent="center"
                >
                  <RaGrid item>
                    <SelectField
                      source="marketing.deadline_type"
                      choices={[
                        {
                          id: "by-date",
                          name: "Sampai dengan tanggal",
                        },
                        {
                          id: "unrestricted",
                          name: "Sampai terjual / tersewa / informasi lebih lanjut dari pemilik",
                        },
                      ]}
                      label="Masa Berlaku Hak Pemasaran"
                    />
                  </RaGrid>
                  <RaGrid item>
                    <TextField
                      source="marketing.deadline_date"
                      label="Tanggal Akhir Hak Pemasaran"
                    />
                  </RaGrid>
                </RaGrid>
                <SelectField source="type" choices={listingType} label="Tipe" />
                <RaGrid container spacing={2}>
                  <RaGrid item>
                    <SelectField
                      source="certificate.type"
                      choices={certificateType}
                      label="Sertifikat"
                    />
                  </RaGrid>
                  <RaGrid item>
                    <TextField
                      source="certificate.expire"
                      label="Tanggal Akhir SHGB / Hak Pakai"
                    />
                  </RaGrid>
                  <RaGrid item>
                    <TextField
                      source="certificate.other"
                      label="Detail Lainnya"
                    />
                  </RaGrid>
                </RaGrid>
              </RaBox>
            </RaGrid>
            <RaGrid item md={6}>
              <Box p={1}>
                <Typography variant="h6" gutterBottom>
                  INFORMASI:
                </Typography>
                <Typography variant="body2">
                  1. Pemilik dan Sales Consultant (SC) untuk mengisi PAP ini
                  dengan lengkap dan jelas, agar pemasaran properti lebih
                  efektif.
                </Typography>
                <Typography variant="body2">
                  2. Setiap properti cukup didaftarkan dengan satu PAP.
                </Typography>
                <Typography variant="body2">
                  3. SC wajib menjelaskan seluruh ketentuan dalam PAP kepada
                  Pemilik dan menyerahkan satu copy PAP yang telah diisi lengkap
                  dan ditandatangani kepada Pemilik.
                </Typography>
                <Typography variant="body2">
                  4. Pemilik dianjurkan untuk selalu menghubungi SC/Principal
                  mengenai kondisi properti dalam PAP ini.
                </Typography>
                <Typography variant="h6" gutterBottom>
                  KETENTUAN MENGENAI HAK PEMASARAN EXCLUSIVE:
                </Typography>
                <Typography variant="body2">
                  1. SC wajib melaporkan seluruh aktivitas pemasaran dan
                  identitas peminat secara berkala kepada Pemilik.
                </Typography>
                <Typography variant="body2">
                  2. Mypro berhak atas jasa pemasaran, apabila selama masa Hak
                  Pemasaran Exclusive, Pemilik memasarkan sendiri atau melalui
                  jasa pihak lain, membatalkan PAP ini, atau melakukan perubahan
                  atas properti sehingga tidak lagi dapat dipasarkan.
                </Typography>
                <Typography variant="body2">
                  3. Mypro tetap berhak atas jasa pemasaran, apabila dalam waktu
                  kurang dari tiga bulan sejak Hak Pemasaran Exclusive berakhir
                  terjadi transaksi dengan pihak lain yang sudah pernah
                  diprospek oleh Mypro.
                </Typography>
                <Typography variant="body2">
                  4. Mypro berhak memasarkan properti melalui kerjasama dengan
                  agen properti lain atau pihak ketiga lainnya.
                </Typography>
              </Box>
            </RaGrid>
          </RaGrid>
          <Divider component="div" />
          <RaGrid container spacing={1} justify="space-evenly">
            <RaGrid item>
              <ArrayField source="facilities.specification" label="Spesifikasi">
                <Datagrid>
                  <SelectField
                    source="type"
                    choices={internalSpec}
                    label="Tipe"
                  />
                  <NumberField source="qty" label="Jumlah" />
                  <TextField source="description" label="Keterangan" />
                </Datagrid>
              </ArrayField>
            </RaGrid>
            <RaGrid item>
              <ArrayField source="facilities.internal" label="Fasilitas">
                <Datagrid>
                  <SelectField
                    source="type"
                    choices={externalSpec}
                    label="Tipe"
                  />
                  <NumberField source="qty" label="Jumlah" />
                </Datagrid>
              </ArrayField>
            </RaGrid>
            <RaGrid item>
              <ArrayField
                source="facilities.external"
                label="Fasilitas Lingkungan"
              >
                <Datagrid>
                  <SelectField
                    source="type"
                    choices={communitySpec}
                    label="Tipe"
                  />
                  <NumberField source="qty" label="Jumlah" />
                </Datagrid>
              </ArrayField>
            </RaGrid>
          </RaGrid>
          <Divider component="div" />
          <TextField source="additional.info" label="Keterangan Lainnya" />
          <Divider component="div" />
          <Box clone my={1}>
            <Typography>
              <Box fontWeight="bold" component="span">
                PIHAK I
              </Box>{" "}
              memberikan kuasa kepada{" "}
              <Box fontWeight="bold" component="span">
                PIHAK II
              </Box>{" "}
              untuk memasarkan properti tersebut diatas dengan ketentuan,
              sebagai berikut:
            </Typography>
          </Box>
          <ol>
            <li>
              <Typography variant="body2">
                <Box fontWeight="bold" component="span">
                  PIHAK I
                </Box>{" "}
                menjamin bahwa:
              </Typography>
              <ol type="a">
                <li>
                  <Typography variant="body2">
                    <Box fontWeight="bold" component="span">
                      PIHAK I
                    </Box>{" "}
                    adalah pemilik yang sah dan mempunyai hak atas properti
                    tersebut diatas.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    Properti tersebut diatas tidak dalam sengketa dengan pihak
                    manapun.
                  </Typography>
                </li>
              </ol>
            </li>
            <li>
              <Typography variant="body2">
                <Box fontWeight="bold" component="span">
                  PIHAK I
                </Box>{" "}
                dengan ini memberikan ijin kepada{" "}
                <Box fontWeight="bold" component="span">
                  PIHAK II
                </Box>{" "}
                , untuk:
              </Typography>
              <ol type="a">
                <li>
                  <Typography variant="body2">
                    Memperlihatkan properti dan Open House.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    Memasang papan tanda/banner bertuliskan "Dijual /
                    Disewakan".
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    Menerima titipan tanda jadi dari pembeli / penyewa melalui
                    rekening perusahaan{" "}
                    <Box fontWeight="bold" component="span">
                      PIHAK II
                    </Box>
                    .
                  </Typography>
                </li>
                <li>
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    alignContent="center"
                  >
                    <Grid item>
                      <Typography variant="body2">
                        <Box fontWeight="bold" component="span">
                          PIHAK I
                        </Box>{" "}
                        setuju membayar Success Fee (Komisi Agen) sebesar
                      </Typography>
                    </Grid>
                    <Grid item>
                      <NumberField
                        source="success.fee_sell"
                        label="% Success Fee (Jual)"
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">% (Jual) atau</Typography>
                    </Grid>
                    <Grid item>
                      <NumberField
                        source="success.fee_rent"
                        label="% Success Fee (Sewa)"
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        % (Sewa), dari nilai transaksi yang terjadi.
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
                <li>
                  <Typography variant="body2">
                    Setiap perkara yang timbul dari Perjanjian ini, Para Pihak
                    telah sepakat mengadakan penyelesaian secara musyawarah.
                  </Typography>
                </li>
              </ol>
            </li>
          </ol>
        </TabPanel>
        <TabPanel value="katalog" currentValue={tab}>
          <TextField source="title" label="Judul" />
          <RichTextField source="description" label="Deskripsi" />
          <TextField source="display.address" label="Alamat Singkat" />
          <MapField source="embed_map" label="Peta" />
          <BooleanField source="verified.is" label="Terverifikasi?" />
          <BooleanField source="active" label="Aktif?" />
          <Divider component="div" />
          <ImageField source="photo.primary.src" label="Foto Utama" />
          <ArrayField source="photo.others" label="Foto Lainnya">
            <Datagrid>
              <ImageField source="src" title="title" label="File" />
            </Datagrid>
          </ArrayField>
          <ArrayField source="files" label="Dokumen terkait">
            <Datagrid>
              <FileField source="src" title="title" label="File" />
            </Datagrid>
          </ArrayField>
        </TabPanel>
        <TabPanel value="r123" currentValue={tab}>
          <R123DevMode />
          {identity?.role === "SALES" ? (
            !identity?.rumah_id ? (
              <Box clone my={1}>
                <Alert severity="error">
                  Akun anda belum terhubung dengan akun Rumah123, pastikan email
                  yang anda gunakan di Rumah123.com sama dengan akun disini.
                  <br />
                  Jika sudah sama dan pesan ini masih muncul harap hubungi admin
                  untuk lakukan sinkronisasi akun.
                </Alert>
              </Box>
            ) : (
              <Box clone my={1}>
                <Alert severity="success">
                  Akun anda telah terhubung dengan akun Rumah123, anda dapat
                  melakukan sinkronisasi data PAP ini ke Rumah123.com.
                </Alert>
              </Box>
            )
          ) : (
            <></>
          )}
          <Typography gutterBottom variant="h6">
            Riwayat Sinkron
          </Typography>
          <R123List id={record?.id} ref={ref} />
        </TabPanel>
        <TabPanel value="kontrak" currentValue={tab}>
          <ReferenceManyField
            label="Daftar Kontrak"
            reference="contracts"
            target="listing_id"
          >
            <Datagrid>
              <TextField source="code" label="Kode" />
              <TextField source="type" label="Jenis" />
              {contract.show && <ShowButton />}
            </Datagrid>
          </ReferenceManyField>
        </TabPanel>
      </CompactShowLayout>
    </Show>
  );
};

export default PapShow;

// <TabbedShowLayout>
//   <Tab label="Informasi Umum / Katalog">
//     <TextField source="code" label="Nomor" />

//   </Tab>
//   <Tab label="Identitas Pemilik / Vendor">
//     <TextField source="owner.name" label="Nama Lengkap"  />
//     <TextField source="owner.nik" label="NIK" />
//     <TextField source="owner.address" label="Alamat"  />
//     <TextField source="owner.postal_code" label="Kodepos" />
//     <>
//       <Grid container spacing={2}>
//         <Grid item>
//           <Typography
//             variant="caption"
//             color="textSecondary"
//             component="div"
//             gutterBottom
//           >
//             No. Telepon
//           </Typography>
//           <TextField source="owner.phone" />
//         </Grid>
//         <Grid item>
//           <Typography
//             variant="caption"
//             color="textSecondary"
//             component="div"
//             gutterBottom
//           >
//             No. Fax
//           </Typography>
//           <TextField source="owner.fax" />
//         </Grid>
//         <Grid item>
//           <Typography
//             variant="caption"
//             color="textSecondary"
//             component="div"
//             gutterBottom
//           >
//             No. HP
//           </Typography>
//           <TextField source="owner.cellphone" />
//         </Grid>
//       </Grid>
//     </>
//     <TextField source="owner.email" label="Email" />
//   </Tab>
//   <Tab label="Sales Konsultan">

//   </Tab>
//   <Tab label="Data Properti">
//     <TextField source="property.address" label="Alamat"  />
//     <>
//       <Grid container spacing={2}>
//         <Grid item>
//           <Typography
//             variant="caption"
//             color="textSecondary"
//             component="div"
//             gutterBottom
//           >
//             RT
//           </Typography>
//           <TextField source="property.rt" />
//         </Grid>
//         <Grid item>
//           <Typography
//             variant="caption"
//             color="textSecondary"
//             component="div"
//             gutterBottom
//           >
//             RW
//           </Typography>
//           <TextField source="property.rw" />
//         </Grid>
//       </Grid>
//     </>
//     <>
//       <Grid container spacing={2}>
//         <Grid item>
//           <Typography
//             variant="caption"
//             color="textSecondary"
//             component="div"
//             gutterBottom
//           >
//             Kelurahan
//           </Typography>
//           <TextField source="property.village" />
//         </Grid>
//         <Grid item>
//           <Typography
//             variant="caption"
//             color="textSecondary"
//             component="div"
//             gutterBottom
//           >
//             Kecamatan
//           </Typography>
//           <TextField source="property.district" />
//         </Grid>
//         <Grid item>
//           <Typography
//             variant="caption"
//             color="textSecondary"
//             component="div"
//             gutterBottom
//           >
//             Kota / Kabupaten
//           </Typography>
//           <TextField source="property.city" />
//         </Grid>
//         <Grid item>
//           <Typography
//             variant="caption"
//             color="textSecondary"
//             component="div"
//             gutterBottom
//           >
//             Provinsi
//           </Typography>
//           <TextField source="property.province" />
//         </Grid>
//       </Grid>
//     </>
//     <>
//       <Grid container spacing={2}>
//         <Grid item>
//           <Typography
//             variant="caption"
//             color="textSecondary"
//             component="div"
//             gutterBottom
//           >
//             Luas Tanah (m<sup>2</sup>)
//           </Typography>
//           <NumberField source="area.land" />
//         </Grid>
//         <Grid item>
//           <Typography
//             variant="caption"
//             color="textSecondary"
//             component="div"
//             gutterBottom
//           >
//             Luas Bangunan (m<sup>2</sup>)
//           </Typography>
//           <NumberField source="area.building" />
//         </Grid>
//       </Grid>
//     </>
//     <TextField source="view" label="Hadap / View" />
//     <NumberField
//       source="price.sell"
//       label="Harga Jual"
//       locales="id-ID"
//       options={{ style: "currency", currency: "IDR" }}
//     />
//     <>
//       <Grid
//         container
//         spacing={2}
//         alignItems="center"
//         alignContent="center"
//       >
//         <Grid item>
//           <Typography
//             variant="caption"
//             color="textSecondary"
//             component="div"
//             gutterBottom
//           >
//             Harga Sewa
//           </Typography>
//           <NumberField
//             source="price.rent"
//             label="Harga Sewa"
//             locales="id-ID"
//             options={{ style: "currency", currency: "IDR" }}
//           />
//         </Grid>
//         <Grid item>
//           <Typography
//             variant="caption"
//             color="textSecondary"
//             component="div"
//             gutterBottom
//           >
//             Jenis Sewa
//           </Typography>

//         </Grid>
//       </Grid>
//     </>
//     <>
//       <Grid
//         container
//         spacing={2}
//         alignItems="center"
//         alignContent="center"
//       >
//         <Grid item>
//           <Typography
//             variant="caption"
//             color="textSecondary"
//             component="div"
//             gutterBottom
//           >
//             Masa Sewa
//           </Typography>
//           <NumberField source="rent.duration" label="Masa Sewa" />
//         </Grid>
//         <Grid item>
//           <Typography
//             variant="caption"
//             color="textSecondary"
//             component="div"
//             gutterBottom
//           >
//             Jenis Masa Sewa
//           </Typography>

//         </Grid>
//         <Grid item>
//           <Typography
//             variant="caption"
//             color="textSecondary"
//             component="div"
//             gutterBottom
//           >
//             Deposit
//           </Typography>
//           <NumberField
//             source="rent.deposit"
//             label="Deposit"
//             locales="id-ID"
//             options={{ style: "currency", currency: "IDR" }}
//           />
//         </Grid>
//       </Grid>
//     </>
//     <TextField source="condition" label="Kondisi"  />

//   </Tab>
//   <Tab label="Hak Pemasaran">

//   </Tab>
//   <Tab label="Lainnya">
//     <TextField
//       source="additional.info"
//       label="Keterangan Lainnya"
//
//     />
//     <NumberField
//       source="success.fee_sell"
//       label="Success Fee (Jual)"
//       options={{ style: "percent" }}
//     />
//     <NumberField
//       source="success.fee_rent"
//       label="Success Fee (Sewa)"
//       options={{ style: "percent" }}
//     />

//   </Tab>
//   <Tab label="Media">

//   </Tab>
// </TabbedShowLayout>;
