import React, { useState } from "react";
import { useLogin, useNotify, Notification } from "react-admin";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
} from "@material-ui/core";
import theme from "./theme";
import { Container } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { Typography } from "@material-ui/core";
import axios from "axios";
import useAxios from "axios-hooks";
import { useEffect } from "react";

const VerifyEmailPage = ({}) => {
  const history = useHistory();
  const notify = useNotify();
  const [{ loading, error }, refetch] = useAxios(
    {
      url: `/email/verification-notification`,
      method: "POST",
      headers: {
        Accept: "application/json",
      },
    },
    { manual: true }
  );

  useEffect(() => {
    if (error) {
      notify(error.message, "error");
    }
  }, [error]);

  const resend = async () => {
    try {
      await refetch();

      notify("Email verifikasi telah dikirim ulang ke email anda", "info");
    } catch (error) {
      console.error(error);
      notify(error.message, "error");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box width="100vw" height="100vh" bgcolor="secondary.dark" py={4}>
        <Container maxWidth="sm">
          <Card>
            <CardHeader
              title="MYPRO Admin"
              avatar={<Avatar src={"/storage/logo.jpg"} />}
            />
            <CardContent>
              <Typography variant="h3" align="center">
                Terima kasih telah mendaftar!
              </Typography>
              <Typography variant="h5" align="center">
                Silahkan cek email anda untuk melakukan verifikasi akun.
              </Typography>
              <Typography variant="h6" align="center">
                Setelah verifikasi email harap hubungi admin kami untuk aktivasi
                akun.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                fullWidth
                color="primary"
                onClick={resend}
                disabled={loading}
              >
                Tidak menerima Email? Klik untuk kirim ulang
              </Button>
            </CardActions>
          </Card>
        </Container>
      </Box>
      <Notification />
    </ThemeProvider>
  );
};

export default VerifyEmailPage;
