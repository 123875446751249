import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import { flatten, unflatten } from "flat";
import { serialize } from "object-to-formdata";
import axios from "axios";
import { ls } from "./app";
import { set } from "lodash";

// const httpClient = fetchUtils.fetchJson;

const getToken = () => {
  const session = ls.get("session");

  if (session) {
    return `Bearer ${session.token}`;
  } else {
    return "";
  }
};

const httpClient = (url, config) =>
  axios.request(
    config
      ? {
          ...config,
          url,
        }
      : { url }
  );

const operators = {
  neq: {
    symbol: "!=",
  },
  gt: {
    symbol: ">",
  },
  gte: {
    symbol: ">=",
  },
  lt: {
    symbol: "<",
  },
  lte: {
    symbol: "<=",
  },
  like: {
    symbol: "=",
    prefix: "%",
    suffix: "%",
  },
  nlike: {
    symbol: "=",
    prefix: "%",
    suffix: "%",
  },
};

export const generateFilter = (filters) =>
  JSON.stringify(
    flatten(
      Object.keys(filters)
        .filter((k) => k !== "returns")
        .reduce((obj, k) => {
          const [operator, field] = k.split("__");
          const { symbol, prefix, suffix } = operators[operator] || {};

          if ((field || k) === "q") {
            return {
              ...obj,
              [field || k]: `%${filters[field || k]}%`,
            };
          } else {
            if (obj[field || k]) {
              obj[field || k] = `${obj[field || k]}&&${symbol || "="}${
                prefix || ""
              }${filters[k]}${suffix || ""}`;
            } else {
              set(
                obj,
                field || k,
                `${symbol || "="}${prefix || ""}${filters[k]}${suffix || ""}`
              );
            }

            return obj;

            // return {
            //   ...obj,
            //   [field || k]: `${symbol || "="}${prefix || ""}${
            //     params.filter[k]
            //   }${suffix || ""}`,
            // };
          }
        }, {}),
      { delimiter: "_", safe: true }
    )
  );

const SetDataProvider = (apiUrl) => ({
  getList: (resource, params) => {
    // console.log(params);
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const filters = flatten(params.filter, { safe: true });
    const query = {
      order: JSON.stringify({ [field.replace(".", "_")]: order.toLowerCase() }),
      // range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      limit: perPage,
      offset: (page - 1) * perPage,
      returns: JSON.stringify(filters.returns || []),
      filter: generateFilter(filters),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, data: { data, total = 0 } }) => ({
      data: data.map((d) =>
        unflatten(
          Object.keys(d).reduce(
            (obj, k) => ({
              ...obj,
              [k.replace("_", ".")]: d[k],
            }),
            {}
          )
        )
      ),
      total,
    }));
  },
  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(
      ({ data: { data } }) => ({
        data: unflatten(
          Object.keys(data).reduce(
            (obj, k) => ({
              ...obj,
              [k.replace("_", ".")]: data[k],
            }),
            {}
          )
        ),
      })
    ),
  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids.map((i) => `=${i}`).join("||") }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ headers, data: { data, total = 0 } }) => ({
      data: data.map((d) =>
        unflatten(
          Object.keys(d).reduce(
            (obj, k) => ({
              ...obj,
              [k.replace("_", ".")]: d[k],
            }),
            {}
          )
        )
      ),
      total,
    }));
  },
  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const filters = flatten(params.filter, { safe: true });

    const query = {
      order: JSON.stringify({ [field.replace(".", "_")]: order.toLowerCase() }),
      // range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      limit: perPage,
      offset: (page - 1) * perPage,
      returns: JSON.stringify(filters.returns || []),
      filter: JSON.stringify(
        flatten(
          Object.keys(filters)
            .filter((k) => k !== "returns")
            .reduce(
              (obj, k) => {
                const [operator, field] = k.split("__");
                const { symbol, prefix, suffix } = operators[operator] || {};

                if ((field || k) === "q") {
                  return {
                    ...obj,
                    [field]: `${params.filter[field]}`,
                  };
                } else {
                  set(
                    obj,
                    field || k,
                    `${symbol || "="}${prefix || ""}${filters[k]}${
                      suffix || ""
                    }`
                  );

                  return obj;

                  // return {
                  //   ...obj,
                  //   [field || k]: `${symbol || "="}${prefix || ""}${
                  //     params.filter[k]
                  //   }${suffix || ""}`,
                  // };
                }
              },
              {
                [params.target]: `=${params.id}`,
              }
            ),
          { delimiter: "_", safe: true }
        )
      ),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, data: { data, total = 0 } }) => ({
      data: data.map((d) =>
        unflatten(
          Object.keys(d).reduce(
            (obj, k) => ({
              ...obj,
              [k.replace("_", ".")]: d[k],
            }),
            {}
          )
        )
      ),
      total,
    }));
  },
  update: async (resource, params) => {
    console.log(
      resource,
      params.data,
      flatten(params.data, { delimiter: "_", safe: true })
    );
    const csrf = await httpClient(`${apiUrl}/../sanctum/csrf-cookie`);
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "POST",
      data: serialize(
        flatten(
          { ...params.data, _method: "PUT" },
          { delimiter: "_", safe: true }
        ),
        { indices: true }
      ),
    }).then(({ data: { data } }) => ({
      data: unflatten(
        Object.keys(data).reduce(
          (obj, k) => ({
            ...obj,
            [k.replace("_", ".")]: data[k],
          }),
          {}
        )
      ),
    }));
  },
  updateMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const csrf = await httpClient(`${apiUrl}/../sanctum/csrf-cookie`);
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      data: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
  create: async (resource, params) => {
    console.log(
      resource,
      params.data,
      flatten(params.data, { delimiter: "_", safe: true })
    );

    const csrf = await httpClient(`${apiUrl}/../sanctum/csrf-cookie`);
    if (resource === "users" && params.data.isRegister === true) {
      return httpClient(`${apiUrl}/../register`, {
        method: "POST",
        data: serialize(flatten(params.data, { delimiter: "_", safe: true }), {
          indices: true,
        }),
      }).then(({ data }) => ({
        data: { ...data, ...params.data },
      }));
    } else {
      return httpClient(`${apiUrl}/${resource}`, {
        method: "POST",
        data: serialize(flatten(params.data, { delimiter: "_", safe: true }), {
          indices: true,
        }),
      }).then(({ data: { data, success, errors, message } }) => {
        if (success === false) {
          console.error(errors);
          throw message;
        }

        return {
          data: unflatten(
            Object.keys(data).reduce(
              (obj, k) => ({
                ...obj,
                [k.replace("_", ".")]: data[k],
              }),
              {}
            )
          ),
        };
      });
    }
  },
  delete: async (resource, params) => {
    const csrf = await httpClient(`${apiUrl}/../sanctum/csrf-cookie`);

    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ data: { data } }) => ({
      data: unflatten(
        Object.keys(data).reduce(
          (obj, k) => ({
            ...obj,
            [k.replace("_", ".")]: data[k],
          }),
          {}
        )
      ),
    }));
  },
  deleteMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const csrf = await httpClient(`${apiUrl}/../sanctum/csrf-cookie`);
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "DELETE",
      data: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
});

export default SetDataProvider;
