import React, { useState } from "react";
import { useLogin, useNotify, Notification } from "react-admin";
import { createMuiTheme, fade, ThemeProvider } from "@material-ui/core/styles";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
  styled,
  hexToRgb,
} from "@material-ui/core";
import theme from "./theme";
import { Container } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";

const BgBox = styled(Box)(({ theme }) => ({
  backgroundImage: `linear-gradient(${fade(
    theme.palette.secondary.dark,
    0.95
  )}, ${fade(
    theme.palette.secondary.light,
    0.25
  )}), url(https://source.unsplash.com/1600x900/?home,apartment,office,building)`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
}));

const LoginPage = ({ theme }) => {
  const history = useHistory();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const login = useLogin();
  const notify = useNotify();

  const submit = (e) => {
    e.preventDefault();
    login({ email, password }).catch(() => notify("Invalid email or password"));
  };

  const goTo = (link) => (event) => {
    event.preventDefault();

    history.push(link, {
      internal: location.state?.internal || true,
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <BgBox width="100vw" height="100vh" bgcolor="secondary.dark" py={4}>
        <Container maxWidth="sm">
          <Card component="form" onSubmit={submit}>
            <CardHeader
              title="MYPRO Admin"
              subheader="Harap Login"
              avatar={<Avatar src={"/storage/logo.jpg"} />}
            />
            <CardContent>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <TextField
                    label="Email"
                    name="email"
                    type="email"
                    value={email}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="Password"
                    name="password"
                    type="password"
                    value={password}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Masuk
                  </Button>
                </Grid>
                {/* <Grid item xs={6}> */}
                <Button
                  fullWidth
                  size="small"
                  onClick={goTo("/forgot-password")}
                >
                  Lupa Kata Sandi?
                </Button>
                {/* </Grid> */}
                {/* <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={goTo("/register")}
                  >
                    Daftar
                  </Button>
                </Grid> */}
              </Grid>
            </CardActions>
          </Card>
          <Box my={2}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={goTo("/catalog")}
                  size="large"
                >
                  <Grid
                    container
                    spacing={1}
                    direction="column"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                  >
                    <Grid item>
                      <Box clone height={72}>
                        <img src={`/storage/seo.png`} />
                      </Box>
                    </Grid>
                    <Grid item>Katalog</Grid>
                  </Grid>
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  component="a"
                  variant="contained"
                  fullWidth
                  href="http://myproindonesia.com"
                  size="large"
                >
                  <Grid
                    container
                    spacing={1}
                    direction="column"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                  >
                    <Grid item>
                      <Box clone height={72}>
                        <img src={`/storage/baloon.png`} />
                      </Box>
                    </Grid>
                    <Grid item>Website</Grid>
                  </Grid>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </BgBox>
      <Notification />
    </ThemeProvider>
  );
};

export default LoginPage;
