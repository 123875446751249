require("./bootstrap");

import App from "./admin.js";
import React from "react";
import { render } from "react-dom";
import axios from "axios";
import {
  cacheAdapterEnhancer,
  throttleAdapterEnhancer,
} from "axios-extensions";

import SecureLS from "secure-ls";
import { withStore } from "react-context-hook";
import LRUCache from "lru-cache";
import "@fontsource/inter";

export const ls = new SecureLS({ encodingType: "aes" });

axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    console.error("AXIOS", error.response);
    return Promise.reject(error.response?.data ?? error);
  }
);

axios.defaults.headers = {
  "Cache-Control": "no-cache",
};

axios.defaults.adapter = throttleAdapterEnhancer(
  cacheAdapterEnhancer(axios.defaults.adapter, {
    defaultCache: new LRUCache({ maxAge: 1000 * 5 }),
  })
);

const StoredApp = withStore(App);

render(<StoredApp />, document.getElementById("app"));
