import {
  Box,
  Container,
  CssBaseline,
  fade,
  styled,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import React from "react";
import theme from "../theme";
import UserCreate from "./create";

const BgBox = styled(Box)(({ theme }) => ({
  backgroundImage: `linear-gradient(${fade(
    theme.palette.secondary.dark,
    0.95
  )}, ${fade(
    theme.palette.secondary.light,
    0.25
  )}), url(https://source.unsplash.com/1600x900/?home,apartment,office,building)`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
}));

const RegisterPage = ({ staticContext, ...props }) => {
  return (
    <ThemeProvider theme={theme}>
      <BgBox minHeight="100vh" bgcolor="secondary.dark" py={4}>
        <Container maxWidth="md">
          <Typography variant="h4" align="center" color="primary" gutterBottom>
            Daftar sebagai Agen MYPRO
          </Typography>
          <UserCreate
            resource="users"
            basePath="/register"
            title="Daftar sebagai agen"
            {...props}
          />
        </Container>
      </BgBox>
    </ThemeProvider>
  );
};

export default RegisterPage;
