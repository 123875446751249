import React, { useEffect } from "react";
import Backdrop from "material-ui-swipeable-backdrop";
import { useContextRef, useRefs } from "react-context-refs";
import { Box, makeStyles, Portal, ThemeProvider } from "@material-ui/core";
import { useStore, useStoreValue, withStore } from "react-context-hook";
import theme from "../theme";

export const BackdropContent = ({ children, ...props }) => {
  const container = useRefs("backdrop");

  return <Portal container={(container[0] || {}).current}>{children}</Portal>;
};

const CatalogLayout = ({ children }) => {
  const backdropClasses = useStoreValue("backdrop_classes", {});
  const [open, setOpen] = useStore("backdrop_open", false);
  const backdrop = useContextRef("backdrop", {});

  useEffect(() => {
    document
      .querySelectorAll('div[class^="makeStyles-swipe"]')
      .forEach((el) => el.remove());
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        classes={backdropClasses}
        closedBackLayerHeight={64}
        openBackLayerHeight={64}
        backLayerOpen={open}
        onBackLayerClose={() => setOpen(false)}
        onBackLayerOpen={() => setOpen(true)}
        backLayer={<Box ref={backdrop} height="100%" />}
        frontLayer={
          <Box overflow="auto" height="100%">
            {children}
          </Box>
        }
      />
    </ThemeProvider>
  );
};

export default CatalogLayout;
