import get from "lodash.get";
import { usePermissions } from "ra-core";
import React, { useMemo } from "react";

export const useNewACL = (name) => {
  const { loading, loaded, permissions } = usePermissions();

  const access = useMemo(
    () => (!loading && loaded ? get(permissions, name) || {} : {}),
    [loading, loaded, permissions, name]
  );

  return access;
};
