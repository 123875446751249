import { RaBox, RaGrid } from "ra-compact-ui";
import { CompactForm } from "ra-compact-ui";
import React from "react";
import {
  AutocompleteInput,
  Edit,
  RadioButtonGroupInput,
  ReferenceInput,
  SelectField,
  required,
  SimpleForm,
  TextInput,
  ReferenceField,
  TextField,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  FormDataConsumer,
} from "react-admin";
import { useNewACL } from "../acl";
import { expenseType } from "../mapping";
import { NumberInput } from "../CustomInputs";

const ContractEdit = (props) => {
  const receipt = useNewACL("receipts");

  return (
    <Edit mutationMode="pessimistic" {...props}>
      <CompactForm layoutComponents={[RaBox, RaGrid]}>
        <RaGrid container spacing={3}>
          <RaGrid item xs={12} md="auto">
            <SelectField
              source="type"
              choices={[
                { id: "sell", name: "SPPJB" },
                { id: "auction", name: "SPBL" },
                {
                  id: "rent",
                  name: "Sewa",
                },
              ]}
              label="Jenis Kontrak"
            />
            {/* <ReferenceInput source="listing.id" reference="listings" label="PAP">
        <AutocompleteInput
          optionText={(record) => `[${record.code}] ${record.property.address}`}
        />
      </ReferenceInput> */}
            <ReferenceField
              source="listing.id"
              reference="listings"
              label="PAP"
              validate={[required()]}
            >
              <TextField source="code" />
            </ReferenceField>
            <NumberInput source="nominal" label="Nominal" />
            <TextInput source="customer.code" label="Kode Pembeli/Penyewa" />
            <TextInput
              source="customer.name"
              label="Nama Pihak Pembeli/Penyewa"
            />
            <TextInput
              source="customer.address"
              label="Alamat Pihak Pembeli/Penyewa"
            />
            <TextInput
              source="customer.email"
              label="Email Pihak Pembeli/Penyewa"
            />
            <TextInput
              source="customer.phone"
              label="Telepon Pihak Pembeli/Penyewa"
            />
            <TextInput
              source="customer.nik"
              label="NIK Pihak Pembeli/Penyewa"
            />
            <TextInput
              source="customer.npwp"
              label="NPWP Pihak Pembeli/Penyewa"
            />
          </RaGrid>
          <RaGrid item xs={12} md="auto">
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.type === "sell" ? (
                  <RadioButtonGroupInput
                    source="transaction"
                    choices={[
                      { id: "cash", name: "Cash" },
                      { id: "kpr", name: "KPR" },
                      {
                        id: "both",
                        name: "Cash & KPR",
                      },
                      { id: "installment", name: "Pembayaran Bertahap" },
                    ]}
                    label="Jenis Transaksi"
                    validate={[required()]}
                  />
                ) : (
                  <></>
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.type === "sell" ? (
                  <NumberInput source="downpayment" label="Nominal UTJ" />
                ) : (
                  <></>
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.type === "sell" ? (
                  <NumberInput source="settlement" label="Nominal Pelunasan" />
                ) : (
                  <></>
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.type === "sell" ? (
                  <TextInput source="shm" label="No. SHM" />
                ) : (
                  <></>
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.type === "rent" ? (
                  <NumberInput source="downpayment" label="Nominal UTJ" />
                ) : (
                  <></>
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.type === "rent" ? (
                  <NumberInput source="deposit" label="Nominal Deposit" />
                ) : (
                  <></>
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.type === "rent" ? (
                  <NumberInput source="fine" label="Denda Keterlambatan" />
                ) : (
                  <></>
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.type === "rent" ? (
                  <RadioButtonGroupInput
                    source="settlement"
                    choices={[
                      { id: "full", name: "Pembayaran Langsung Penuh" },
                      { id: "installment", name: "Dibagi Beberapa Termin" },
                    ]}
                    label="Jenis Pelunasan"
                    validate={[required()]}
                  />
                ) : (
                  <></>
                )
              }
            </FormDataConsumer>
            {receipt.create ? (
              <ArrayInput
                source="additional.bill"
                label="Potongan / Pengeluaran"
              >
                <SimpleFormIterator>
                  <SelectInput
                    source="name"
                    choices={expenseType}
                    label="Jenis"
                    validate={[required()]}
                  />
                  <NumberInput
                    source="nominal"
                    label="Nominal"
                    validate={[required()]}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            ) : (
              <></>
            )}
            {receipt.create ? (
              <ArrayInput source="marketers" label="Sales lain yang terlibat">
                <SimpleFormIterator>
                  <ReferenceInput
                    source="user_id"
                    reference="users"
                    label="Cari Agen"
                    filter={{ role: "SALES" }}
                  >
                    <AutocompleteInput optionText="name" fullWidth />
                  </ReferenceInput>
                </SimpleFormIterator>
              </ArrayInput>
            ) : (
              <></>
            )}
          </RaGrid>
        </RaGrid>
      </CompactForm>
    </Edit>
  );
};

export default ContractEdit;
