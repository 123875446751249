import MomentUtils from "@date-io/moment";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from "@material-ui/core";
import React, { useState, useCallback, useEffect } from "react";
import {
  BooleanField,
  DateField,
  Show,
  Tab,
  ImageField,
  NumberField,
  PasswordField,
  RadioButtonGroupField,
  TabbedShowLayout,
  TextField,
  SelectField,
  TopToolbar,
  EditButton,
  Button,
  Edit,
  SimpleForm,
  useMutation,
  SelectInput,
  Toolbar,
  SaveButton,
  required,
  BooleanInput,
  useNotify,
} from "react-admin";
import CheckIcon from "@material-ui/icons/Check";
import MailIcon from "@material-ui/icons/Mail";
import PrintIcon from "@material-ui/icons/Print";
import { useNewACL } from "../acl";
import useAxios from "axios-hooks";

const ResendMouButton = ({ id }) => {
  const notify = useNotify();
  const [{ data, loading, error }, send] = useAxios(
    {
      url: `/api/resend-mou/${id}`,
      method: "POST",
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (!loading && !error && data) {
      notify("Link MOU telah dikirimkan kembali ke email Agen", "info");
    }
  }, [loading, error, data]);

  const handleSend = () => {
    send();
  };

  return (
    <Button
      onClick={handleSend}
      label="Kirim Kembali MOU"
      disabled={loading || error}
    >
      <MailIcon />
    </Button>
  );
};

const SaveOnly = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const Actions = ({ permissions, basePath, data, resource }) => {
  const users = useNewACL("users");
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(!open);

  return (
    <>
      <TopToolbar>
        {/* {data?.active === false && data?.mail?.verified === true && (
          <Box clone marginRight={1}>
            <Button onClick={handleOpen} label="Aktifkan Pengguna">
              <CheckIcon />
            </Button>
          </Box>
        )} */}
        <EditButton basePath={basePath} record={data} />
        {/* {permissions === "admin" && (
          <DeleteButton basePath={basePath} record={data} resource={resource} />
        )} */}
        {data?.active === true &&
          data?.mail?.verified === true &&
          data?.role === "SALES" && (
            <Box clone marginRight={1}>
              <ResendMouButton id={data?.id} />
            </Box>
          )}
        {users?.create && data?.role === "SALES" && (
          <Button
            label="Cetak MOU"
            component="a"
            href={`/mou/${data?.id}`}
            target="_blank"
          >
            <PrintIcon />
          </Button>
        )}
      </TopToolbar>
      <Dialog
        open={open}
        onClose={handleOpen}
        TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
      >
        <DialogTitle>Aktifkan Pengguna</DialogTitle>
        <DialogContent>
          <Edit
            id={data?.id}
            resource={resource}
            basePath={basePath}
            mutationMode="pessimistic"
            redirect="show"
            component="div"
          >
            <SimpleForm toolbar={<SaveOnly />}>
              <SelectInput
                choices={[
                  { id: "USER", name: "Pengguna" },
                  { id: "ADMIN", name: "Admin" },
                  { id: "SALES", name: "Sales / Agen" },
                ]}
                source="role"
                label="Jenis Pengguna"
                validate={[required()]}
              />
              <BooleanInput
                source="active"
                label="Set Aktif"
                readOnly
                defaultValue={true}
              />
            </SimpleForm>
          </Edit>
        </DialogContent>
      </Dialog>
    </>
  );
};

const UserShow = (props) => (
  <Show actions={<Actions />} {...props}>
    <TabbedShowLayout>
      <Tab label="Identitas Diri">
        <DateField source="mail.verified_at" label="Email Terverifikasi" />
        <SelectField
          choices={[
            { id: "USER", name: "Pengguna" },
            { id: "ADMIN", name: "Admin" },
            { id: "SALES", name: "Sales / Agen" },
          ]}
          source="role"
          label="Jenis Pengguna"
        />
        <BooleanField source="active" label="Aktif" />
        <TextField source="rumah.id" label="ID Agen di Rumah123" />
        <Divider />
        <ImageField source="avatar.src" label="Foto Profil" />
        <TextField source="name" label="Nama Lengkap" fullWidth />
        <TextField source="alias" label="Nama Alias" fullWidth />
        <SelectField
          source="gender"
          label="Jenis Kelamin"
          choices={[
            { id: "male", name: "Laki-laki" },
            { id: "female", name: "Perempuan" },
          ]}
        />
        <TextField source="email" />
        <TextField source="code" label="Kode Karyawan" />
        <TextField source="reference.code" label="Kode Ref" />
        {/* <TextField source="spv.code" label="Kode SPV" /> */}
        <TextField source="phone" label="Telepon/WA" />
        <DateField
          source="birthdate"
          label="Tanggal Lahir"
          options={{
            format: "DD/MM/YYYY",
            inputVariant: "filled",
            margin: "dense",
          }}
          providerOptions={{ utils: MomentUtils }}
        />
        <TextField source="birthplace" label="Tempat Lahir" />
        <TextField source="address" label="Alamat Tinggal Sekarang" fullWidth />
        <TextField source="nik" label="NIK" />
        <TextField source="npwp" label="NPWP" />
        <SelectField
          source="marital"
          choices={[
            { id: "single", name: "Belum Menikah" },
            { id: "married", name: "Menikah" },
            { id: "divorced", name: "Bercerai" },
          ]}
          label="Status Nikah"
        />
        <NumberField source="children" label="Jumlah Anak" />
        {/* <SelectField
          source="employment"
          choices={[
            { id: "internal", name: "Hanya di MYPRO" },
            {
              id: "external",
              name: "Bekerja di Tempat Lain",
            },
          ]}
          label="Status Kerja"
        /> */}
        <SelectField
          source="category.type"
          label="Kategori"
          choices={[
            { id: "fresh", name: "Fresh Property" },
            { id: "senior", name: "Senior Property Consultant" },
          ]}
        />
        <TextField source="category.detail" label="Background / Ex Agent" />
        <TextField source="occupation" label="Pekerjaan Lain" />
      </Tab>
      <Tab label="Rekening">
        <TextField source="bank.name" label="Bank" />
        <TextField source="bank.number" label="No." />
        <TextField source="bank.account" label="Atas Nama" />
        <TextField source="bank.branch" label="Cabang" />
      </Tab>
      <Tab label="Media Sosial">
        <TextField source="facebook" label="Facebook" />
        <TextField source="instagram" label="Instagram" />
        <TextField source="tiktok" label="Tiktok" />
      </Tab>
      <Tab label="Dokumen Referensi">
        <ImageField source="docs.ktp.src" label="Foto KTP" />
        <ImageField source="docs.npwp.src" label="Foto NPWP" />
        <ImageField source="docs.selfie.src" label="Foto Selfie" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default UserShow;
