import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Link,
  styled,
  SvgIcon,
  Tab,
  Tabs,
  InputAdornment,
  makeStyles,
  AppBar,
  fade,
} from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { Button } from "@material-ui/core";
import React, { useState } from "react";
import { useSetStoreValue, useStore } from "react-context-hook";
import CatalogLayout, { MainContent, BackdropContent } from "./layout";
import HomeIcon from "@material-ui/icons/Home";
import FilterListIcon from "@material-ui/icons/FilterList";
import { CardHeader } from "@material-ui/core";
import useAxios from "axios-hooks";
import { useEffect } from "react";
import { Badge } from "@material-ui/core";
import { Chip } from "@material-ui/core";
import { GiHouse } from "@react-icons/all-files/gi/GiHouse";
import { GiMove } from "@react-icons/all-files/gi/GiMove";
import { GiBed } from "@react-icons/all-files/gi/GiBed";
import { GiBathtub } from "@react-icons/all-files/gi/GiBathtub";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import {
  FilterLiveSearch,
  ListBase,
  Pagination,
  useListContext,
  TextInput,
  NullableBooleanInput,
  SelectInput,
  AutocompleteInput,
  ReferenceInput,
  FormDataConsumer,
  RadioButtonGroupInput,
} from "react-admin";
import { Form } from "react-final-form";
import { listingType } from "../mapping";
import ns from "number-string";
import theme from "../theme";
import { Skeleton } from "@material-ui/lab";
import { useMemo } from "react";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { NumberInput } from "../CustomInputs";
import { flatten, unflatten } from "flat";

const GridCard = styled(Card)(({ theme }) => ({
  margin: theme.spacing(0),
}));

const useStyles = makeStyles(() => ({
  backdropRoot: {
    backgroundImage: `linear-gradient(to bottom, ${theme.palette.secondary.main} 10%, ${theme.palette.secondary.dark} 75%)`,
  },
  frontLayer: {
    backgroundImage: `linear-gradient(${fade(
      theme.palette.secondary.dark,
      0.95
    )}, ${fade(
      theme.palette.grey[100],
      0.9
    )} 35%), url(https://source.unsplash.com/1600x900/?home,apartment,office,building)`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
}));

const useTabsStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    marginLeft: spacing(1),
  },
  indicator: {
    height: 3,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    backgroundColor: palette.common.white,
  },
}));

const useTabItemStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    textTransform: "initial",
    margin: spacing(0, 2),
    minWidth: 0,
    [breakpoints.up("md")]: {
      minWidth: 0,
    },
  },
  wrapper: {
    fontWeight: "normal",
    letterSpacing: 0.5,
  },
}));

const ActiveFilters = () => {
  const { displayedFilters, filterValues, setFilters, hideFilter } =
    useListContext();

  const hasFilters = useMemo(
    () => Object.keys(filterValues).filter((k) => k !== "q").length > 0,
    [filterValues]
  );

  const handleDelete = (key) => (event) => {
    const currentFilters = flatten(filterValues);

    const newFilters = unflatten(
      Object.keys(currentFilters).reduce(
        (obj, k) => (k !== key ? { ...obj, [k]: currentFilters[k] } : obj),
        {}
      )
    );

    setFilters(newFilters);
  };

  const flatFilter = useMemo(() => flatten(filterValues), [filterValues]);

  return (
    <>
      {hasFilters && (
        <Box my={1}>
          <Grid container justify="center" spacing={1}>
            {Object.keys(flatFilter)
              .filter((k) => k !== "q")
              .map((k) => (
                <Grid item key={k}>
                  <Chip
                    icon={<FilterListIcon />}
                    size="small"
                    label={flatFilter[k]}
                    onDelete={handleDelete(k)}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
      )}
    </>
  );
};

const CatalogAdvanceSearchForm = ({ tab, subTab, onClose }) => {
  const { displayedFilters, filterValues, setFilters, hideFilter } =
    useListContext();

  const onSubmit = (values) => {
    if (Object.keys(values).length > 0) {
      setFilters(values);
    } else {
      // hideFilter("main");
    }
  };

  const resetFilter = () => {
    setFilters({
      ...(tab === "sell"
        ? { is_sell: +true, verified_is: true, active: +true }
        : tab === "rent"
        ? { is_rent: +true, verified_is: true, active: +true }
        : { is_auction: +true, verified_is: true, active: +true }),
      ...(tab === "sell" ? { is_primary: +(subTab === "primary") } : {}),
    });
  };

  const handleClose = () => onClose();

  return (
    <Form onSubmit={onSubmit} initialValues={filterValues}>
      {({ handleSubmit }) => (
        <Box clone marginBottom={1}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={1}
              alignItems="center"
              alignContent="center"
            >
              <Grid item xs={12}>
                <TextInput
                  helperText={false}
                  source="q"
                  label="Mencari"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <NumberInput
                  helperText={false}
                  source="gte__price"
                  label="Harga Mulai"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <NumberInput
                  helperText={false}
                  source="lte__price"
                  label="Harga Hingga"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <RadioButtonGroupInput
                  helperText={false}
                  source="type"
                  label="Tipe Properti"
                  choices={listingType}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <ReferenceInput
                  source="property.province"
                  reference="postal_codes"
                  label="Pilih Provinsi"
                  filterToQuery={(province) => ({
                    like__province: province,
                    returns: ["province"],
                  })}
                >
                  <AutocompleteInput optionText="province" />
                </ReferenceInput>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDataConsumer>
                  {({ formData, ...rest }) => (
                    <ReferenceInput
                      source="property.city"
                      reference="postal_codes"
                      label="Pilih Kota / Kabupaten"
                      filter={{
                        returns: ["city"],
                        province: formData.property?.province,
                      }}
                      filterToQuery={(city) => ({
                        like__city: city,
                      })}
                    >
                      <AutocompleteInput
                        optionText="city"
                        disabled={!formData.property?.province}
                      />
                    </ReferenceInput>
                  )}
                </FormDataConsumer>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDataConsumer>
                  {({ formData, ...rest }) => (
                    <ReferenceInput
                      source="property.district"
                      reference="postal_codes"
                      label="Pilih Kecamatan"
                      filter={{
                        returns: ["district"],
                        province: formData.property?.province,
                        city: formData.property?.city,
                      }}
                      filterToQuery={(district) => ({
                        like__district: district,
                      })}
                    >
                      <AutocompleteInput
                        optionText="district"
                        disabled={!formData.property?.city}
                      />
                    </ReferenceInput>
                  )}
                </FormDataConsumer>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormDataConsumer>
                  {({ formData, ...rest }) => (
                    <ReferenceInput
                      source="property.village"
                      reference="postal_codes"
                      label="Pilih Kelurahan / Desa"
                      filter={{
                        returns: ["village"],
                        province: formData.property?.province,
                        city: formData.property?.city,
                        district: formData.property?.district,
                      }}
                      filterToQuery={(village) => ({
                        like__village: village,
                      })}
                    >
                      <AutocompleteInput
                        optionText="village"
                        disabled={!formData.property?.district}
                      />
                    </ReferenceInput>
                  )}
                </FormDataConsumer>
              </Grid>
            </Grid>
            <Grid container justify="flex-end" spacing={1}>
              <Grid item>
                <Button variant="contained" color="primary" type="submit">
                  Filter
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={resetFilter}>
                  Reset
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={handleClose}>Tutup</Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      )}
    </Form>
  );
};

const useCardStyles = makeStyles(() => ({
  media: {
    width: "100%",
    paddingBottom: "56.25%",
    clipPath: "polygon(0 0, 100% 0%, 100% 84%, 0% 100%)",
    backgroundColor: "rgba(0,0,0,0.8)",
  },
  avatar: {
    width: 50,
    height: 50,
    border: "2px solid #fff",
    margin: "-48px 32px 0 auto",
    "& > img": {
      margin: 0,
    },
  },
  actionArea: {
    backgroundImage: `linear-gradient(to bottom, ${theme.palette.secondary.light} 90%, ${theme.palette.secondary.main})`,
    backgroundColor: "transparent",
    color: theme.palette.getContrastText(theme.palette.secondary.main),
  },
}));

export const PapGrid = () => {
  const { ids, data, basePath, loading } = useListContext();
  const history = useHistory();
  const location = useLocation();
  const classes = useCardStyles();

  const goTo = (link) => (event) => {
    event.preventDefault();

    history.push(link, {
      internal: location.state?.internal || true,
    });
  };

  return (
    <Grid container spacing={2} alignItems="stretch" alignContent="center">
      {loading &&
        [...Array(6).keys()].map((k) => (
          <Grid item key={k} xs={12} sm={6} md={4}>
            <Skeleton width={"100%"} height={256} />
          </Grid>
        ))}
      {!loading &&
        ids.map((id) => {
          const d = data[id];
          return (
            <Grid
              item
              key={id}
              xs={12}
              sm={6}
              md={4}
              // lg={3}
              // component={GridCard}
            >
              <Card>
                {/* <CardHeader
                avatar={
                }
                title={d.sales?.name}
                titleTypographyProps={{ variant: "subtitle1" }}
              /> */}

                <CardActionArea
                  className={classes.actionArea}
                  component="a"
                  href={`/catalog-detail/${d.id}`}
                  onClick={goTo(`/catalog-detail/${d.id}`)}
                >
                  <Box
                    clone
                    height={200}
                    bgcolor={`${theme.palette.primary.light} !important`}
                  >
                    <CardMedia
                      className={classes.media}
                      image={
                        d.photo?.primary?.src ||
                        // "/storage/listing_placeholder.png"
                        "/storage/sale.png"
                      }
                      title={d.title}
                    />
                  </Box>
                  <Avatar
                    src={d.sales?.avatar?.src || "/storage/logo.jpg"}
                    className={classes.avatar}
                    title={d.sales?.name}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="h2">
                      {d.title}
                    </Typography>
                    <Box my={2}>
                      <Grid container justify="space-between">
                        <Grid item>
                          <Typography
                            variant="body2"
                            color="inherit"
                            align="left"
                          >
                            Oleh {d.sales?.name || "MYPRO"}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="subtitle2"
                            color="inherit"
                            align="right"
                          >
                            {ns.toMoney(d.price?.sell || d.price?.rent || 0, {
                              decimalMark: ",",
                              thousandSeparator: ".",
                              symbol: "Rp. ",
                              minPrecision: 0,
                              maxPrecision: 0,
                            })}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Grid container spacing={1} justify="flex-end">
                      <Grid item>
                        <Chip
                          size="small"
                          icon={
                            <Box clone fontSize={14}>
                              <GiHouse />
                            </Box>
                          }
                          label={
                            <>
                              {d.area?.building} m<sup>2</sup>
                            </>
                          }
                          color="primary"
                        />
                      </Grid>
                      <Grid item>
                        <Chip
                          size="small"
                          icon={
                            <Box clone fontSize={14}>
                              <GiMove />
                            </Box>
                          }
                          label={
                            <>
                              {d.area?.land} m<sup>2</sup>
                            </>
                          }
                          color="secondary"
                        />
                      </Grid>
                      <Grid item>
                        <Chip
                          size="small"
                          icon={
                            <Box clone fontSize={14}>
                              <GiBed />
                            </Box>
                          }
                          label={<>{d.count?.bed || 0}</>}
                        />
                      </Grid>
                      <Grid item>
                        <Chip
                          size="small"
                          icon={
                            <Box clone fontSize={14}>
                              <GiBathtub />
                            </Box>
                          }
                          label={<>{d.count?.bath || 0}</>}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
    </Grid>
  );
};

const ListingListPage = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const setBackdropClasses = useSetStoreValue("backdrop_classes");
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(
    qs.parse(location.search.substr(1))["type"] || "sell"
  );
  const [subtab, setSubTab] = useState(
    qs.parse(location.search.substr(1))["subtype"] || "primary"
  );
  const tabStyles = useTabsStyles();
  const tabItemStyles = useTabItemStyles();

  useEffect(() => {
    if (classes) {
      setBackdropClasses({
        root: classes.backdropRoot,
        frontLayer: classes.frontLayer,
      });
    }
  }, [setBackdropClasses, classes]);

  const handleTabChange = (event, value) => setTab(value);

  const handleSubTabChange = (event, value) => setSubTab(value);

  const goTo = (link) => (event) => {
    event.preventDefault();

    history.push(link, {
      internal: location.state?.internal || true,
    });
  };

  return (
    <CatalogLayout>
      <ListBase
        syncWithLocation
        filter={{
          ...(tab === "sell"
            ? { is_sell: +true, verified_is: true, active: +true }
            : tab === "rent"
            ? { is_rent: +true, verified_is: true, active: +true }
            : { is_auction: +true, verified_is: true, active: +true }),
          ...(tab === "sell" ? { is_primary: +(subtab === "primary") } : {}),
        }}
        basePath={"/catalog"}
        resource="listings"
        perPage={12}
      >
        <BackdropContent>
          <Box p={1} color="white">
            <Grid
              container
              spacing={1}
              justify="space-between"
              alignItems="center"
              alignContent="center"
            >
              <Grid item>
                <IconButton
                  component="a"
                  href="http://myproindonesia.com"
                  color="inherit"
                >
                  <HomeIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <Grid
                  container
                  spacing={1}
                  justify="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid item>
                    <Avatar src={"/storage/logo.jpg"} />
                  </Grid>
                  <Grid item>
                    <Typography variant="h5" component="h2" color="inherit">
                      MYPRO INDONESIA
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <IconButton component="a" href="/" color="inherit">
                  <ExitToAppIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </BackdropContent>
        <Container maxWidth="lg">
          <Box py={35}>
            <Typography align="center" variant={"h4"} component="h1">
              Katalog Properti
            </Typography>
            {open ? (
              <CatalogAdvanceSearchForm
                tab={tab}
                subTab={subtab}
                onClose={() => setOpen(false)}
              />
            ) : (
              <Grid
                container
                spacing={1}
                alignItems="center"
                alignContent="center"
              >
                <Grid item xs={12} md={9}>
                  <FilterLiveSearch source="q" fullWidth />
                </Grid>
                <Grid item>
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={() => setOpen(!open)}
                    endIcon={<FilterListIcon />}
                  >
                    Pencarian Lebih Lanjut
                  </Button>
                </Grid>
              </Grid>
            )}
          </Box>
          <ActiveFilters />
          <AppBar position={"static"} color="secondary">
            <Tabs
              value={tab}
              onChange={handleTabChange}
              variant="fullWidth"
              classes={tabStyles}
            >
              <Tab classes={tabItemStyles} value="sell" label="Dijual" />
              <Tab classes={tabItemStyles} value="rent" label="Disewa" />
              <Tab classes={tabItemStyles} value="auction" label="Lelang" />
            </Tabs>
          </AppBar>
          {tab === "sell" && (
            <AppBar position={"static"} color="secondary">
              <Tabs
                value={subtab}
                onChange={handleSubTabChange}
                variant="fullWidth"
                classes={tabStyles}
              >
                <Tab classes={tabItemStyles} value="primary" label="Primary" />
                <Tab
                  classes={tabItemStyles}
                  value="secondary"
                  label="Secondary"
                />
              </Tabs>
            </AppBar>
          )}
          <Box my={1}>
            <PapGrid />
            <Pagination rowsPerPageOptions={[12, 24, 48, 64]} />
          </Box>
        </Container>
      </ListBase>
    </CatalogLayout>
  );
};

export default ListingListPage;
