import MomentUtils from "@date-io/moment";
import {
  Box,
  Container,
  Divider,
  Hidden,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Typography,
  withStyles,
  CircularProgress,
} from "@material-ui/core";
import { capitalize, set, upperFirst } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import {
  Create,
  FormTab,
  ImageInput,
  RadioButtonGroupInput,
  SaveButton,
  SimpleForm,
  TabbedForm,
  TextInput,
  Toolbar,
  Button,
  NullableBooleanInput,
  BooleanInput,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  AutocompleteInput,
  ImageField,
  FileInput,
  FileField,
  CheckboxGroupInput,
  ReferenceInput,
  required,
  FormWithRedirect,
  useGetIdentity,
  SelectArrayInput,
  TextField,
} from "react-admin";
import { Alert } from "@material-ui/lab";
import { DateInput, DateTimeInput } from "react-admin-date-inputs-refactor";
import { useHistory } from "react-router-dom";
import RichTextInput from "ra-input-rich-text";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { NumberInput } from "../CustomInputs";
import useAxios from "axios-hooks";
import {
  internalSpecList,
  externalSpecList,
  communitySpecList,
  certificateType,
  listingType,
} from "../mapping";
import { R123DevMode } from "../components";

const getOffset = (val, div = 1) => {
  const parse = parseFloat(val);
  return `${parse / div}${String(val).replace(String(parse), "") || "px"}`;
};

const GridIterator = withStyles((theme) => ({
  form: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    margin: `-${getOffset(theme.spacing(1), 2)}`,
    width: `calc(100% + ${getOffset(theme.spacing(1))})`,
    "& > *": {
      padding: getOffset(theme.spacing(1), 2),
    },
  },
}))(SimpleFormIterator);

const FormDataHelper = ({ formData, onChange }) => {
  useEffect(() => {
    onChange(formData);
  }, [formData]);

  return null;
};

const PapCreate = (props) => {
  const { identity } = useGetIdentity();
  const [tab, setTab] = useState("pap");
  const [internalSpec, setInternalSpec] = useState(internalSpecList);
  const [externalSpec, setExternalSpec] = useState(externalSpecList);
  const [communitySpec, setCommunitySpec] = useState(communitySpecList);
  const [formState, setFormState] = useState({});
  const [r123, setR123] = useState({});
  const [id, setId] = useState(null);
  const [{ data, loading, error }, refetch] = useAxios({}, { manual: true });

  useEffect(() => {
    console.log(formState);
    if (tab === "katalog" && formState.type) {
      refetch({
        method: "GET",
        url: `/api/rumah123/params/${
          listingType.find((t) => t.id === formState.type).r123
        }`,
      });
    }
  }, [tab, formState, listingType]);

  useEffect(() => {
    if (!loading && !error && data) {
      console.log(data);
      setR123(data);
    }
  }, [data, loading, error]);

  useEffect(() => {
    const loadNewID = async () => {
      try {
        const req = await axios.get(`/gen-pap-uuid`);

        if (req.data.id) {
          setId(req.data.id);
        }
      } catch (error) {
        console.error(error);
      }
    };

    loadNewID();

    return () => {
      setId(null);
    };
  }, []);

  const computedProps = useMemo(() => {
    console.log(props);

    return { ...props, record: { id } };
  }, [props, id]);

  const handleTabChange = (event, newValue) => setTab(newValue);

  const handleFormState = (state) => setFormState(state);

  return id ? (
    <Create {...computedProps}>
      <FormWithRedirect
        render={(formProps) => (
          <form>
            <FormDataConsumer>
              {(props) => (
                <FormDataHelper onChange={handleFormState} {...props} />
              )}
            </FormDataConsumer>
            <TextInput
              disabled
              source="id"
              label="ID Unik"
              fullWidth
              validate={[required()]}
            />
            <Tabs variant="scrollable" value={tab} onChange={handleTabChange}>
              <Tab value="pap" label="Form PAP" />
              <Tab value="katalog" label="Form E-Katalog" />
            </Tabs>
            <Hidden xsUp={tab !== "pap"} xsDown={tab !== "pap"}>
              <Container maxWidth="lg">
                <Box py={1}>
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <Box p={1}>
                        <Typography variant="h6" gutterBottom>
                          PEMILIK / VENDOR (PIHAK I)
                        </Typography>
                        <TextInput
                          source="owner.name"
                          label="Nama Lengkap"
                          fullWidth
                          validate={[required()]}
                        />
                        <TextInput
                          source="owner.nik"
                          label="NIK"
                          fullWidth
                          // validate={[required()]}
                        />
                        <TextInput
                          source="owner.address"
                          label="Alamat"
                          multiline
                          fullWidth
                          validate={[required()]}
                        />
                        <TextInput
                          source="owner.postal_code"
                          label="Kodepos"
                          fullWidth
                          // validate={[required()]}
                        />
                        <>
                          <Grid container spacing={1}>
                            <Grid item md={6}>
                              <TextInput
                                source="owner.phone"
                                label="No. Telepon"
                                fullWidth
                                // validate={[required()]}
                              />
                            </Grid>
                            <Grid item md={6}>
                              <TextInput
                                source="owner.fax"
                                label="No. Fax"
                                fullWidth
                                // validate={[required()]}
                              />
                            </Grid>
                          </Grid>
                        </>
                        <TextInput
                          source="owner.cellphone"
                          label="No. HP"
                          fullWidth
                          validate={[required()]}
                        />
                        <TextInput
                          source="owner.email"
                          fullWidth
                          label="Email"
                        />
                        <FormDataConsumer>
                          {({ formData, ...rest }) => (
                            <>
                              {(formData.marketing?.type || []).includes(
                                "auction"
                              ) && (
                                <TextInput
                                  fullWidth
                                  source="bank.name"
                                  label="Nama Bank"
                                  validate={[required()]}
                                />
                              )}
                              {(formData.marketing?.type || []).includes(
                                "auction"
                              ) && (
                                <TextInput
                                  fullWidth
                                  source="bank.address"
                                  label="Alamat Bank"
                                  validate={[required()]}
                                  multiline
                                />
                              )}
                              {(formData.marketing?.type || []).includes(
                                "auction"
                              ) && (
                                <TextInput
                                  source="bank.phone"
                                  label="No. Telpon Bank"
                                  fullWidth
                                  validate={[required()]}
                                />
                              )}
                              {(formData.marketing?.type || []).includes(
                                "auction"
                              ) && (
                                <TextInput
                                  source="bank.kpknl"
                                  label="KPKNL"
                                  fullWidth
                                  // validate={[required()]}
                                />
                              )}
                              {(formData.marketing?.type || []).includes(
                                "auction"
                              ) && (
                                <TextInput
                                  source="bank.lot"
                                  label="Kode Lot"
                                  fullWidth
                                  // validate={[required()]}
                                />
                              )}
                            </>
                          )}
                        </FormDataConsumer>
                        <Typography variant="h6" gutterBottom>
                          SALES CONSULTANT MYPRO (PIHAK II)
                        </Typography>
                        {identity?.role === "ADMIN" ? (
                          <>
                            <BooleanInput
                              source="is_current_user"
                              label="Saya sendiri?"
                            />
                            <FormDataConsumer>
                              {({ formData, ...rest }) =>
                                formData.is_current_user !== true && (
                                  <>
                                    <>
                                      <Box my={1} clone>
                                        <Typography color="textSecondary">
                                          Atau
                                        </Typography>
                                      </Box>
                                    </>
                                    <ReferenceInput
                                      source="sales.user_id"
                                      reference="users"
                                      label="Cari Agen"
                                      filter={{ role: "SALES" }}
                                    >
                                      <AutocompleteInput
                                        optionText="name"
                                        fullWidth
                                      />
                                    </ReferenceInput>
                                    {/* <>
                                  <Box my={1} clone>
                                    <Typography color="textSecondary">
                                      Atau
                                    </Typography>
                                  </Box>
                                </>
                                <Grid container direction="column">
                                  <Grid item>
                                    <TextInput
                                      source="sales.name"
                                      label="Nama Lengkap"
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item>
                                    <TextInput
                                      source="sales.phone"
                                      label="No. HP"
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item>
                                    <TextInput
                                      source="sales.email"
                                      label="Email"
                                      fullWidth
                                    />
                                  </Grid>
                                </Grid> */}
                                  </>
                                )
                              }
                            </FormDataConsumer>
                            <ReferenceInput
                              source="branch"
                              reference="branches"
                              label="Cabang"
                            >
                              <SelectInput optionText="name" />
                            </ReferenceInput>
                          </>
                        ) : (
                          <Grid
                            container
                            spacing={1}
                            alignItems="center"
                            alignContent="center"
                          >
                            <Grid item>
                              <CheckBoxIcon color="primary" fontSize="large" />
                            </Grid>
                            <Grid item>
                              <Typography variant="h5">
                                Saya Sendiri ({identity?.name})
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </Box>
                    </Grid>
                    <Grid item md={6}>
                      <Box p={1}>
                        <Typography variant="h6" gutterBottom>
                          DATA PROPERTI
                        </Typography>
                        <TextInput
                          source="property.address"
                          label="Alamat"
                          multiline
                          fullWidth
                          validate={[required()]}
                        />
                        <>
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <TextInput
                                source="property.rt"
                                label="RT"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextInput
                                source="property.rw"
                                label="RW"
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                        </>
                        <>
                          <Grid container spacing={1}>
                            <Grid item md={6}>
                              <ReferenceInput
                                source="property.province"
                                reference="postal_codes"
                                label="Pilih Provinsi"
                                filterToQuery={(province) => ({
                                  like__province: province,
                                  returns: ["province"],
                                })}
                                validate={[required()]}
                              >
                                <AutocompleteInput
                                  fullWidth
                                  optionText="province"
                                />
                              </ReferenceInput>
                            </Grid>
                            <Grid item md={6}>
                              <FormDataConsumer>
                                {({ formData, ...rest }) => (
                                  <ReferenceInput
                                    source="property.city"
                                    reference="postal_codes"
                                    label="Pilih Kota / Kabupaten"
                                    filter={{
                                      returns: ["city"],
                                      province: formData.property?.province,
                                    }}
                                    filterToQuery={(city) => ({
                                      like__city: city,
                                    })}
                                    validate={[required()]}
                                  >
                                    <AutocompleteInput
                                      fullWidth
                                      optionText="city"
                                      disabled={!formData.property?.province}
                                    />
                                  </ReferenceInput>
                                )}
                              </FormDataConsumer>
                            </Grid>
                          </Grid>
                        </>
                        <>
                          <Grid container spacing={1}>
                            <Grid item md={6}>
                              <FormDataConsumer>
                                {({ formData, ...rest }) => (
                                  <ReferenceInput
                                    source="property.district"
                                    reference="postal_codes"
                                    label="Pilih Kecamatan"
                                    filter={{
                                      returns: ["district"],
                                      province: formData.property?.province,
                                      city: formData.property?.city,
                                    }}
                                    filterToQuery={(district) => ({
                                      like__district: district,
                                    })}
                                    validate={[required()]}
                                  >
                                    <AutocompleteInput
                                      fullWidth
                                      optionText="district"
                                      disabled={!formData.property?.city}
                                    />
                                  </ReferenceInput>
                                )}
                              </FormDataConsumer>
                            </Grid>
                            <Grid item md={6}>
                              <FormDataConsumer>
                                {({ formData, ...rest }) => (
                                  <ReferenceInput
                                    source="property.village"
                                    reference="postal_codes"
                                    label="Pilih Kelurahan / Desa"
                                    filter={{
                                      returns: ["village"],
                                      province: formData.property?.province,
                                      city: formData.property?.city,
                                      district: formData.property?.district,
                                    }}
                                    filterToQuery={(village) => ({
                                      like__village: village,
                                    })}
                                    validate={[required()]}
                                  >
                                    <AutocompleteInput
                                      fullWidth
                                      optionText="village"
                                      disabled={!formData.property?.district}
                                    />
                                  </ReferenceInput>
                                )}
                              </FormDataConsumer>
                            </Grid>
                          </Grid>
                        </>
                        <>
                          <Grid container spacing={1}>
                            <Grid item md={6}>
                              <NumberInput
                                fullWidth
                                source="area.land"
                                label="Luas Tanah (M2)"
                              />
                            </Grid>
                            <Grid item md={6}>
                              <NumberInput
                                fullWidth
                                source="area.building"
                                label="Luas Bangunan (M2)"
                              />
                            </Grid>
                          </Grid>
                        </>
                        <TextInput
                          source="view"
                          label="Hadap / View"
                          fullWidth
                        />
                        <NumberInput
                          source="price.sell"
                          label="Harga Jual"
                          fullWidth
                        />
                        <>
                          <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            alignContent="center"
                          >
                            <Grid item md={6}>
                              <NumberInput
                                fullWidth
                                source="price.rent"
                                label="Harga Sewa"
                              />
                            </Grid>
                            <Grid item md={6}>
                              <RadioButtonGroupInput
                                source="rent.type"
                                choices={[
                                  { id: "monthly", name: "Per Bulan" },
                                  {
                                    id: "annually",
                                    name: "Per Tahun",
                                  },
                                ]}
                                label="Jenis Sewa"
                              />
                            </Grid>
                          </Grid>
                        </>
                        <>
                          <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            alignContent="center"
                          >
                            <Grid item md={4}>
                              <NumberInput
                                fullWidth
                                source="rent.duration"
                                label="Masa Sewa"
                              />
                            </Grid>
                            <Grid item md={4}>
                              <RadioButtonGroupInput
                                source="rent.duration_type"
                                choices={[
                                  { id: "month", name: "Bulan" },
                                  {
                                    id: "year",
                                    name: "Tahun",
                                  },
                                ]}
                                label="Jenis Masa Sewa"
                              />
                            </Grid>
                            <Grid item md={4}>
                              <NumberInput
                                fullWidth
                                source="rent.deposit"
                                label="Deposit"
                              />
                            </Grid>
                          </Grid>
                        </>
                        <FormDataConsumer>
                          {({ formData, ...rest }) => (
                            <>
                              {(formData.marketing?.type || []).includes(
                                "auction"
                              ) && (
                                <NumberInput
                                  source="price.bank"
                                  label="Harga Limit"
                                  fullWidth
                                  // validate={[required()]}
                                />
                              )}
                              {(formData.marketing?.type || []).includes(
                                "auction"
                              ) && (
                                <NumberInput
                                  source="price.warranty"
                                  label="Harga Jaminan"
                                  fullWidth
                                  // validate={[required()]}
                                />
                              )}
                              {(formData.marketing?.type || []).includes(
                                "auction"
                              ) && (
                                <NumberInput
                                  source="price.market"
                                  label="Harga Pasar"
                                  fullWidth
                                  // validate={[required()]}
                                />
                              )}
                            </>
                          )}
                        </FormDataConsumer>
                        <TextInput
                          source="condition"
                          label="Kondisi"
                          multiline
                          fullWidth
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid container spacing={1}>
                    <Grid item md={6}>
                      <Box p={1}>
                        <RadioButtonGroupInput
                          source="marketing.rights"
                          choices={[
                            { id: "open", name: "Open" },
                            {
                              id: "sole",
                              name: "Sole Agent",
                            },
                            {
                              id: "exclusive",
                              name: "Exclusive",
                            },
                          ]}
                          label="Jenis Hak Pemasaran"
                          validate={[required()]}
                        />
                        <FormDataConsumer>
                          {({ formData, ...rest }) => (
                            <>
                              {formData.marketing?.rights === "open" && (
                                <Typography
                                  color="textSecondary"
                                  variant="subtitle2"
                                >
                                  Pemilik bebas menjual sendiri serta menunjuk
                                  agen properti lain.
                                </Typography>
                              )}
                              {formData.marketing?.rights === "sole" && (
                                <Typography
                                  color="textSecondary"
                                  variant="subtitle2"
                                >
                                  Pemilik dapat menjual sendiri namun hanya
                                  menunjuk satu agen properti, yaitu Mypro.
                                </Typography>
                              )}
                              {formData.marketing?.rights === "exclusive" && (
                                <Typography
                                  color="textSecondary"
                                  variant="subtitle2"
                                >
                                  Pemilik tidak dapat menjual sendiri dan
                                  menyerahkan hak pemasaran sepenuhnya kepada
                                  Mypro.
                                </Typography>
                              )}
                            </>
                          )}
                        </FormDataConsumer>
                        <CheckboxGroupInput
                          source="marketing.type"
                          choices={[
                            { id: "sell", name: "Dijual" },
                            identity?.role === "ADMIN"
                              ? { id: "auction", name: "Dilelang" }
                              : null,
                            {
                              id: "rent",
                              name: "Disewakan",
                            },
                          ].filter(Boolean)}
                          label="Jenis Pemasaran"
                          validate={[required()]}
                        />
                        <FormDataConsumer>
                          {({ formData, ...rest }) => (
                            <>
                              {(formData.marketing?.type || []).includes(
                                "sell"
                              ) && (
                                <RadioButtonGroupInput
                                  source="marketing.sell_type"
                                  choices={[
                                    {
                                      id: "primary",
                                      name: "Primary",
                                    },
                                    {
                                      id: "secondary",
                                      name: "Secondary",
                                    },
                                  ]}
                                  label="Primary / Secondary"
                                  validate={[required()]}
                                />
                              )}
                            </>
                          )}
                        </FormDataConsumer>
                        <RadioButtonGroupInput
                          source="marketing.deadline_type"
                          choices={[
                            {
                              id: "by-date",
                              name: "Sampai dengan tanggal",
                            },
                            {
                              id: "unrestricted",
                              name: "Sampai terjual / tersewa / informasi lebih lanjut dari pemilik",
                            },
                          ]}
                          label="Masa Berlaku Hak Pemasaran"
                          validate={[required()]}
                        />
                        <FormDataConsumer>
                          {({ formData, ...rest }) =>
                            formData.marketing?.deadline_type === "by-date" && (
                              <DateInput
                                fullWidth
                                source="marketing.deadline_date"
                                label="Tanggal Akhir Hak Pemasaran"
                                options={{
                                  format: "DD/MM/YYYY",
                                  inputVariant: "filled",
                                  margin: "dense",
                                  fullWidth: true,
                                }}
                                providerOptions={{
                                  utils: MomentUtils,
                                }}
                              />
                            )
                          }
                        </FormDataConsumer>
                        <RadioButtonGroupInput
                          source="type"
                          choices={listingType}
                          label="Tipe"
                          validate={[required()]}
                        />
                        <RadioButtonGroupInput
                          source="certificate.type"
                          choices={certificateType}
                          label="Sertifikat"
                          validate={[required()]}
                        />
                        <FormDataConsumer>
                          {({ formData, ...rest }) => (
                            <>
                              {(formData.certificate?.type === "shgb" ||
                                formData.certificate?.type === "hak") && (
                                <DateInput
                                  fullWidth
                                  source="certificate.expire"
                                  label="Tanggal Akhir SHGB / Hak Pakai"
                                  options={{
                                    format: "DD/MM/YYYY",
                                    inputVariant: "filled",
                                    margin: "dense",
                                    fullWidth: true,
                                  }}
                                  providerOptions={{
                                    utils: MomentUtils,
                                  }}
                                />
                              )}
                              {formData.certificate?.type === "lain" && (
                                <TextInput
                                  fullWidth
                                  source="certificate.other"
                                  label="Detail Lainnya"
                                />
                              )}
                            </>
                          )}
                        </FormDataConsumer>
                      </Box>
                    </Grid>
                    <Grid item md={6}>
                      <Box p={1}>
                        <Typography variant="h6" gutterBottom>
                          INFORMASI:
                        </Typography>
                        <Typography variant="body2">
                          1. Pemilik dan Sales Consultant (SC) untuk mengisi PAP
                          ini dengan lengkap dan jelas, agar pemasaran properti
                          lebih efektif.
                        </Typography>
                        <Typography variant="body2">
                          2. Setiap properti cukup didaftarkan dengan satu PAP.
                        </Typography>
                        <Typography variant="body2">
                          3. SC wajib menjelaskan seluruh ketentuan dalam PAP
                          kepada Pemilik dan menyerahkan satu copy PAP yang
                          telah diisi lengkap dan ditandatangani kepada Pemilik.
                        </Typography>
                        <Typography variant="body2">
                          4. Pemilik dianjurkan untuk selalu menghubungi
                          SC/Principal mengenai kondisi properti dalam PAP ini.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                          KETENTUAN MENGENAI HAK PEMASARAN EXCLUSIVE:
                        </Typography>
                        <Typography variant="body2">
                          1. SC wajib melaporkan seluruh aktivitas pemasaran dan
                          identitas peminat secara berkala kepada Pemilik.
                        </Typography>
                        <Typography variant="body2">
                          2. Mypro berhak atas jasa pemasaran, apabila selama
                          masa Hak Pemasaran Exclusive, Pemilik memasarkan
                          sendiri atau melalui jasa pihak lain, membatalkan PAP
                          ini, atau melakukan perubahan atas properti sehingga
                          tidak lagi dapat dipasarkan.
                        </Typography>
                        <Typography variant="body2">
                          3. Mypro tetap berhak atas jasa pemasaran, apabila
                          dalam waktu kurang dari tiga bulan sejak Hak Pemasaran
                          Exclusive berakhir terjadi transaksi dengan pihak lain
                          yang sudah pernah diprospek oleh Mypro.
                        </Typography>
                        <Typography variant="body2">
                          4. Mypro berhak memasarkan properti melalui kerjasama
                          dengan agen properti lain atau pihak ketiga lainnya.
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Divider />
                  <ArrayInput
                    source="facilities.specification"
                    label="Spesifikasi"
                  >
                    <GridIterator>
                      <SelectInput
                        source="type"
                        choices={internalSpec}
                        onCreate={() => {
                          const name = upperFirst(
                            prompt("Tambahkan Spesifikasi")
                          );

                          if (name) {
                            const newItem = {
                              // id: `custom-spec-${internalSpec.length + 1}`,
                              id: name.toLowerCase(),
                              name,
                            };

                            // specifications.push(newItem);

                            setInternalSpec([...internalSpec, newItem]);

                            return newItem;
                          }
                        }}
                        createLabel="++ Tambah Pilihan ++"
                        createItemLabel="++ Tambah Pilihan ++"
                        label="Tipe"
                        validate={[required()]}
                      />
                      <NumberInput
                        source="qty"
                        label="Jumlah"
                        min={1}
                        validate={[required()]}
                      />
                      <TextInput source="description" label="Keterangan" />
                    </GridIterator>
                  </ArrayInput>
                  <ArrayInput source="facilities.internal" label="Fasilitas">
                    <GridIterator>
                      <SelectInput
                        source="type"
                        choices={externalSpec}
                        onCreate={() => {
                          const name = upperFirst(
                            prompt("Tambahkan Spesifikasi")
                          );

                          if (name) {
                            const newItem = {
                              // id: `custom-spec-${externalSpec.length + 1}`,
                              id: name.toLowerCase(),
                              name,
                            };

                            // specifications.push(newItem);

                            setExternalSpec([...externalSpec, newItem]);

                            return newItem;
                          }
                        }}
                        createLabel="++ Tambah Pilihan ++"
                        createItemLabel="++ Tambah Pilihan ++"
                        label="Tipe"
                        validate={[required()]}
                      />
                    </GridIterator>
                  </ArrayInput>
                  <ArrayInput
                    source="facilities.external"
                    label="Fasilitas Lingkungan"
                  >
                    <GridIterator>
                      <SelectInput
                        source="type"
                        choices={communitySpec}
                        onCreate={() => {
                          const name = upperFirst(
                            prompt("Tambahkan Spesifikasi")
                          );

                          if (name) {
                            const newItem = {
                              // id: `custom-spec-${communitySpec.length + 1}`,
                              id: name.toLowerCase(),
                              name,
                            };

                            // specifications.push(newItem);

                            setCommunitySpec([...communitySpec, newItem]);

                            return newItem;
                          }
                        }}
                        createLabel="++ Tambah Pilihan ++"
                        createItemLabel="++ Tambah Pilihan ++"
                        label="Tipe"
                        validate={[required()]}
                      />
                    </GridIterator>
                  </ArrayInput>
                  <Divider />
                  <TextInput
                    source="additional.info"
                    label="Keterangan Lainnya"
                    multiline
                    fullWidth
                  />
                  <Divider />
                  <Box clone my={1}>
                    <Typography>
                      <Box fontWeight="bold" component="span">
                        PIHAK I
                      </Box>{" "}
                      memberikan kuasa kepada{" "}
                      <Box fontWeight="bold" component="span">
                        PIHAK II
                      </Box>{" "}
                      untuk memasarkan properti tersebut diatas dengan
                      ketentuan, sebagai berikut:
                    </Typography>
                  </Box>
                  <ol>
                    <li>
                      <Typography variant="body2">
                        <Box fontWeight="bold" component="span">
                          PIHAK I
                        </Box>{" "}
                        menjamin bahwa:
                      </Typography>
                      <ol type="a">
                        <li>
                          <Typography variant="body2">
                            <Box fontWeight="bold" component="span">
                              PIHAK I
                            </Box>{" "}
                            adalah pemilik yang sah dan mempunyai hak atas
                            properti tersebut diatas.
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="body2">
                            Properti tersebut diatas tidak dalam sengketa dengan
                            pihak manapun.
                          </Typography>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <Typography variant="body2">
                        <Box fontWeight="bold" component="span">
                          PIHAK I
                        </Box>{" "}
                        dengan ini memberikan ijin kepada{" "}
                        <Box fontWeight="bold" component="span">
                          PIHAK II
                        </Box>{" "}
                        , untuk:
                      </Typography>
                      <ol type="a">
                        <li>
                          <Typography variant="body2">
                            Memperlihatkan properti dan Open House.
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="body2">
                            Memasang papan tanda/banner bertuliskan "Dijual /
                            Disewakan".
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="body2">
                            Menerima titipan tanda jadi dari pembeli / penyewa
                            melalui rekening perusahaan{" "}
                            <Box fontWeight="bold" component="span">
                              PIHAK II
                            </Box>
                            .
                          </Typography>
                        </li>
                        <li>
                          <Grid
                            container
                            spacing={1}
                            alignItems="center"
                            alignContent="center"
                          >
                            <Grid item>
                              <Typography variant="body2">
                                <Box fontWeight="bold" component="span">
                                  PIHAK I
                                </Box>{" "}
                                setuju membayar Success Fee (Komisi Agen)
                                sebesar
                              </Typography>
                            </Grid>
                            <Grid item>
                              <NumberInput
                                source="success.fee_sell"
                                label="% Success Fee (Jual)"
                                min={0}
                                max={100}
                                variant="standard"
                                margin="none"
                              />
                            </Grid>
                            <Grid item>
                              <Typography variant="body2">atau</Typography>
                            </Grid>
                            <Grid item>
                              <NumberInput
                                source="success.fee_rent"
                                label="% Success Fee (Sewa)"
                                min={0}
                                max={100}
                                variant="standard"
                                margin="none"
                              />
                            </Grid>
                            <Grid item>
                              <Typography variant="body2">
                                , dari nilai transaksi yang terjadi.
                              </Typography>
                            </Grid>
                          </Grid>
                        </li>
                        <li>
                          <Typography variant="body2">
                            Setiap perkara yang timbul dari Perjanjian ini, Para
                            Pihak telah sepakat mengadakan penyelesaian secara
                            musyawarah.
                          </Typography>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </Box>
              </Container>
            </Hidden>
            <Hidden xsUp={tab !== "katalog"} xsDown={tab !== "katalog"}>
              <Container maxWidth="lg">
                <Box py={1}>
                  <TextInput
                    source="title"
                    label="Judul"
                    fullWidth
                    // validate={[required()]}
                  />
                  <RichTextInput
                    source="description"
                    label="Deskripsi"
                    fullWidth
                    multiline
                    // validate={[required()]}
                  />
                  <TextInput
                    source="display.address"
                    label="Alamat Singkat"
                    fullWidth
                  />
                  <TextInput
                    source="maps_url"
                    label="Link Google Map"
                    fullWidth
                  />
                  <>
                    <Box clone fontStyle="italic">
                      <Typography color="textSecondary" variant="subtitle2">
                        *Masuk Google Maps lalu cari alamat, setelah ditemukan
                        klik share dan copy link tersebut ke dalam kotak ini.
                      </Typography>
                    </Box>
                  </>
                  {(identity?.role === "ADMIN" ||
                    (identity?.role === "SALES" && identity?.rumah_id)) && (
                    <>
                      <Box py={1}>
                        <Typography variant="h6">Data Rumah123</Typography>
                        <R123DevMode />
                        <TextInput
                          source="r123.description"
                          label="Deskripsi Iklan"
                          fullWidth
                          multiline
                          // validate={[required()]}
                        />
                        <FormDataConsumer>
                          {({ formData, ...rest }) =>
                            !formData.type && (
                              <Box clone my={1}>
                                <Alert severity="info">
                                  Harap pilih dahulu tipe properti yang akan
                                  diiklankan
                                </Alert>
                              </Box>
                            )
                          }
                        </FormDataConsumer>
                        <Grid container spacing={1}>
                          {r123.face ? (
                            <Grid item xs={12} md={3}>
                              <SelectInput
                                fullWidth
                                choices={r123.face}
                                source="r123.face"
                                label="Hadap"
                              />
                            </Grid>
                          ) : (
                            <></>
                          )}
                          {r123.electricity ? (
                            <Grid item xs={12} md={3}>
                              <SelectInput
                                fullWidth
                                choices={r123.electricity}
                                source="r123.electricity"
                                label="Watt Listrik"
                              />
                            </Grid>
                          ) : (
                            <></>
                          )}
                          {r123.furnitureCondition ? (
                            <Grid item xs={12} md={3}>
                              <SelectInput
                                fullWidth
                                choices={r123.furnitureCondition}
                                source="r123.furnitureCondition"
                                label="Kondisi Furnitur"
                              />
                            </Grid>
                          ) : (
                            <></>
                          )}
                          {r123.propertyCondition ? (
                            <Grid item xs={12} md={3}>
                              <SelectInput
                                fullWidth
                                choices={r123.propertyCondition}
                                source="r123.propertyCondition"
                                label="Kondisi Properti"
                              />
                            </Grid>
                          ) : (
                            <></>
                          )}
                          {r123.inHouseFacility ? (
                            <Grid item xs={12} md={3}>
                              <SelectArrayInput
                                fullWidth
                                choices={r123.inHouseFacility}
                                source="r123.inHouseFacility"
                                label="Fasilitas Rumah"
                              />
                            </Grid>
                          ) : (
                            <></>
                          )}
                          {r123.residentialFacility ? (
                            <Grid item xs={12} md={3}>
                              <SelectArrayInput
                                fullWidth
                                choices={r123.residentialFacility}
                                source="r123.residentialFacility"
                                label="Fasilitas Lingkungan"
                              />
                            </Grid>
                          ) : (
                            <></>
                          )}
                          {r123.imb ? (
                            <Grid item xs={12} md={3}>
                              <SelectInput
                                fullWidth
                                choices={r123.imb}
                                source="r123.imb"
                                label="IMB"
                              />
                            </Grid>
                          ) : (
                            <></>
                          )}
                          {r123.priceUnit ? (
                            <Grid item xs={12} md={3}>
                              <SelectInput
                                fullWidth
                                choices={r123.priceUnit}
                                source="r123.priceUnit"
                                label="Harga per"
                              />
                            </Grid>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Box>
                    </>
                  )}
                  {identity?.role === "ADMIN" && (
                    <BooleanInput
                      source="active"
                      label="Langsung Dipasarkan?"
                    />
                  )}
                  <Divider />
                  <ImageInput
                    source="photo.primary"
                    label="Foto Utama"
                    accept="image/*"
                    maxSize={5000000}
                    validate={[required()]}
                  >
                    <ImageField source="src" title="title" />
                  </ImageInput>
                  <ImageInput
                    source="photo.others"
                    label="Foto Lainnya"
                    multiple
                    accept="image/*"
                    maxSize={5000000}
                  >
                    <ImageField source="src" title="title" />
                  </ImageInput>
                  <FileInput
                    source="files"
                    label="Dokumen terkait"
                    multiple
                    maxSize={10000000}
                  >
                    <FileField source="src" title="title" />
                  </FileInput>
                </Box>
              </Container>
            </Hidden>
            <Toolbar>
              <SaveButton
                saving={formProps.saving}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              />
            </Toolbar>
          </form>
        )}
      />
    </Create>
  ) : (
    <Box
      display="flex"
      justifyContent={"center"}
      alignContent={"center"}
      alignItems={"center"}
    >
      <CircularProgress size={96} variant="indeterminate" />
    </Box>
  );
};

export default PapCreate;
