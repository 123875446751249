import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  EmailField,
  TextInput,
  SelectField,
  useRecordContext,
  TopToolbar,
  CreateButton,
  SimpleList,
  BooleanInput,
  useGetIdentity,
  ReferenceInput,
  ReferenceField,
  AutocompleteInput,
  FunctionField,
} from "react-admin";
import React from "react";
import { Avatar, useMediaQuery, useTheme } from "@material-ui/core";
import { useACL } from "ra-access-control-lists";
import JsFileDownloader from "js-file-downloader";

const Filters = [
  <ReferenceInput
    source="authenticatable.id"
    reference="users"
    label="Pengguna"
    alwaysOn
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
];

const LogList = (props) => {
  return (
    <List
      bulkActionButtons={false}
      filters={Filters}
      sort={{ field: "id", order: "DESC" }}
      {...props}
    >
      <Datagrid rowClick={false}>
        <TextField source="id" />
        <ReferenceField
          source="authenticatable.id"
          reference="users"
          label="Pengguna"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="ip.address" label="IP" />
        <TextField source="user.agent" label="Browser" />
        <DateField source="login.at" showTime label="Login" />
        <DateField source="logout.at" showTime label="Logout" />
      </Datagrid>
    </List>
  );
};

export default LogList;
