import { createMuiTheme } from "@material-ui/core";

const baseTheme = createMuiTheme({
  palette: {
    type: "light", // Switching the dark mode on is a single property value change.
    primary: { main: "#59852E" },
    secondary: { main: "#3c4a85" },
    error: { main: "#A53860" },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: [
      "Inter",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  overrides: {
    MuiButton: {
      label: {
        textTransform: "initial",
      },
    },
  },
});

const theme = {
  ...baseTheme,
  shadows: [...Array(25).keys()].map((i) =>
    i === 0
      ? "none"
      : `0 2px ${2.5 + i / 1.75}px 1px rgba(0, 0, 0, 0.14), 0 4px ${
          2.5 + i / 1.75
        }px -7px ${baseTheme.palette.background.default}`
  ),
  overrides: {
    ...baseTheme.overrides,
    MuiAppBar: {
      colorDefault: {
        backgroundColor: baseTheme.palette.background.default,
      },
      colorSecondary: {
        background: `linear-gradient(60deg, ${baseTheme.palette.secondary.main} 50%, ${baseTheme.palette.secondary.dark} 90%)`,
      },
      colorPrimary: {
        background: `linear-gradient(60deg, ${baseTheme.palette.primary.main} 50%, ${baseTheme.palette.primary.dark} 90%)`,
      },
    },
    MuiBottomNavigation: {
      root: {
        backgroundColor: baseTheme.palette.background.default,
      },
    },
    MuiButton: {
      label: {
        textTransform: "initial",
      },
      containedPrimary: {
        boxShadow: `0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 20px -5px ${baseTheme.palette.primary.main} !important`,
        // background: `linear-gradient(60deg, ${baseTheme.palette.primary.main} 50%, ${baseTheme.palette.primary.dark}) 90%`,
        // color: baseTheme.palette.getContrastText(
        //   baseTheme.palette.primary.main
        // ),
        "&$disabled": {
          boxShadow: "none !important",
        },
      },
      containedSecondary: {
        boxShadow: `0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 20px -5px ${baseTheme.palette.secondary.main} !important`,
        // background: `linear-gradient(60deg, ${baseTheme.palette.secondary.main} 50%, ${baseTheme.palette.secondary.dark}) 90%`,
        // color: baseTheme.palette.getContrastText(
        //   baseTheme.palette.secondary.main
        // ),
        "&$disabled": {
          boxShadow: "none !important",
        },
      },
    },
  },
};

export default theme;
