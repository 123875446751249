import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  fade,
  Fade,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  styled,
} from "@material-ui/core";
import React, { useMemo, useState } from "react";
import CatalogLayout, { BackdropContent } from "./layout";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import RefreshIcon from "@material-ui/icons/Refresh";
import { useHistory, useLocation, useParams } from "react-router-dom";
import useAxios from "axios-hooks";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { useSetStoreValue, useStore } from "react-context-hook";
import { useEffect } from "react";
import {
  communitySpecList,
  externalSpecList,
  internalSpecList,
  listingType,
} from "../mapping";
import { upperFirst } from "lodash";
import { ListItemIcon } from "@material-ui/core";
// import { Skeleton } from "@material-ui/lab";
import StarIcon from "@material-ui/icons/Star";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PhoneIcon from "@material-ui/icons/Phone";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import MailIcon from "@material-ui/icons/Mail";
import { ListBase, Pagination, useShowController } from "react-admin";
import { Card } from "@material-ui/core";
import ns from "number-string";
import Viewer from "react-viewer";
import { PapGrid } from "./list";

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LineIcon,
  LineShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import Markdown from "markdown-to-jsx";
import theme from "../theme";
import Carousel from "react-material-ui-carousel";

const Skeleton = () => null;

// const useStyles = makeStyles((theme) => ({
//   backdropRoot: {
//     backgroundImage: ({ photos, index = 0 }) =>
//       photos.length > 0
//         ? `radial-gradient(transparent, rgba(0,0,0,0.5)), url(${photos[index]})`
//         : 'url("/storage/listing_placeholder.png")',
//     backgroundColor: theme.palette.background.default,
//     backgroundSize: ({ photos, index = 0 }) =>
//       photos.length > 0 ? "100% 100%, cover" : "cover",
//     backgroundRepeat: "no-repeat",
//     backgroundPosition: "center",
//     transition: "background-image 0.2s ease-in-out",
//   },
// }));

const CarouselItem = styled(Paper)(({ photo }) => ({
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundImage: `url(${photo})`,
  height: theme.spacing(50),
}));

const useStyles = makeStyles(() => ({
  backdropRoot: {
    backgroundImage: `linear-gradient(to bottom, ${theme.palette.secondary.main} 10%, ${theme.palette.secondary.dark} 75%)`,
  },
  frontLayer: {
    backgroundImage: ({ photos }) =>
      `linear-gradient(${fade(theme.palette.secondary.light, 0.95)}, ${fade(
        theme.palette.grey[100],
        0.95
      )} 35%), ${
        photos.length > 0
          ? `url(${photos[0]}`
          : // : 'url("/storage/listing_placeholder.png")'
            'url("/storage/sale.png")'
      }`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
}));

const ListText = ({ title, content }) => (
  <ListItem>
    <ListItemText
      primary={title}
      secondary={content}
      primaryTypographyProps={{
        color: "textSecondary",
        variant: "subtitle2",
      }}
      secondaryTypographyProps={{
        color: "textPrimary",
        variant: "body1",
      }}
    />
  </ListItem>
);

const RefreshViewCount = ({ listingId }) => {
  const [{ data, loading, error }] = useAxios({
    url: `/api/markViewed/${listingId}`,
  });

  return (
    <Fade in={!loading && !error && !!data} mountOnEnter unmountOnExit>
      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
      >{`Telah dilihat ${data?.total}x`}</Typography>
    </Fade>
  );
};

const ListingDetailPage = () => {
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useStore("backdrop_open", false);
  const setBackdropClasses = useSetStoreValue("backdrop_classes");
  const [visible, setVisible] = useState(false);
  const [index, setIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const { id } = useParams();
  // const [{ data, loading, error }, refetch] = useAxios({
  //   url: `/api/public-listings/${id}`,
  // });
  const {
    loading,
    record: data,
    loaded,
  } = useShowController({ basePath: "/catalog", resource: "listings", id });

  const photos = useMemo(
    () =>
      !loading && loaded
        ? [
            data.photo?.primary?.src,
            ...data.photo?.others.map((p) => p.src),
          ].filter(Boolean)
        : [],
    [data, loading, loaded]
  );

  const classes = useStyles({ photos, index });

  // useEffect(() => {
  //   setOpen(true);
  //   return () => {
  //     setOpen(false);
  //   };
  // }, []);

  useEffect(() => {
    if (classes) {
      setBackdropClasses({
        root: classes.backdropRoot,
        frontLayer: classes.frontLayer,
      });
    }
  }, [setBackdropClasses, classes]);

  return (
    <CatalogLayout>
      <BackdropContent>
        <Box p={1} color="white">
          <Grid
            container
            spacing={1}
            justify="space-between"
            alignItems="center"
            alignContent="center"
          >
            <Grid item>
              <IconButton
                color="inherit"
                onClick={() =>
                  location.state?.internal
                    ? history.goBack()
                    : history.push("/catalog")
                }
              >
                <KeyboardBackspaceIcon />
              </IconButton>
            </Grid>
            <Hidden mdDown>
              <Grid item>
                <Grid
                  container
                  spacing={1}
                  justify="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid item>
                    <Avatar src={"/storage/logo.jpg"} />
                  </Grid>
                  <Grid item>
                    <Typography variant="h5" component="h2" color="inherit">
                      MYPRO INDONESIA
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>
            <Grid item>
              <ButtonBase onClick={(event) => setAnchorEl(event.currentTarget)}>
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid item>
                    <Avatar
                      src={
                        !loading
                          ? data.sales?.avatar?.src || "/storage/logo.jpg"
                          : null
                      }
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="button" component="h3" color="inherit">
                      {!loading ? data.sales?.name : <Skeleton />}
                    </Typography>
                  </Grid>
                </Grid>
              </ButtonBase>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem
                  component="a"
                  disabled={!data?.sales?.phone}
                  href={`tel:${data?.sales?.phone}`}
                >
                  <ListItemIcon>
                    <PhoneIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Hubungi"
                    secondary={!loading ? data.sales?.phone : <Skeleton />}
                  />
                </MenuItem>
                <MenuItem
                  component="a"
                  disabled={!data?.sales?.phone}
                  href={`https://wa.me/${String(
                    data?.sales?.phone || ""
                  ).replace("08", "628")}?text=Hai ${
                    data?.sales?.name
                  }, saya tertarik dengan "${data?.title}" [https//${
                    window.location.hostname
                  }/c/${data?.code}]`}
                >
                  <ListItemIcon>
                    <WhatsAppIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Chat WA"
                    secondary={!loading ? data?.sales?.phone : <Skeleton />}
                  />
                </MenuItem>
                <MenuItem
                  component="a"
                  disabled={!data?.sales?.email}
                  href={`mailto:${data?.sales?.email}?subject=Saya tertarik dengan "${data?.title}" [${data?.code}]&body=Hai ${data?.sales?.name}, saya berminat dengan penawaran di alamat web berikut: https//${window.location.hostname}/c/${data?.code}. Mohon segera kontak kembali saya.`}
                >
                  <ListItemIcon>
                    <MailIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Email"
                    secondary={!loading ? data.sales?.email : <Skeleton />}
                  />
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Box>
      </BackdropContent>
      <Container maxWidth="lg">
        <Carousel>
          {photos.map((p, idx) => (
            <CarouselItem photo={p} key={idx} elevation={2}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                height="100%"
              >
                <IconButton
                  color="primary"
                  onClick={() => setVisible(true)}
                  disabled={photos.length === 0}
                >
                  <VisibilityIcon />
                </IconButton>
              </Box>
            </CarouselItem>
          ))}
        </Carousel>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          {!loading ? data.title : <Skeleton />}
        </Typography>
        <Box marginBottom={10}>
          <RefreshViewCount listingId={id} />
        </Box>
        <Box my={3}>
          <Typography variant="body1" component="p" gutterBottom>
            {!loading ? (
              <Markdown>{data.description || ""}</Markdown>
            ) : (
              <Skeleton />
            )}
          </Typography>
          {!loading && loaded && data && data.embed_map && (
            <Card variant="outlined">
              <CardContent>
                <Box clone border="none">
                  <iframe
                    src={data.embed_map}
                    width="100%"
                    height="400"
                    allowFullScreen
                    loading="lazy"
                  />
                </Box>
              </CardContent>
            </Card>
          )}
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" component="h2">
              Informasi
            </Typography>
            <List>
              <ListText
                title="Kode"
                content={!loading ? data.code : <Skeleton />}
              />
              <ListText
                title="Tipe"
                content={
                  !loading ? (
                    listingType.find((l) => l.id === data.type)?.name || "-"
                  ) : (
                    <Skeleton />
                  )
                }
              />
              <ListText
                title="Alamat"
                content={
                  !loading ? (
                    // `${data.property.address}, RT ${data.property.rt}, RW ${
                    //   data.property.rw
                    // }, Kelurahan ${data.property.village || "?"}, Kecamatan ${
                    //   data.property.district || "?"
                    // }, ${data.property.city || "?"}`
                    data.display.address
                  ) : (
                    <Skeleton />
                  )
                }
              />
              <ListText
                title="Harga Jual"
                content={
                  !loading ? (
                    ns.toMoney(data.price?.sell || 0, {
                      decimalMark: ",",
                      thousandSeparator: ".",
                      symbol: "Rp. ",
                      minPrecision: 0,
                      maxPrecision: 0,
                    })
                  ) : (
                    <Skeleton />
                  )
                }
              />
              <ListText
                title="Harga Sewa"
                content={
                  !loading ? (
                    ns.toMoney(data.price?.rent || 0, {
                      decimalMark: ",",
                      thousandSeparator: ".",
                      symbol: "Rp. ",
                      minPrecision: 0,
                      maxPrecision: 0,
                    })
                  ) : (
                    <Skeleton />
                  )
                }
              />
              <ListText
                title="Sertifikat"
                content={
                  !loading ? (
                    String(data.certificate.type || "").toUpperCase()
                  ) : (
                    <Skeleton />
                  )
                }
              />
              <ListText
                title="Luas Tanah/Bangunan"
                content={
                  !loading ? (
                    <>
                      {data.area.land} m<sup>2</sup> / {data.area.building} m
                      <sup>2</sup>
                    </>
                  ) : (
                    <Skeleton />
                  )
                }
              />
              <ListText
                title="Hadap"
                content={!loading ? data.view : <Skeleton />}
              />
            </List>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" component="h2">
              Fasilitas
            </Typography>
            <List>
              {!loading &&
                (data.facilities.specification || []).map((f, idx) => (
                  <ListItem key={idx}>
                    <ListItemIcon>
                      {internalSpecList.find((s) => s.id === f.id)?.icon || (
                        <StarIcon />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        internalSpecList.find((s) => s.id === f.id)?.name ||
                        upperFirst(f.id)
                      }
                      secondary={`Ada ${f.qty}${
                        f.description ? `, ${f.description}` : ""
                      }`}
                    />
                  </ListItem>
                ))}
              {!loading &&
                (data.facilities.internal || []).map((f, idx) => (
                  <ListItem key={idx}>
                    <ListItemIcon>
                      {externalSpecList.find((s) => s.id === f.id)?.icon || (
                        <StarIcon />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        externalSpecList.find((s) => s.id === f.id)?.name ||
                        upperFirst(f.id)
                      }
                      secondary={`${f.qty > 1 ? f.qty : "Ada"}${
                        f.description ? `, ${f.description}` : ""
                      }`}
                    />
                  </ListItem>
                ))}
              {!loading &&
                (data.facilities.external || []).map((f, idx) => (
                  <ListItem key={idx}>
                    <ListItemIcon>
                      {communitySpecList.find((s) => s.id === f.id)?.icon || (
                        <StarIcon />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        communitySpecList.find((s) => s.id === f.id)?.name ||
                        upperFirst(f.id)
                      }
                      secondary={`${f.qty > 1 ? f.qty : "Ada"}${
                        f.description ? `, ${f.description}` : ""
                      }`}
                    />
                  </ListItem>
                ))}
            </List>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <Box clone height={400}>
                <CardMedia
                  image={
                    !loading
                      ? data.sales?.avatar?.src || "/storage/logo.jpg"
                      : null
                  }
                />
              </Box>
              <CardContent>
                <Typography variant="h5" component="h3">
                  {!loading ? data.sales?.name : <Skeleton />}
                </Typography>
              </CardContent>
              <CardActions>
                <Grid container justify="space-evenly">
                  <Grid item>
                    <Button
                      disabled={!data?.sales?.phone}
                      component="a"
                      href={`tel:${data?.sales?.phone}`}
                      startIcon={<PhoneIcon />}
                    >
                      Hubungi
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={!data?.sales?.phone}
                      component="a"
                      href={`https://wa.me/${String(
                        data?.sales?.phone || ""
                      ).replace("08", "628")}?text=Hai ${
                        data?.sales?.name
                      }, saya tertarik dengan "${data?.title}" [https//${
                        window.location.hostname
                      }/c/${data?.code}]`}
                      startIcon={<WhatsAppIcon />}
                    >
                      Chat WA
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={!data?.sales?.email}
                      component="a"
                      href={`mailto:${data?.sales?.email}?subject=Saya tertarik dengan "${data?.title}" [${data?.code}]&body=Hai ${data?.sales?.name}, saya berminat dengan penawaran di alamat web berikut: https//${window.location.hostname}/c/${data?.code}. Mohon segera kontak kembali saya.`}
                      startIcon={<MailIcon />}
                    >
                      Kirim Email
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
            <Box marginTop={2}>
              <Typography align="center" gutterBottom>
                Share
              </Typography>
              <Grid
                container
                spacing={1}
                justify="space-evenly"
                alignItems="center"
                alignContent="center"
              >
                <Grid item>
                  <EmailShareButton
                    title={`[MYPRO INDONESIA] ${data?.title}`}
                    url={`${window.location.protocol}//${window.location.hostname}/c/${data?.code}`}
                  >
                    <EmailIcon size={32} round={true} />
                  </EmailShareButton>
                </Grid>
                <Grid item>
                  <FacebookShareButton
                    title={`[MYPRO INDONESIA] ${data?.title}`}
                    url={`${window.location.protocol}//${window.location.hostname}/c/${data?.code}`}
                  >
                    <FacebookIcon size={32} round={true} />
                  </FacebookShareButton>
                </Grid>
                <Grid item>
                  <LineShareButton
                    title={`[MYPRO INDONESIA] ${data?.title}`}
                    url={`${window.location.protocol}//${window.location.hostname}/c/${data?.code}`}
                  >
                    <LineIcon size={32} round={true} />
                  </LineShareButton>
                </Grid>
                <Grid item>
                  <TelegramShareButton
                    title={`[MYPRO INDONESIA] ${data?.title}`}
                    url={`${window.location.protocol}//${window.location.hostname}/c/${data?.code}`}
                  >
                    <TelegramIcon size={32} round={true} />
                  </TelegramShareButton>
                </Grid>
                <Grid item>
                  <TwitterShareButton
                    title={`[MYPRO INDONESIA] ${data?.title}`}
                    url={`${window.location.protocol}//${window.location.hostname}/c/${data?.code}`}
                  >
                    <TwitterIcon size={32} round={true} />
                  </TwitterShareButton>
                </Grid>
                <Grid item>
                  <WhatsappShareButton
                    title={`[MYPRO INDONESIA] ${data?.title}`}
                    url={`${window.location.protocol}//${window.location.hostname}/c/${data?.code}`}
                  >
                    <WhatsappIcon size={32} round={true} />
                  </WhatsappShareButton>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box my={5}>
          <Divider />
        </Box>
        <Box my={2}>
          <Typography variant="h6" gutterBottom>
            Penawaran lainnya oleh agen ini
          </Typography>
          {data?.sales?.user_id ? (
            <ListBase
              filter={{
                sales_user_id: data?.sales?.user_id,
                neq__id: id,
              }}
              sort={{ order: "DESC", field: "id" }}
              perPage={6}
              basePath={`/catalog-details/${id}`}
              resource="listings"
            >
              <PapGrid />
              <Pagination rowsPerPageOptions={[6]} />
            </ListBase>
          ) : (
            <Typography color="textSecondary" variant="subtitle2">
              Belum ada penawaran lain dari sales ini.
            </Typography>
          )}
        </Box>
        <Box my={2}>
          <Typography variant="h6" gutterBottom>
            Penawaran lainnya di sekitar properti ini
          </Typography>
          <ListBase
            filter={{
              property_district: data?.property?.district,
              property_province: data?.property?.province,
              property_city: data?.property?.city,
              neq__id: id,
            }}
            sort={{ order: "DESC", field: "id" }}
            perPage={6}
            basePath={`/catalog-details/${id}`}
            resource="listings"
          >
            <PapGrid />
            <Pagination rowsPerPageOptions={[6]} />
          </ListBase>
        </Box>
        <Divider />
        <Box my={1} display="flex" justifyContent="center">
          <Button
            color="secondary"
            onClick={() =>
              history.push("/catalog", {
                internal: location.state?.internal || true,
              })
            }
          >
            Kembali ke katalog
          </Button>
        </Box>
      </Container>
      <Viewer
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        images={photos.map((p, idx) => ({ src: p, alt: `Foto ${idx + 1}` }))}
      />
    </CatalogLayout>
  );
};

export default ListingDetailPage;
