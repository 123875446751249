import MomentUtils from "@date-io/moment";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  makeStyles,
  Paper,
} from "@material-ui/core";
import React, { useCallback, useRef, useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import {
  Create,
  FormTab,
  ImageInput,
  RadioButtonGroupInput,
  SaveButton,
  SimpleForm,
  TabbedForm,
  TextInput,
  Toolbar,
  Button,
  ImageField,
  useCreate,
  useRedirect,
  useNotify,
  SelectInput,
  required,
  email,
  minLength,
  regex,
  useCreateController,
  useCreateContext,
  ReferenceInput,
  AutocompleteInput,
  FormDataConsumer,
} from "react-admin";
import { DateInput } from "react-admin-date-inputs-refactor";
import { useHistory, useLocation } from "react-router-dom";
import { useFormState } from "react-final-form";
import ReactSignatureCanvas from "react-signature-canvas";
import AgentContract from "../AgentContract";
import { NumberInput } from "../CustomInputs";

const useStyles = makeStyles((theme) => ({
  canvas: {
    width: theme.spacing(45),
    height: theme.spacing(22.5),
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
  },
  dialog: {
    maxHeight: `calc(100vh - ${theme.spacing(20)}px)`,
  },
}));

const UserToolbar = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [create, { loading }] = useCreate("users");
  const redirectTo = useRedirect();
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const [agree, setAgree] = useState(false);
  const [hasSigned, setHasSigned] = useState(false);
  const [values, setValues] = useState({});
  const ref = useRef(null);
  const classes = useStyles();
  const { basePath } = props;

  const goTo = (link) => (event) => {
    event.preventDefault();

    history.push(link, {
      internal: location.state?.internal || true,
    });
  };

  const handleSave = useCallback(
    (values, redirect) => {
      if (values.role === "SALES") {
        setValues(values);
        setOpen(true);
        setAgree(false);
      } else {
        create(
          {
            payload: {
              data: { ...values, isRegister: basePath === "/register" },
            },
          },
          {
            onSuccess: ({ data: newRecord }) => {
              console.log("new", redirect, basePath, newRecord.id, newRecord);
              notify(
                basePath === "/register"
                  ? "Sukses, Silahkan cek email anda untuk verifikasi akun!"
                  : "ra.notification.created",
                "info",
                {
                  smart_count: 1,
                }
              );
              redirectTo(
                basePath === "/register" ? "/verify-email" : redirect,
                basePath,
                newRecord.id,
                newRecord
              );
            },
            onFailure: (error) =>
              notify(error.message, "warning", {
                smart_count: 1,
              }),
          }
        );
      }
    },
    [create, notify, redirectTo, basePath]
  );

  const handleAgentSave = () => {
    create(
      {
        payload: {
          data: {
            ...values,
            signature: ref.current.isEmpty() ? null : ref.current.toDataURL(),
          },
        },
      },
      {
        onSuccess: ({ data: newRecord }) => {
          console.log("new agent", basePath, newRecord.id, newRecord);
          notify(
            basePath === "/register"
              ? "Sukses, Silahkan cek email anda untuk verifikasi akun!"
              : "ra.notification.created",
            "info",
            {
              smart_count: 1,
            }
          );
          redirectTo("show", basePath, newRecord.id, newRecord);
        },
      }
    );
  };

  const handleAgree = (event) => setAgree(event.target.checked);

  return (
    <Toolbar {...props}>
      <SaveButton
        label={basePath === "/register" ? "Daftar" : "ra.action.save"}
        // onSuccess={handleSave}
        onSave={handleSave}
      />
      {basePath === "/register" && (
        <Box clone marginLeft={1}>
          <Button
            onClick={goTo("/login")}
            color="inherit"
            label="Batal"
            size="medium"
          />
        </Box>
      )}
      <Dialog
        open={open}
        TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
        fullWidth
        maxWidth="lg"
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle>Perjanjian Marketing</DialogTitle>
        <DialogContent>
          <AgentContract data={values} />
          <Box display="flex" justifyContent="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={agree}
                  onChange={handleAgree}
                  color="primary"
                />
              }
              label="Saya setuju atas syarat-syarat diatas dan bertanggung jawab penuh"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            alignItems="flex-end"
            alignContent="center"
            spacing={2}
          >
            <Grid item xs={4}>
              <Button
                size="large"
                variant="outlined"
                color="secondary"
                onClick={() => setOpen(false)}
                label="Batal"
                fullWidth
                disabled={loading}
              />
            </Grid>
            <Grid item xs={4}>
              <Card>
                <CardHeader title="Tanda tangan pihak KEDUA" />
                <Box clone display="flex" justifyContent="center">
                  <CardContent>
                    <ReactSignatureCanvas
                      ref={ref}
                      onEnd={() =>
                        hasSigned === false ? setHasSigned(true) : null
                      }
                      canvasProps={{
                        className: classes.canvas,
                      }}
                    />
                  </CardContent>
                </Box>
                <CardActions>
                  <Button
                    label="Reset"
                    fullWidth
                    color="default"
                    onClick={() => {
                      ref.current?.clear();
                      setHasSigned(false);
                    }}
                  />
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={handleAgentSave}
                label="Setujui & Simpan"
                fullWidth
                disabled={!(agree && hasSigned) || loading}
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Toolbar>
  );
};

const ValidateType = ({ changeValidator, basePath, ...props }) => {
  const { values } = useFormState();

  useEffect(() => {
    changeValidator(
      basePath !== "register"
        ? values.role === "SALES"
          ? [required()]
          : []
        : [required()]
    );
  }, [basePath, values]);

  return null;
};

const UserCreate = (props) => {
  const [requireValidate, setRequiredValidate] = useState([required()]);

  return (
    <Create {...props}>
      <TabbedForm toolbar={<UserToolbar />}>
        <FormTab label="Identitas Diri">
          {props.basePath !== "/register" && (
            <ValidateType
              changeValidator={(validator) => setRequiredValidate(validator)}
            />
          )}
          {props.basePath !== "/register" && (
            <SelectInput
              choices={[
                { id: "ADMIN", name: "Admin" },
                { id: "SALES", name: "Sales / Agen" },
              ]}
              source="role"
              label="Jenis Pengguna"
              validate={[required()]}
            />
          )}
          {props.basePath !== "/register" && (
            <ImageInput source="avatar" label="Foto Profil" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          )}
          <TextInput
            source="name"
            label="Nama Lengkap (Sesuai KTP)"
            fullWidth
            validate={[required()]}
          />
          <TextInput source="alias" label="Nama Alias" fullWidth />
          <SelectInput
            source="gender"
            label="Jenis Kelamin"
            choices={[
              { id: "male", name: "Laki-laki" },
              { id: "female", name: "Perempuan" },
            ]}
            validate={[required()]}
          />
          <TextInput
            source="email"
            type="email"
            validate={[required(), email()]}
          />
          <TextInput
            source="password"
            type="password"
            validate={[required(), minLength(6)]}
          />
          {props.basePath !== "/register" && (
            <TextInput source="code" label="Kode Karyawan" />
          )}
          {/* <TextInput source="reference.code" type="Kode Ref" /> */}
          <ReferenceInput source="branch" reference="branches" label="Cabang">
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput
            source="reference.code"
            reference="users"
            label="Sales Pembawa"
            filter={{
              active: +true,
              role: "SALES",
              show_all: +true,
            }}
            fullWidth
          >
            <AutocompleteInput
              optionText={(record) => `[${record.code}] ${record.name}`}
            />
          </ReferenceInput>
          {/* {props.basePath !== "/register" && (
            <TextInput source="spv.code" type="Kode SPV" />
          )} */}
          <TextInput
            source="phone"
            label="Telepon/WA"
            validate={[
              ...requireValidate,
              regex(/[0-9]+/, "Harap masukan nomor telpon dengan angka saja"),
            ]}
          />
          <DateInput
            source="birthdate"
            label="Tanggal Lahir"
            options={{
              format: "DD/MM/YYYY",
              inputVariant: "filled",
              margin: "dense",
              validate: [...requireValidate],
            }}
            providerOptions={{ utils: MomentUtils }}
          />
          <TextInput
            source="birthplace"
            label="Tempat Lahir"
            validate={[...requireValidate]}
          />
          <TextInput
            source="address"
            label="Alamat Tinggal Sekarang"
            multiline
            fullWidth
            validate={[...requireValidate]}
          />
          <TextInput
            source="nik"
            label="NIK"
            validate={[
              ...requireValidate,
              regex(/[0-9]+/, "Harap masukan nomor KTP dengan angka saja"),
            ]}
          />
          <TextInput
            source="npwp"
            label="NPWP"
            validate={[...requireValidate]}
          />
          <RadioButtonGroupInput
            source="marital"
            choices={[
              { id: "single", name: "Belum Menikah" },
              { id: "married", name: "Menikah" },
              { id: "divorced", name: "Bercerai" },
            ]}
            label="Status Nikah"
            validate={[...requireValidate]}
          />
          <NumberInput source="children" label="Jumlah Anak" />
          <SelectInput
            source="category.type"
            label="Kategori"
            choices={[
              { id: "fresh", name: "Fresh Property" },
              { id: "senior", name: "Senior Property Consultant" },
            ]}
            validate={[required()]}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.category?.type === "fresh" ? (
                <TextInput
                  source="category.detail"
                  label="Background"
                  fullWidth
                />
              ) : formData.category?.type === "senior" ? (
                <TextInput
                  source="category.detail"
                  label="Ex Agency"
                  fullWidth
                />
              ) : null
            }
          </FormDataConsumer>
          {/* <RadioButtonGroupInput
            source="employment"
            choices={[
              { id: "internal", name: "Hanya di MYPRO" },
              {
                id: "external",
                name: "Bekerja di Tempat Lain",
              },
            ]}
            label="Status Pekerjaan"
            validate={[...requireValidate]}
          /> */}
          <TextInput source="occupation" label="Pekerjaan Lain" fullWidth />
        </FormTab>
        <FormTab label="Rekening">
          <TextInput
            source="bank.name"
            label="Bank"
            validate={[...requireValidate]}
          />
          <TextInput
            source="bank.number"
            label="No."
            validate={[...requireValidate]}
          />
          <TextInput
            source="bank.account"
            label="Atas Nama"
            validate={[...requireValidate]}
          />
          <TextInput
            source="bank.branch"
            label="Cabang"
            validate={[...requireValidate]}
          />
        </FormTab>
        <FormTab label="Media Sosial">
          <TextInput source="facebook" label="Facebook" />
          <TextInput source="instagram" label="Instagram" />
          <TextInput source="tiktok" label="Tiktok" />
        </FormTab>
        <FormTab label="Dokumen Referensi">
          <ImageInput
            source="docs.ktp"
            label="Foto KTP"
            accept="image/*"
            validate={[...requireValidate]}
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <ImageInput
            source="docs.npwp"
            label="Foto NPWP"
            accept="image/*"
            validate={[...requireValidate]}
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <ImageInput
            source="docs.selfie"
            label="Foto Selfie"
            accept="image/*"
            validate={[...requireValidate]}
          >
            <ImageField source="src" title="title" />
          </ImageInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default UserCreate;
