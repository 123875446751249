import MomentUtils from "@date-io/moment";
import React from "react";
import {
  AutocompleteInput,
  Edit,
  RadioButtonGroupInput,
  ReferenceInput,
  required,
  SelectField,
  SimpleForm,
  TextInput,
} from "react-admin";
import { DateInput } from "react-admin-date-inputs-refactor";
import { NumberInput } from "../CustomInputs";

const ReceiptEdit = (props) => (
  <Edit mutationMode="pessimistic" {...props}>
    <SimpleForm>
      <SelectField
        source="type"
        choices={[
          { id: "primary", name: "Primary" },
          { id: "secondary", name: "Secondary" },
          { id: "auction", name: "Lelang" },
          {
            id: "rent",
            name: "Sewa",
          },
        ]}
        label="Jenis Kwitansi"
      />
      <ReferenceInput
        source="contract.id"
        reference="contracts"
        label="Kontrak"
      >
        <AutocompleteInput optionText="code" />
      </ReferenceInput>
      <NumberInput source="nominal" label="Nominal" validate={[required()]} />
      <TextInput source="from" label="Terima Dari" validate={[required()]} />
      <TextInput
        source="description"
        label="Deskripsi"
        multiline
        validate={[required()]}
      />
      <DateInput
        source="date"
        label="Tanggal Buat"
        options={{
          format: "DD/MM/YYYY",
          inputVariant: "filled",
          margin: "dense",
        }}
        providerOptions={{ utils: MomentUtils }}
        validate={[required()]}
      />
    </SimpleForm>
  </Edit>
);

export default ReceiptEdit;
