import * as React from "react";
import {
  Admin,
  EditGuesser,
  ListGuesser,
  Resource,
  RouteWithoutLayout,
  Title,
  useAuthenticated,
  usePermissions,
} from "react-admin";
import jsonServerProvider from "ra-data-json-server";
import {
  createMuiTheme,
  CssBaseline,
  Card,
  CardContent,
  CardHeader,
} from "@material-ui/core";
import UserList from "./users/list";

import indonesianMessages from "ra-language-indonesian-new";
import polyglotI18nProvider from "ra-i18n-polyglot";
import UserCreate from "./users/create";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import UserEdit from "./users/edit";
import { Route } from "react-router-dom";
import RegisterPage from "./users/register";
import PapList from "./pap/list";
import PapCreate from "./pap/create";
import SetDataProvider from "./dataProvider";
import PapEdit from "./pap/edit";
import setAuthProvider from "./authProvider";
import { createBrowserHistory as createHistory } from "history";
import LoginPage from "./loginPage";
import theme from "./theme";
import UserShow from "./users/show";
import VerifyEmailPage from "./verifyEmailPage";
import ForgotPasswordPage from "./forgotPasswordPage";
import ContractList from "./contracts/list";
import PapShow from "./pap/show";
import ContractCreate from "./contracts/create";
import ContractEdit from "./contracts/edit";
import ContractShow from "./contracts/show";
import ListingListPage from "./catalog-new/list";
import ListingDetailPage from "./catalog-new/detail";
import { RefProvider } from "react-context-refs";
import AdminLayout from "./layout";
import ProfilePage from "./users/profile";
import ReceiptList from "./receipts/list";
import ReceiptCreate from "./receipts/create";
import ReceiptEdit from "./receipts/edit";
import ReceiptShow from "./receipts/show";
import { useACL } from "ra-access-control-lists";
import { useEffect, useState } from "react";
import get from "lodash.get";
import { useNewACL } from "./acl";
import ResetPasswordPage from "./resetPasswordPage";
import NotificationShow from "./notifications/show";
import Dashboard from "./dashboard";
import useInterval from "@use-it/interval";
import LogList from "./log/list";
// import { ResourceWithPermission } from "ra-access-control-lists";

const history = createHistory();

const messages = {
  id: indonesianMessages,
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "id");

// const dataProvider = jsonServerProvider("https://jsonplaceholder.typicode.com");
const dataProvider = SetDataProvider("/api");
const authProvider = setAuthProvider("/api");

const ResourceWithPermission = (props) => {
  const { name, list, create, edit, show, options } = props;

  const access = useNewACL(name);

  return (
    <Resource
      {...props}
      name={name}
      list={access.list ? list : undefined}
      create={access.create ? create : undefined}
      edit={access.edit ? edit : undefined}
      show={access.show ? show : undefined}
    />
  );
};

const illustrations = [
  // "apartment",
  // "building",
  // "city",
  // "town",
  // "sale",
  // "house",
  "building1",
  "building2",
  "building3",
];

const BackgroundChanger = () => {
  const [index, setIndex] = useState(
    Math.floor(Math.random() * (illustrations.length - 0))
  );

  useEffect(() => {
    const element = document.querySelector("div.layout");
    if (element) {
      element.style.backgroundImage = `url('/storage/${illustrations[index]}.png')`;
    }
  }, [index]);

  useInterval(() => {
    setIndex(Math.floor(Math.random() * (illustrations.length - 0)));
  }, 1000 * 10);

  return null;
};

const App = () => {
  return (
    <>
      <RefProvider>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <CssBaseline />
          <BackgroundChanger />
          <Admin
            disableTelemetry
            history={history}
            authProvider={authProvider}
            dataProvider={dataProvider}
            theme={theme}
            i18nProvider={i18nProvider}
            customRoutes={[
              <Route key="profile" path="/profile" component={ProfilePage} />,
              // <RouteWithoutLayout
              //   key="register"
              //   path="/register"
              //   component={RegisterPage}
              // />,
              <RouteWithoutLayout
                key="verify-email"
                path="/verify-email"
                component={VerifyEmailPage}
              />,
              <RouteWithoutLayout
                key="forgot-password"
                path="/forgot-password"
                component={ForgotPasswordPage}
              />,
              <RouteWithoutLayout
                key="reset-password"
                path="/reset-password/:token"
                component={ResetPasswordPage}
              />,
              <RouteWithoutLayout
                key="catalog"
                path="/catalog"
                component={ListingListPage}
              />,
              <RouteWithoutLayout
                key="catalog-detail"
                path="/catalog-detail/:id"
                component={ListingDetailPage}
              />,
            ]}
            loginPage={LoginPage}
            layout={AdminLayout}
            dashboard={Dashboard}
          >
            <ResourceWithPermission
              name="listings"
              options={{ label: "PAP" }}
              list={PapList}
              edit={PapEdit}
              show={PapShow}
              create={PapCreate}
            />
            <ResourceWithPermission
              name="contracts"
              options={{ label: "Kontrak" }}
              list={ContractList}
              edit={ContractEdit}
              show={ContractShow}
              create={ContractCreate}
            />
            <ResourceWithPermission
              name="receipts"
              options={{ label: "Kwitansi" }}
              list={ReceiptList}
              edit={ReceiptEdit}
              show={ReceiptShow}
              create={ReceiptCreate}
            />
            <ResourceWithPermission
              name="users"
              options={{ label: "Pengguna" }}
              list={UserList}
              edit={UserEdit}
              show={UserShow}
              create={UserCreate}
            />
            <Resource
              name="notifications"
              options={{ label: "Notifikasi" }}
              show={NotificationShow}
            />
            <Resource name="postal_codes" />
            <ResourceWithPermission
              name="authentication_log"
              options={{ label: "Log Login" }}
              list={LogList}
            />
            <Resource name="branches" />
          </Admin>
        </MuiPickersUtilsProvider>
      </RefProvider>
    </>
  );
};

export default App;
