import axios from "axios";
import { fetchUtils } from "react-admin";
import { ls } from "./app";
import permissions from "./permissions";

// const httpClient = fetchUtils.fetchJson;

const getToken = () => {
  const session = ls.get("session");

  if (session) {
    return `Bearer ${session.token}`;
  } else {
    return "";
  }
};

const httpClient = (url, config) =>
  axios.request(
    config
      ? {
          ...config,
          url,
        }
      : { url }
  );

const setAuthProvider = (apiUrl) => ({
  // authentication
  login: async (params) => {
    try {
      const csrf = await httpClient(`${apiUrl}/../sanctum/csrf-cookie`);
      const { data } = await httpClient(
        // `${apiUrl}/login-with-token`
        `${apiUrl}/../login`,
        {
          method: "POST",
          data: {
            email: params.email,
            password: params.password,
            context: "app",
          },
        }
      );
      // const { data } = await httpClient(`${apiUrl}/tokens/create`, {
      //   method: "POST",
      //   data: {
      //     token_name: "admin_app",
      //   },
      // });
      // ls.set("session", data);

      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  checkError: (error) => {
    // const { status, message } = error;
    // if (status === 401 || status === 403) {
    //   return Promise.reject({ message });
    // }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  checkAuth: async (params) => {
    try {
      await httpClient(`${apiUrl}/is-authenticated`);
      // const session = ls.get("session");
      // return Promise.resolve();

      // if (session) {
      // return Promise.resolve(session);
      // } else {
      //   return Promise.reject();
      // }
    } catch (error) {
      // try {
      // const { data } = await httpClient(`${apiUrl}/tokens/create`, {
      //   method: "POST",
      //   data: {
      //     token_name: "admin_app",
      //   },
      // });

      // return Promise.resolve();
      // } catch (error) {
      return Promise.reject(error);
      // }
    }
  },
  logout: async () => {
    // try {
    await httpClient(
      // `${apiUrl}/logout-with-token`
      `${apiUrl}/../logout`,
      {
        method: "POST",
      }
    );

    // ls.remove("session");

    return Promise.resolve();
    // } catch (error) {
    //   return Promise.reject(error);
    // }
  },
  getIdentity: async (params) => {
    try {
      const { data } = await httpClient(`${apiUrl}/current-user`);

      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPermissions: async (params) => {
    try {
      const { data } = await httpClient(`${apiUrl}/current-user`);

      const rolePermissions = permissions[data.role];

      return Promise.resolve(rolePermissions);
    } catch (error) {
      return Promise.reject(error);
    }
  },
});

export default setAuthProvider;
