import React, { useEffect } from "react";
import { RaBox, RaGrid } from "ra-compact-ui";
import { CompactShowLayout } from "ra-compact-ui";

import {
  AutocompleteInput,
  Show,
  RadioButtonGroupInput,
  ReferenceField,
  SelectField,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TopToolbar,
  Button,
  EditButton,
  ReferenceManyField,
  Datagrid,
  ShowButton,
  NumberField,
  ArrayField,
  useNotify,
  useShowController,
} from "react-admin";
import PrintIcon from "@material-ui/icons/Print";
import MoneyIcon from "@material-ui/icons/Money";
import AddIcon from "@material-ui/icons/Add";
import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useNewACL } from "../acl";
import { DialogProvider, useDialog } from "muibox";
import useAxios from "axios-hooks";

const Actions = ({ permissions, basePath, data, resource }) => {
  const receipt = useNewACL("receipts");
  const dialog = useDialog();
  const notify = useNotify();
  const [{ data: simulation, loading, error }, refetch] = useAxios(
    `/api/commission-simulation/${data?.id}`,
    { manual: true }
  );

  useEffect(() => {
    if (!loading && !error && simulation) {
      dialog.alert({
        title: "Simulasi Komisi",
        message: <Box whiteSpace="pre-wrap">{simulation}</Box>,
      });
    }

    if (!loading && error) {
      notify(error.message, "error");
    }
  }, [loading, error, simulation]);

  const getSimulation = () => {
    try {
      refetch();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <TopToolbar>
      {receipt.create && (
        <Box clone marginRight={1}>
          <Button
            // onClick={goTo("/login")}
            component={Link}
            to={{
              pathname: "/receipts/create",
              state: { record: { contract: { id: data?.id } } },
            }}
            label="Buat Kwitansi"
          >
            <AddIcon />
          </Button>
        </Box>
      )}
      {/* {receipt.create && ( */}
      <Box clone marginRight={1}>
        <Button
          // onClick={goTo("/login")}
          component="a"
          href={`/api/contracts/${data?.id}?print=1`}
          target="_blank"
          download
          label="Cetak Draft"
        >
          <PrintIcon />
        </Button>
      </Box>
      {/* )} */}
      {receipt.create && (
        <Box clone marginRight={1}>
          <Button
            onClick={getSimulation}
            label="Simulasi Komisi"
            disabled={loading}
          >
            <MoneyIcon />
          </Button>
        </Box>
      )}
      {receipt.create && (
        <Box clone marginRight={1}>
          <Button
            // onClick={goTo("/login")}
            component="a"
            href={`/api/commission-final/${data?.id}`}
            target="_blank"
            download
            label="Cetak Excel Komisi"
          >
            <PrintIcon />
          </Button>
        </Box>
      )}
      <EditButton basePath={basePath} record={data} />
      {/* {permissions === "admin" && (
      <DeleteButton basePath={basePath} record={data} resource={resource} />
    )} */}
    </TopToolbar>
  );
};

const ContractShow = (props) => {
  const receipt = useNewACL("receipts");

  const { record } = useShowController(props);

  return (
    <DialogProvider>
      <Show actions={<Actions />} {...props}>
        <CompactShowLayout layoutComponents={[RaBox, RaGrid]}>
          <RaGrid container spacing={1}>
            <RaGrid item xs={12} md={6}>
              <SelectField
                source="type"
                choices={[
                  { id: "sell", name: "SPPJB" },
                  { id: "auction", name: "SPBL" },
                  {
                    id: "rent",
                    name: "Sewa",
                  },
                ]}
                label="Jenis Kontrak"
              />
              <ReferenceField
                source="listing.id"
                reference="listings"
                label="PAP"
              >
                <TextField source="code" />
              </ReferenceField>
              <NumberField
                source="nominal"
                label="Nominal"
                locales="id-ID"
                options={{ style: "currency", currency: "IDR" }}
              />
              <TextField
                source="customer.name"
                label="Nama Pihak Pembeli/Penyewa"
              />
              <TextField
                source="customer.address"
                label="Alamat Pihak Pembeli/Penyewa"
              />
              <TextField
                source="customer.email"
                label="Email Pihak Pembeli/Penyewa"
              />
              <TextField
                source="customer.phone"
                label="Telepon Pihak Pembeli/Penyewa"
              />
              <TextField
                source="customer.nik"
                label="NIK Pihak Pembeli/Penyewa"
              />
              <TextField
                source="customer.npwp"
                label="NPWP Pihak Pembeli/Penyewa"
              />
              <ReferenceManyField
                label="Daftar Kwitansi"
                reference="receipts"
                target="contract_id"
              >
                <Datagrid>
                  <TextField source="code" label="Kode" />
                  <TextField source="type" label="Jenis" />
                  {receipt.show && <ShowButton />}
                </Datagrid>
              </ReferenceManyField>
            </RaGrid>
            <RaGrid xs={12} md={6}>
              {record?.type === "sell" ? (
                <SelectField
                  source="transaction"
                  label="Jenis Transaksi"
                  choices={[
                    { id: "cash", name: "Cash" },
                    { id: "kpr", name: "KPR" },
                    {
                      id: "both",
                      name: "Cash & KPR",
                    },
                    { id: "installment", name: "Pembayaran Bertahap" },
                  ]}
                />
              ) : (
                <></>
              )}
              {record?.type === "sell" || record?.type === "rent" ? (
                <NumberField
                  source="downpayment"
                  label="Nominal UTJ"
                  locales="id-ID"
                  options={{ style: "currency", currency: "IDR" }}
                />
              ) : (
                <></>
              )}
              {record?.type === "sell" ? (
                <NumberField
                  source="settlement"
                  label="Nominal Pelunasan"
                  locales="id-ID"
                  options={{ style: "currency", currency: "IDR" }}
                />
              ) : (
                <></>
              )}
              {record?.type === "sell" ? (
                <TextField source="shm" label="No. SHM" />
              ) : (
                <></>
              )}
              {record?.type === "rent" ? (
                <NumberField
                  source="deposit"
                  label="Nominal Deposit"
                  locales="id-ID"
                  options={{ style: "currency", currency: "IDR" }}
                />
              ) : (
                <></>
              )}
              {record?.type === "rent" ? (
                <NumberField
                  source="fine"
                  label="Denda Keterlambatan"
                  locales="id-ID"
                  options={{ style: "currency", currency: "IDR" }}
                />
              ) : (
                <></>
              )}
              {record?.type === "rent" ? (
                <SelectField
                  source="settlement"
                  label="Jenis Pelunasan"
                  choices={[
                    { id: "full", name: "Pembayaran Langsung Penuh" },
                    { id: "installment", name: "Dibagi Beberapa Termin" },
                  ]}
                />
              ) : (
                <></>
              )}
              <ArrayField
                label="Daftar Potongan / Pengeluaran"
                source="additional.bill"
              >
                <Datagrid>
                  <TextField source="name" label="Jenis" />
                  <NumberField
                    source="nominal"
                    label="Nominal"
                    locales="id-ID"
                    options={{ style: "currency", currency: "IDR" }}
                  />
                </Datagrid>
              </ArrayField>
              <ArrayField label="Daftar Agen tambahan" source="other.marketers">
                <Datagrid>
                  <ReferenceField
                    source="user.id"
                    reference="users"
                    label="Agen"
                  >
                    <TextField source="name" />
                  </ReferenceField>
                </Datagrid>
              </ArrayField>
            </RaGrid>
          </RaGrid>
        </CompactShowLayout>
      </Show>
    </DialogProvider>
  );
};

export default ContractShow;
