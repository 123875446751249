import React, { useEffect, useMemo, useState } from "react";
import Markdown from "markdown-to-jsx";
import { Box, CircularProgress } from "@material-ui/core";
import { useGetOne } from "react-admin";
import moment from "moment";
import axios from "axios";
import { Skeleton } from "@material-ui/lab";

// export const branch = {
//   PUSAT: {
//     name: "STARLA JESSICA ALEK",
//     company: "PT. BINTANG PRIMA PROPERTI (“Perseroan”)",
//     address: "Rukan Sedayu Business Park G5/21, Cengkareng, Jakarta Barat",
//     specimen: "/storage/speciment.jpg",
//   },
//   SERPONG: {
//     name: "LIM WIDYA",
//     company: "PT. MITRA SEKAWAN PROPERTINDO (“Perseroan”)",
//     address:
//       "Ruko Arcadia Grande Blok A No. 6, Gading Serpong, Kelapa Dua, Tangerang Selatan",
//     specimen: "/storage/speciment2.jpg",
//   },
// };

// export const getContent = (data) => `
// Pada hari ini ${moment(data.date || new Date())
//   .toDate()
//   .toLocaleDateString("id-ID", {
//     weekday: "long",
//     year: "numeric",
//     month: "long",
//     day: "numeric",
//   })},  yang bertanda tangan dibawah ini :

// - Nama  : ${branch[data.branch].name}
// - Jabatan : Direktur

// Dalam hal ini bertindak untuk dan atas nama **${
//   branch[data.branch].company
// }**, suatu Perseroan yang didirikan berdasarkan Hukum Indonesia, yang berkedudukan dan beralamat di ${
//   branch[data.branch].address
// }, selanjutnya disebut **PIHAK PERTAMA**.

// - **Nama (Sesuai KTP) : ${data.name}**
// - **Nama Alias : ${data.alias || "-"}**
// - **Jenis Kelamin : ${data.gender || "-"}**
// - **Tempat/Tgl Lahir : ${data.birthplace || "-"} / ${data.birthdate}**
// - **Alamat : ${data.address || "-"}**
// - **No. KTP : ${data.nik || "-"}**
// - **Category : ${
//   data.category === "fresh"
//     ? `Fresh Property, Background ${data.category_detail || "-"}`
//     : data.category === "senior"
//     ? `Senior Consultant Property, Ex Agency ${data.category_detail || "-"}`
//     : "-"
// }**
// - **Referensi Oleh : ${data.reference || "-"}**
// - **No Telepon : ${data.phone || "-"}**
// - **Email : ${data.email || "-"}**
// - **Status : ${data.marital || "-"}**
// - **Anak : ${data.children || "-"}**
// - **Pekerjaan Lain : ${data.occupation || "-"}**

// Dalam hal ini bertindak untuk dan atas namanya sendiri yang selanjutnya disebut **PIHAK KEDUA.**

// Selanjutnya **PIHAK PERTAMA** dan PIHAK KEDUA terlebih dahulu menerangkan hal – hal sebagai berikut :

// a. Bahwa PIHAK PERTAMA adalah perusahaan yang bergerak dibidang properti.

// b. Bahwa PIHAK KEDUA adalah Sales Consultant yang membantu memasarkan setiap aset properti PIHAK PERTAMA.

// Oleh karena itu, berdasarkan hal – hal tersebut diatas, maka PARA PIHAK sepakat untuk menandatangani Perjanjian ini dengan syarat – syarat dan ketentuan – ketentuan sebagai berikut :

// **Pasal 1**

// **Pokok Perjanjian**

// PIHAK PERTAMA dengan ini menunjuk PIHAK KEDUA sebagai salah satu Sales Consultant dalam memasarkan aset/properti milik PIHAK PERTAMA, baik berupa tanah dan atau bangunan dan PIHAK KEDUA bersedia menerima penunjukan sebagai salah satu Sales Consultant dari PIHAK PERTAMA.

// **Pasal 2**

// **Kewajiban PIHAK PERTAMA**

// 1. PIHAK PERTAMA memberikan _training_ (pelatihan) kepada PIHAK KEDUA yang bersifat khusus, mengenai bidang usaha dan jasa lelang yang dijalankan oleh PIHAK PERTAMA.

// 2. PIHAK PERTAMA wajib memberikan komisi kepada PIHAK KEDUA atas setiap penjualan aset/properti Lelang PIHAK PERTAMA yang dilakukan oleh PIHAK KEDUA , sesuai dengan ketentuan yang diatur dalam Pasal 5 Perjanjian ini.

// 3. Seluruh aset properti baik berupa bangunan dan/atau tanah yang dipasarkan oleh PIHAK KEDUA sepenuhnya menjadi tanggung jawab PIHAK PERTAMA.

// ${
//   data.branch === "PUSAT"
//     ? "4. PIHAK PERTAMA Memfasilitasi _Banner (spanduk) & ID Card (kartunama)_ kepada PIHAK KEDUA secara gratis."
//     : ""
// }

// **Pasal 3**

// **Kewajiban PIHAK KEDUA**

// 1. PIHAK KEDUA wajib menjaga nama baik Perusahaan dan PIHAK PERTAMA.

// 2. PIHAK KEDUA berkewajiban untuk berupaya sebaik mungkin untuk memasarkan setiap aset/properti milik PIHAK PERTAMA dan mencapai target penjualan yang telah ditentukan oleh PIHAK PERTAMA.

// 3. PIHAK KEDUA berkewajiban membantu PIHAK PERTAMA untuk melakukan survey dan atau mapping (memetakan) atas setiap properti baik berupa tanah dan atau bangunan yang diperjualbelikan oleh PIHAK PERTAMA.

// 4. PIHAK KEDUA berkewajiban hadir/datang tepat waktu dalam meeting Bulanan dan atau pelatihan yang diadakan oleh PIHAK PERTAMA.

// 5. PIHAK KEDUA dilarang untuk terikat hubungan kerja dan atau sebagai Sales Freelance dengan pihak lain yang menjalankan bidang usaha yang sejenis dengan PIHAK PERTAMA.

// 6. PIHAK KEDUA wajib memberitahukan dan mendapat persetujuan dari PIHAK PERTAMA apabila _upping_ (menaikan) harga terhadap setiap aset properti baik berupa bangunan dan/atau tanah yang diperjualbelikan dan/atau lelang.

// 7. PIHAK KEDUA wajib  menjaga kerahasiaan data, pembagian komisi, dan/atau hal lainnya yang terkait dengan aset  properti baik berupa bangunan dan/atau tanah yang diperjual  belikan dan/atau dilelang oleh PIHAK PERTAMA dari pihak manapun.

// 8. PIHAK PERTAMA siap memasarkan aset PIHAK KEDUA memalui Internet &  Website dengan syarat memberikan Min. 10 Listing Seondary serta menyertakan data selengkap -lengkap nya atau TTD PAP pemilik Aset.

// ${
//   data.branch === "PUSAT"
//     ? "9. Bilamana PIHAK KEDUA _Resign/mengundurkan diri_, maka PIHAK KEDUA wajib membayar banner dan mengembalikan ID Card yang sudah di pesan PIHAK PERTAMA."
//     : ""
// }

// **Pasal 4**

// **Jangka Waktu Perjanjian**

// 1. PIHAK KEDUA wajib memberikan Performance kepada PIHAK PERTAMA dalam waktu 6 (enam) bulan setelah tanda tangan perjanjian ini, Bilamana PIHAK KEDUA tidak ada performance dalam Penjualan atau kehadiran disetiap pertemuan yang diadakan oleh PIHAK PERTAMA maka PIHAK PERTAMA dapat memutuskan sepihak kontrak kerjasama ini.

// **Pasal 5**

// **Komisi**

// 1. PIHAK PERTAMA akan memberikan komisi kepada PIHAK KEDUA dengan perhitungan persentase sebesar 2% (dua persen) dari harga jual aset dan 30% (tiga puluh persen) dari keuntungan bersih PIHAK PERTAMA. Jika berhasil dalam memperantarai tahapan jual Lelang sampai dengan pelunasan pembayaran terhadap aset jual Lelang, dan semua tahapan tersebut telah dinyatakan selesai oleh PIHAK PERTAMA.

// 2. Bila terjadi transaksi Jual beli (AJB SECONDARY) Maka Komisi PIHAK KEDUA akan dipotong sebesar 20% dan apabila PIHAK KEDUA merekrut marketing lain, maka berhak mendapatkan OR sebesar 15% dari potongan kantor untuk leader (PIHAK KEDUA) dengan syarat marketing yang tergabung tidak terikat di kantor agency manapun, Dan Minimal merekrut 5 (Lima) marketing untuk bergabung dan harus closing, dan apabila berhasil menjual aset Lelang AYDA (AJB BANK) maka komisi PIHAK KEDUA akan dipotong sebesar 40% dari komisi yang diterima. Jika berhasil dalam memperantarai tahapan jual sampai dengan pelunasan pembayaran terhadap aset dan semua tahapan tersebut telah dinyatakan selesai oleh PIHAK PERTAMA.

// 3. Bila terjadi transaksi aset PRIMARY Maka PIHAK KEDUA mendapatkan komisi sebesar 60% dan siap untuk mengikuti Product Knowledge serta hadir dalam setiap acara yang diadakan oleh **PIHAK DEVELOPER.**

// 4. Apabila Pembeli membatalkan transaksi secara sepihak, maka _down payment_ (uang muka) yang sudah diberikan akan dibagi PARA PIHAK dengan ketentuan pembagian persentase sebesar 50% (lima puluh persen) untuk masing-masing pihak.

// 5. Pembayaran Komisi PIHAK KEDUA oleh PIHAK PERTAMA akan dilakukan secara transfer ke rekening PIHAK KEDUA sebagai berikut:

//   - **BANK : ${data.bank?.name || "-"}**
//   - **Nomor A/C : ${data.bank?.number || "-"}**
//   - **Atas Nama : ${data.bank?.account || "-"}**
//   - **NO. NPWP : ${data.npwp || "-"}**

// 6. PIHAK PERTAMA berhak untuk tidak memberikan komisi kepada PIHAK KEDUA sebagaimana dimaksud diatas,apabila PIHAK PERTAMA menemukan adanya kecurangan yang dilakukan oleh PIHAK KEDUA dan atau PIHAK KEDUA tidak menangani sepenuhnya semua tahap sampai dengan terjadinya pembayaran.

// **Pasal 6**

// **Penyelesaian Perselisihan**

// 1. PIHAK KEDUA wajib menjaga kerahasiaan data Perusaahaan, apabila terdapat kecurangan baik disengaja atau tidak disengaja maka akan berlanjut pada proses Hukum sebagaimana yang berlaku di Republik Indonesia dengan instansi yang terkait Kepolisian Republik Indonesia.

// 2. Apabila terjadi perselisihan dikemudian hari, maka akan diselesaikan secara musyawarah, apabila tidak tercapai kata mufakat dalam musyawarah, maka akan diselesaikan melalui pengadilan Negeri Jakarta Barat.

// **Pasal 7**

// **Lain – lain**

// Hal – hal lain yang tidak teratur dan belum cukup diatur dalam Perjanjian ini, maka akan ditentukan lebih lanjut yang merupakan bagian tidak terpisahkan dari Perjanjian ini.

// Demikian Perjanjian ini dibuat, dan telah ditanda tangani oleh kedua belah pihak dalam keadaan sehat jasmani dan rohani dan tanpa ada paksaan dari pihak manapun.
//   `;

const spaceGenerator = (num, text = "") =>
  `${text}${[...Array(num - text.length).keys()].map(() => "&nbsp;").join("")}`;

// export const getContent = (data) => {
//   if (data.branch === "PUSAT") {
//     return `
// <h1 style="text-align: center;">PERJANJIAN KERJASAMA AGEN PROPERTI</h1>

// Perjanjian Kerjasama Agen Properti ini (untuk selanjutnya disebut **"Perjanjian"** ) dibuat pada hari ini, ${moment(
//       data.date || new Date()
//     )
//       .toDate()
//       .toLocaleDateString("id-ID", {
//         weekday: "long",
//         year: "numeric",
//         month: "long",
//         day: "numeric",
//       })}, oleh dan antara :

// <div style="font-weight: bold;">${spaceGenerator(15)}${spaceGenerator(
//       10,
//       "Nama"
//     )} : Starla Jessica Alek</div>
// <div style="font-weight: bold;">${spaceGenerator(15)}${spaceGenerator(
//       10,
//       "Jabatan"
//     )} : Direktur</div>

// Dalam hal ini bertindakuntuk dan atas nama **PT. BINTANG PRIMA PROPERTI ("Perusahaan")** atau disebut juga dengan **MYPRO** , suatu Perusahaan yang didirikan berdasarkan Hukum Indonesia, yang berkedudukan dan beralamat di Rukan Sedayu Business Park Blok G5 No.21, Cengkareng, Jakarta Barat, selanjutnya disebut **PIHAK PERTAMA**.

// <div style="font-weight: bold;">${spaceGenerator(
//       15
//     )}Nama (Sesuai KTP)${spaceGenerator(5)}: ${data.name}</div>
// <div style="font-weight: bold;">${spaceGenerator(15)}Nama Alias${spaceGenerator(
//       19
//     )}: ${data.alias || "-"}</div>
// <div style="font-weight: bold;">${spaceGenerator(
//       15
//     )}Jenis Kelamin${spaceGenerator(15)}: ${data.gender || "-"}</div>
// <div style="font-weight: bold;">${spaceGenerator(
//       15
//     )}Tempat/Tgl Lahir${spaceGenerator(8)}: ${data.birthplace || "-"} / ${
//       data.birthdate
//     }</div>
// <div style="font-weight: bold;">${spaceGenerator(15)}Alamat${spaceGenerator(
//       26
//     )}: ${data.address || "-"}</div>
// <div style="font-weight: bold;">${spaceGenerator(15)}No. KTP${spaceGenerator(
//       25
//     )}: ${data.nik || "-"}</div>
// <div style="font-weight: bold;">${spaceGenerator(15)}Kategori${spaceGenerator(
//       24
//     )}: ${
//       data.category === "fresh"
//         ? `Fresh Property, Background ${data.category_detail || "-"}`
//         : data.category === "senior"
//         ? `Senior Consultant Property, Ex Agency ${data.category_detail || "-"}`
//         : "-"
//     }</div>
// <div style="font-weight: bold;">${spaceGenerator(
//       15
//     )}Referensi Oleh${spaceGenerator(12)}: ${data.reference_name || "-"}</div>
// <div style="font-weight: bold;">${spaceGenerator(15)}No Telepon${spaceGenerator(
//       18
//     )}: ${data.phone || "-"}${spaceGenerator(
//       80 - 26 - (data.phone || "-").length
//     )}Keperluan Pajak</div>
// <div style="font-weight: bold;">${spaceGenerator(15)}Email${spaceGenerator(
//       29
//     )}: ${data.email || "-"}${spaceGenerator(
//       80 - 44 - (data.email || "-").length
//     )}Status${spaceGenerator(20)}: ${data.marital || "-"}</div>
// <div style="font-weight: bold;">${spaceGenerator(15)}Lengkapi${spaceGenerator(
//       100 - 8
//     )}Anak${spaceGenerator(23)}: ${data.children || "-"}</div>
// <div style="font-weight: bold;">${spaceGenerator(30)}${
//       data.has?.ktp
//         ? '<img src="/storage/checkmark.png" style="width:12px;height:12px;" />'
//         : '<img src="/storage/cancelmark.png" style="width:12px;height:12px;" />'
//     } KTP${spaceGenerator(25)}${
//       data.has?.npwp
//         ? '<img src="/storage/checkmark.png" style="width:12px;height:12px;" />'
//         : '<img src="/storage/cancelmark.png" style="width:12px;height:12px;" />'
//     } NPWP${spaceGenerator(40)}Pekerjaan Lain${spaceGenerator(5)}: ${
//       data.occupation || "-"
//     }</div>

// Dalam hal ini bertindak untuk dan atas namanya sendiri yang selanjutnya disebut **PIHAK KEDUA.**

// **PIHAK PERTAMA** dan **PIHAK KEDUA** atau secara bersama-sama disebut sebagai **PARA PIHAK**. Dengan ini **PARA PIHAK** sepakat dan setuju untuk saling mengikatkan diri dalam suatu Perjanjian Kerjasama Agen Propertidengan syarat-syarat dan ketentuan-ketentuan sebagai berikut :

// <h3 style="text-align:center;">Pasal 1<div>DEFINISI</div></h3>

// Di dalam Perjanjian ini yang dimaksud dengan :

// 1. Properti adalah hak eksklusif terhadap kepemilikan seseorang atau sekelompok orang atas sebidang tanah dan/atau bangunan yang berdiri di atasnya dan dilengkapi dengan dokumen resmi (legal).
// 2. MYPRO adalah perusahan properti yang bernaung di bawah PT. Bintang Prima Property yang kegiatan usahanya adalah pemasaran properti.
// 3. Pemasaran properti atau disebut juga pemasaran adalah proses penjualan atau penyewaan properti termasuk aset Lelang yang dilakukan oleh seorang agen properti yang terikat hubungan kerjasama dengan **PIHAK PERTAMA.**
// 4. Listing Properti adalah perjanjian surat kuasa jual/sewa atau surat perintah untuk menjual/menyewakan properti dari pemilik properti kepada agen properti melalui website **PIHAK PERTAMA** atau media-media pemasaran lainnya.

// <br />
// <h3 style="text-align:center;">Pasal 2<div>STATUS KEAGENAN</div></h3>

// 1. Keberadaan **PIHAK KEDUA** di dalam perjanjian ini adalah sebagai "Agen Lepas" dan tidak terikat dengan Perjanjian Kerja Waktu Tertentu (PKWT) dan Perjanjian Kerja Waktu Tidak Tertentu (PKWTT) sebagaimana dimaksud di dalam Undang-Undang Nomor 13 Tahun 2003 tentang Ketenagakerjaan.
// 2. Sebelum menandatangani Perjanjian ini, **PIHAK KEDUA** terlebih dahulu harus mendaftarkan diri pada **PIHAK PERTAMA** dengan melengkapi persyaratan-persyaratan yang ditentukan oleh **PIHAK PERTAMA.**
// 3. Selama terikat dalam Perjanjian ini, **PIHAK KEDUA** harus patuh dan tunduk terhadap kode etik dan peraturan-peraturan yang ditetapkan oleh Perusahaan.
// 4. **PIHAK KEDUA** dilarang terikat hubungan kerja dengan pihak lain yang menjalankan bidang usaha yang sama dengan **PIHAK PERTAMA.**

// <h3 style="text-align:center;">Pasal 3<div>RUANG LINGKUP KERJASAMA</div></h3>

// Ruang lingkup kerjasama antara **PIHAK PERTAMA** dan **PIHAK KEDUA** di dalam Perjanjian ini adalah sebagai berikut :

// 1. Memasarkan baik untuk dijual atau disewakan seluruh listing properti yang terdaftar pada **PIHAK PERTAMA**.
// 2. Mendaftarkan properti yang akan dijual atau disewakan untuk dimuat ke dalam listing properti **PIHAK PERTAMA** dengan melengkapi seluruh dokumen yang berkaitan dengan properti tersebut termasuk membuat perjanjian dengan pemilik properti tentang komisi atas hasil penjualan/persewaan properti tersebut yang selanjutnya disebut Perjanjian Agen Pemasaran (PAP).
// 3. Mendampingi calon pembeli/penyewa dan memberikan keterangan yang jelas dan lengkap mengenai kondisi dan keadaan properti yang akan dijual atau disewakan kepada calon pembeli/penyewa.

// <h3 style="text-align:center;">Pasal 4<div>JANGKA WAKTU DAN BERAKHIRNYA PERJANJIAN</div></h3>

// 1. Jangka waktu Perjanjian ini adalah selama 1 (satu) tahun terhitung sejak ditandatanganinya Perjanjian ini dan akan diperpanjang secara otomatis apabila **PIHAK KEDUA** melaksanakan kegiatan pemasaran properti.
// 2. Sebelum berakhirnya jangka waktu Perjanjian ini, **PIHAK PERTAMA** dapat mengakhiri Perjanjian ini meskipun tanpa adanya persetujuan terlebih dahulu dari **PIHAK KEDUA** dalam hal :

// 1. **PIHAK KEDUA** melakukan pelanggaran terhadap etika profesi pemasaran sebagaimana ditentukan di dalam peraturan perusahaan.
// 2. **PIHAK KEDUA** melakukan pelanggaran terhadap isi dari perjanjian ini.
// 3. **PIHAK KEDUA** dihukum penjara karena melakukan tindak pidana berdasarkan putusan pengadilan yang telah berkekuatan hukum tetap.
// 4. **PIHAK KEDUA** tidak aktif dalam melakukan kegiatan pemasaran properti selama 12 (dua belas) bulan berturut-turut.
// 5. **PIHAK KEDUA** mengundurkan diri atau meninggal dunia.

// 1. Terhadap pengakhiran Perjanjian sebagaimana dimaksud pada ayat (1) dan (2) di atas, **PIHAK KEDUA** tidak melakukan penuntutan hak-hak normatif berdasarkan ketentuan yang diatur di dalam Undang-Undang Nomor 13 Tahun 2003 tentang ketenagakerjaan.
// 2. Dengan berakhirnya Perjanjian ini maka **PARA PIHAK** sepakat untuk menyelesaikan segala tunggakan yang menjadi kewajiban masing-masing, kecuali hak atas komisi dari hasil penjualan properti yang masih dalam proses negosiasi antara pemilik properti dan calon pembeli/penyewa yang dipasarkan oleh **PIHAK KEDUA**.

// <h3 style="text-align:center;">Pasal 5<div>HAK DAN KEWAJIBAN</div></h3>

// 1. Hak dan Kewajiban **PIHAK PERTAMA :**

// 1. Berhak atas informasi yang jelas dan lengkap mengenai properti yang didaftarkan oleh **PIHAK KEDUA** untuk dimuat di dalam listing properti **PIHAK PERTAMA.** Data-data dan informasi yang diperoleh dari **PIHAK KEDUA** akan dijamin kerahasiannya.
// 2. Berhak atas Perjanjian Agen Pemasaran (PAP) yang dibuat dan ditandatangi oleh **PIHAK KEDUA** dengan pemilik properti.
// 3. Berhak menerima laporan tentang perkembangan dan kemajuan pemasaran properti yang dilakukan oleh **PIHAK KEDUA.**
// 4. Berhak atas komisi dari hasil penjualan/persewaan properti Primary, Secondary dan penjualan aset Lelang yang dilakukan oleh **PIHAK KEDUA**.
// 5. Berhak atas seluruh dokumen yang berkaitan dengan seluruh properti yang dimuat di dalam listing properti **PIHAK PERTAMA** untuk disimpan dengan baik dan aman termasuk perjanjian yang ditandatangani oleh pihak pemilik properti dan pembeli/penyewa (Surat Perjanjian Pengikatan Jual Beli (SPPJB) atau Perjanjian Sewa Menyewa (PSM)) dan dokumen terkait lainnya.
// 6. Berkewajiban memuat properti yang didaftarkan oleh **PIHAK KEDUA** di dalam listing properti **PIHAK PERTAMA** sepanjang properti tersebut dilengkapi dengan dokumen serta informasi yang jelas dan dapat dipertanggungjawabkan.
// 7. Berkewajiban memberikan fasilitas dan dukungan kepada **PIHAK KEDUA** dalam menjalankan pemasaran, diantaranya bantuan administrasi (_administrative support)_, fasilitas ruang pertemuan (_meeting rooms_), pelatihan-pelatihan (_training_, _technical and soft skills),_ spanduk (_banner_), konsultasi, dan lainnya sesuai dengan kebijakan perusahaan.

// 2. Hak dan Kewajiban **PIHAK KEDUA :**

// 1. Berhak atas komisi dari hasil penjualan/persewaan properti termasuk aset Lelang yang dilakukan oleh **PIHAK KEDUA.**
// 2. Berhak atas program reward/insentif/promosi/lainnya yang disediakan oleh **PIHAK PERTAMA** untuk mendorong produktivitas **PIHAK KEDUA** sesuai dengan ketentuan dan kebijakan perusahaan.
// 3. Berhak mendapatkan fasilitas dan dukungan yang disediakan oleh **PIHAK PERTAMA** untuk menjalankan pemasaran, diantaranya bantuan administrasi (_administrative support)_, fasilitas ruang pertemuan (_meeting rooms_), pelatihan-pelatihan (_training_, _technical and soft skills),_ spanduk (_banner_), konsultasi, dan lainnya sesuai dengan kebijakan perusahaan.
// 4. Berkewajiban menjaga nama baik **PIHAK PERTAMA.**
// 5. Berkewajiban mematuhi etika profesi dan peraturan perusahaan yang telah ditetapkan oleh **PIHAK PERTAMA.**
// 6. Berkewajiban menjalankan transaksi pemasaran properti melalui sistem yang ditentukan oleh **PIHAK PERTAMA.**
// 7. Berkewajiban dan bertanggungjawab atas keabsahan, kelengkapan dan akurasi data listing dan mendapatkan persetujuan dari pemilik properti kepada **PIHAK KEDUA** untuk memasarkan properti.

// 1. Berkewajiban memberikan laporan tentang perkembangan dan kemajuan pemasaran properti yang dilakukan oleh **PIHAK KEDUA** kepada **PIHAK PERTAMA** termasuk Surat Perjanjian Pengikatan Jual Beli (SPPJB) atau Perjanjian Sewa Menyewa (PSM) yang telah ditandatangani oleh pemilik properti dan pembeli/penyewa.
// 2. Berkewajiban hadir dalam meeting bulanan dan setiap pelatihan-pelatihan yang diadakan oleh **PIHAK PERTAMA** dan/atau Leader.
// 3. Berkewajiban menjaga kerahasiaan data yang berkaitan dengan properti yang dipasarkan dan/atau di lelang oleh **PIHAK PERTAMA** dari pihak manapun.

// <h3 style="text-align:center;">Pasal 6<div>TRANSAKSI DAN KOMISI PENJUALAN</div></h3>

// 1. Guna menghitung komisi yang seharusnya diterima oleh **PIHAK PERTAMA** dan **PIHAK KEDUA** , maka seluruh transaksi penjualan/penyewaan properti wajib dilakukan melalui rekening Bank **PIHAK PERTAMA** , kecuali telah mendapatkan persetujuan dari **PIHAK PERTAMA** untuk di transfer ke rekening lain.

// 1. **PIHAK PERTAMA** dan **PIHAK KEDUA** berhak atas komisi dari hasil penjualan/persewaan properti Primary dan Secondary dari pemilik properti serta hasil penjualan aset Lelang oleh **PIHAK KEDUA**.
// 2. Pembagian atau komposisi komisi atas hasil penjualan/persewaan/lelang properti antara **PIHAK PERTAMA** dan **PIHAK KEDUA** dihitung secara proporsional dan dituangkan dalam kesepakatan tersendiri sesuai dengan kebijakan perusahaan.
// 3. Pembagian dari komisi untuk provisi bank atas transaksi Jual-Beli melalui KPR Bank antara **PIHAK PERTAMA** dan **PIHAK KEDUA** dihitung secara proporsional dan dituangkan dalam kesepakatan tersendiri sesuai dengan kebijakan perusahaan.
// 4. Pembayaran komisi kepada **PIHAK KEDUA** dilakukan oleh **PIHAK PERTAMA** selambat-lambatnya 7 (tujuh) hari kerja terhitung sejak komisi diterima oleh **PIHAK PERTAMA.**

// <h3 style="text-align:center;">Pasal 7<div>FORCE MAJEURE</div></h3>

// 1. Terhadap pembatalan Perjanjian akibat Force Majeure, **PIHAK PERTAMA** dan **PIHAK KEDUA** sepakat untuk menanggung kerugian masing-masing.
// 2. Force Majeure yang dimaksud dalam Perjanjian ini adalah suatu keadaan memaksa di luar batas kemampuan kedua belah pihak yang dapat mengganggu bahkan menggagalkan terlaksananya Perjanjian ini, seperti bencana alam, epidemik, peperangan, pemogokan, sabotase, pemberontakan, huru-hara, kebijakan pemerintah di bidang moneter, kecelakaan atau keterlambatan yang disebabkan oleh keadaan di luar kemampuan manusia.

// <h3 style="text-align:center;">Pasal 8<div>PENYELESAIAN SENGKETA</div></h3>

// 1. **PARA PIHAK** sepakat untuk menyelesaikan setiap perselisihanyang terjadi terkait dengan Perjanjian ini secara musyawarah untuk mufakat.
// 2. Apabila musyawarah untuk mufakat tidak tercapai, **PARA PIHAK** sepakat untuk menyelesaikan secara hukum yang berlaku di Republik Indonesia dan memilih domisili di Pengadilan Negeri Jakarta Barat.

// <h3 style="text-align:center;">Pasal 9<div>PENUTUP</div></h3>

// 1. Perjanjian ini merupakan kesepakatan yang mengikat antara **PIHAK PERTAMA** dengan **PIHAK KEDUA** dan diatur menurut hukum Indonesia.
// 2. Perjanjian ini dibuat dalam rangkap 2(dua), mempunyai kekuatan hukum yang sama setelah ditandatangani oleh **PIHAK PERTAMA** dan **PIHAK KEDUA.**
// 3. Hal-hal yang belum diatur di dalam Perjanjian ini, akan diatur lebih lanjut di dalam Perjanjian tambahan (_addendum_) dan merupakan bagian yang tidak terpisahkan dari Perjanjian ini.

// Demikian Perjanjian Kerjasama Agen Properti ini dibuat dan ditandatangani dengan sebenar-benarnya dalam keadaan sehat jasmani dan rohani serta tanpa adanya pengaruh atau paksaan dari pihak manapun.`;
//   } else if (data.branch === "SERPONG") {
//     return `
// <h1 style="text-align: center;">PERJANJIAN KERJASAMA AGEN PROPERTI</h1>

// Perjanjian Kerjasama Agen Properti ini (untuk selanjutnya disebut **"Perjanjian"** ) dibuat pada hari ini, ${moment(
//       data.date || new Date()
//     )
//       .toDate()
//       .toLocaleDateString("id-ID", {
//         weekday: "long",
//         year: "numeric",
//         month: "long",
//         day: "numeric",
//       })}, oleh dan antara :

// <div style="font-weight: bold;">${spaceGenerator(15)}${spaceGenerator(
//       10,
//       "Nama"
//     )} : Lim Widya</div>
// <div style="font-weight: bold;">${spaceGenerator(15)}${spaceGenerator(
//       10,
//       "Jabatan"
//     )} : Direktur</div>

// Dalam hal ini bertindakuntuk dan atas nama **PT. MITRA SEKAWAN PROPERTINDO ("Perusahaan")** atau disebut juga dengan **MYPRO SERPONG** , suatu Perusahaan yang didirikan berdasarkan Hukum Indonesia, yang berkedudukan dan beralamat di Ruko Arcadia Grande, Blok A No.6, Gading Serpong, Kelapa Dua, Tangerang, selanjutnya disebut **PIHAK PERTAMA**.

// <div style="font-weight: bold;">${spaceGenerator(
//       15
//     )}Nama (Sesuai KTP)${spaceGenerator(5)}: ${data.name}</div>
// <div style="font-weight: bold;">${spaceGenerator(15)}Nama Alias${spaceGenerator(
//       19
//     )}: ${data.alias || "-"}</div>
// <div style="font-weight: bold;">${spaceGenerator(
//       15
//     )}Jenis Kelamin${spaceGenerator(15)}: ${data.gender || "-"}</div>
// <div style="font-weight: bold;">${spaceGenerator(
//       15
//     )}Tempat/Tgl Lahir${spaceGenerator(8)}: ${data.birthplace || "-"} / ${
//       data.birthdate
//     }</div>
// <div style="font-weight: bold;">${spaceGenerator(15)}Alamat${spaceGenerator(
//       26
//     )}: ${data.address || "-"}</div>
// <div style="font-weight: bold;">${spaceGenerator(15)}No. KTP${spaceGenerator(
//       25
//     )}: ${data.nik || "-"}</div>
// <div style="font-weight: bold;">${spaceGenerator(15)}Kategori${spaceGenerator(
//       24
//     )}: ${
//       data.category === "fresh"
//         ? `Fresh Property, Background ${data.category_detail || "-"}`
//         : data.category === "senior"
//         ? `Senior Consultant Property, Ex Agency ${data.category_detail || "-"}`
//         : "-"
//     }</div>
// <div style="font-weight: bold;">${spaceGenerator(
//       15
//     )}Referensi Oleh${spaceGenerator(12)}: ${data.reference_name || "-"}</div>
// <div style="font-weight: bold;">${spaceGenerator(15)}No Telepon${spaceGenerator(
//       18
//     )}: ${data.phone || "-"}${spaceGenerator(
//       80 - 26 - (data.phone || "-").length
//     )}Keperluan Pajak</div>
// <div style="font-weight: bold;">${spaceGenerator(15)}Email${spaceGenerator(
//       29
//     )}: ${data.email || "-"}${spaceGenerator(
//       80 - 44 - (data.email || "-").length
//     )}Status${spaceGenerator(20)}: ${data.marital || "-"}</div>
// <div style="font-weight: bold;">${spaceGenerator(15)}Lengkapi${spaceGenerator(
//       100 - 8
//     )}Anak${spaceGenerator(23)}: ${data.children || "-"}</div>
// <div style="font-weight: bold;">${spaceGenerator(30)}${
//       data.has?.ktp
//         ? '<img src="/storage/checkmark.png" style="width:12px;height:12px;" />'
//         : '<img src="/storage/cancelmark.png" style="width:12px;height:12px;" />'
//     } KTP${spaceGenerator(25)}${
//       data.has?.npwp
//         ? '<img src="/storage/checkmark.png" style="width:12px;height:12px;" />'
//         : '<img src="/storage/cancelmark.png" style="width:12px;height:12px;" />'
//     } NPWP${spaceGenerator(40)}Pekerjaan Lain${spaceGenerator(5)}: ${
//       data.occupation || "-"
//     }</div>

// Dalam hal ini bertindak untuk dan atas namanya sendiri yang selanjutnya disebut **PIHAK KEDUA.**

// **PIHAK PERTAMA** dan **PIHAK KEDUA** atau secara bersama-sama disebut sebagai **PARA PIHAK**. Dengan ini **PARA PIHAK** sepakat dan setuju untuk saling mengikatkan diri dalam suatu Perjanjian Kerjasama Agen Propertidengan syarat-syarat dan ketentuan-ketentuan sebagai berikut :

// <h3 style="text-align:center;">Pasal 1<div>DEFINISI</div></h3>

// Di dalam Perjanjian ini yang dimaksud dengan :

// 1. Properti adalah hak eksklusif terhadap kepemilikan seseorang atau sekelompok orang atas sebidang tanah dan/atau bangunan yang berdiri di atasnya dan dilengkapi dengan dokumen resmi (legal).
// 2. MYPRO SERPONG adalah perusahan properti yang bernaung di bawah PT. Mitra Sekawan Propertindo yang kegiatan usahanya adalah pemasaran properti.
// 3. Pemasaran properti atau disebut juga pemasaran adalah proses penjualan atau penyewaan properti termasuk aset Lelang yang dilakukan oleh seorang agen properti yang terikat hubungan kerjasama dengan **PIHAK PERTAMA.**
// 4. Listing Properti adalah perjanjian surat kuasa jual/sewa atau surat perintah untuk menjual/menyewakan properti dari pemilik properti kepada agen properti melalui website **PIHAK PERTAMA** atau media-media pemasaran lainnya.

// <br />
// <h3 style="text-align:center;">Pasal 2<div>STATUS KEAGENAN</div></h3>

// 1. Keberadaan **PIHAK KEDUA** di dalam perjanjian ini adalah sebagai "Agen Lepas" dan tidak terikat dengan Perjanjian Kerja Waktu Tertentu (PKWT) dan Perjanjian Kerja Waktu Tidak Tertentu (PKWTT) sebagaimana dimaksud di dalam Undang-Undang Nomor 13 Tahun 2003 tentang Ketenagakerjaan.
// 2. Sebelum menandatangani Perjanjian ini, **PIHAK KEDUA** terlebih dahulu harus mendaftarkan diri pada **PIHAK PERTAMA** dengan melengkapi persyaratan-persyaratan yang ditentukan oleh **PIHAK PERTAMA.**
// 3. Selama terikat dalam Perjanjian ini, **PIHAK KEDUA** harus patuh dan tunduk terhadap kode etik dan peraturan-peraturan yang ditetapkan oleh Perusahaan.
// 4. **PIHAK KEDUA** dilarang terikat hubungan kerja dengan pihak lain yang menjalankan bidang usaha yang sama dengan **PIHAK PERTAMA.**

// <h3 style="text-align:center;">Pasal 3<div>RUANG LINGKUP KERJASAMA</div></h3>

// Ruang lingkup kerjasama antara **PIHAK PERTAMA** dan **PIHAK KEDUA** di dalam Perjanjian ini adalah sebagai berikut :

// 1. Memasarkan baik untuk dijual atau disewakan seluruh listing properti yang terdaftar pada **PIHAK PERTAMA**.
// 2. Mendaftarkan properti yang akan dijual atau disewakan untuk dimuat ke dalam listing properti **PIHAK PERTAMA** dengan melengkapi seluruh dokumen yang berkaitan dengan properti tersebut termasuk membuat perjanjian dengan pemilik properti tentang komisi atas hasil penjualan/persewaan properti tersebut yang selanjutnya disebut Perjanjian Agen Pemasaran (PAP).
// 3. Mendampingi calon pembeli/penyewa dan memberikan keterangan yang jelas dan lengkap mengenai kondisi dan keadaan properti yang akan dijual atau disewakan kepada calon pembeli/penyewa.

// <h3 style="text-align:center;">Pasal 4<div>JANGKA WAKTU DAN BERAKHIRNYA PERJANJIAN</div></h3>

// 1. Jangka waktu Perjanjian ini adalah selama 1 (satu) tahun terhitung sejak ditandatanganinya Perjanjian ini dan akan diperpanjang secara otomatis apabila **PIHAK KEDUA** melaksanakan kegiatan pemasaran properti.
// 2. Sebelum berakhirnya jangka waktu Perjanjian ini, **PIHAK PERTAMA** dapat mengakhiri Perjanjian ini meskipun tanpa adanya persetujuan terlebih dahulu dari **PIHAK KEDUA** dalam hal :

// 1. **PIHAK KEDUA** melakukan pelanggaran terhadap etika profesi pemasaran sebagaimana ditentukan di dalam peraturan perusahaan.
// 2. **PIHAK KEDUA** melakukan pelanggaran terhadap isi dari perjanjian ini.
// 3. **PIHAK KEDUA** dihukum penjara karena melakukan tindak pidana berdasarkan putusan pengadilan yang telah berkekuatan hukum tetap.
// 4. **PIHAK KEDUA** tidak aktif dalam melakukan kegiatan pemasaran properti selama 12 (dua belas) bulan berturut-turut.
// 5. **PIHAK KEDUA** mengundurkan diri atau meninggal dunia.

// 1. Terhadap pengakhiran Perjanjian sebagaimana dimaksud pada ayat (1) dan (2) di atas, **PIHAK KEDUA** tidak melakukan penuntutan hak-hak normatif berdasarkan ketentuan yang diatur di dalam Undang-Undang Nomor 13 Tahun 2003 tentang ketenagakerjaan.
// 2. Dengan berakhirnya Perjanjian ini maka **PARA PIHAK** sepakat untuk menyelesaikan segala tunggakan yang menjadi kewajiban masing-masing, kecuali hak atas komisi dari hasil penjualan properti yang masih dalam proses negosiasi antara pemilik properti dan calon pembeli/penyewa yang dipasarkan oleh **PIHAK KEDUA**.

// <h3 style="text-align:center;">Pasal 5<div>HAK DAN KEWAJIBAN</div></h3>

// 1. Hak dan Kewajiban **PIHAK PERTAMA :**

// 1. Berhak atas informasi yang jelas dan lengkap mengenai properti yang didaftarkan oleh **PIHAK KEDUA** untuk dimuat di dalam listing properti **PIHAK PERTAMA.** Data-data dan informasi yang diperoleh dari **PIHAK KEDUA** akan dijamin kerahasiannya.
// 2. Berhak atas Perjanjian Agen Pemasaran (PAP) yang dibuat dan ditandatangi oleh **PIHAK KEDUA** dengan pemilik properti.
// 3. Berhak menerima laporan tentang perkembangan dan kemajuan pemasaran properti yang dilakukan oleh **PIHAK KEDUA.**
// 4. Berhak atas komisi dari hasil penjualan/persewaan properti Primary, Secondary dan penjualan aset Lelang yang dilakukan oleh **PIHAK KEDUA**.
// 5. Berhak atas seluruh dokumen yang berkaitan dengan seluruh properti yang dimuat di dalam listing properti **PIHAK PERTAMA** untuk disimpan dengan baik dan aman termasuk perjanjian yang ditandatangani oleh pihak pemilik properti dan pembeli/penyewa (Surat Perjanjian Pengikatan Jual Beli (SPPJB) atau Perjanjian Sewa Menyewa (PSM)) dan dokumen terkait lainnya.
// 6. Berkewajiban memuat properti yang didaftarkan oleh **PIHAK KEDUA** di dalam listing properti **PIHAK PERTAMA** sepanjang properti tersebut dilengkapi dengan dokumen serta informasi yang jelas dan dapat dipertanggungjawabkan.
// 7. Berkewajiban memberikan fasilitas dan dukungan kepada **PIHAK KEDUA** dalam menjalankan pemasaran, diantaranya bantuan administrasi (_administrative support)_, fasilitas ruang pertemuan (_meeting rooms_), pelatihan-pelatihan (_training_, _technical and soft skills),_ spanduk (_banner_), konsultasi, dan lainnya sesuai dengan kebijakan perusahaan.

// 2. Hak dan Kewajiban **PIHAK KEDUA :**

// 1. Berhak atas komisi dari hasil penjualan/persewaan properti termasuk aset Lelang yang dilakukan oleh **PIHAK KEDUA.**
// 2. Berhak atas program reward/insentif/promosi/lainnya yang disediakan oleh **PIHAK PERTAMA** untuk mendorong produktivitas **PIHAK KEDUA** sesuai dengan ketentuan dan kebijakan perusahaan.
// 3. Berhak mendapatkan fasilitas dan dukungan yang disediakan oleh **PIHAK PERTAMA** untuk menjalankan pemasaran, diantaranya bantuan administrasi (_administrative support)_, fasilitas ruang pertemuan (_meeting rooms_), pelatihan-pelatihan (_training_, _technical and soft skills),_ spanduk (_banner_), konsultasi, dan lainnya sesuai dengan kebijakan perusahaan.
// 4. Berkewajiban menjaga nama baik **PIHAK PERTAMA.**
// 5. Berkewajiban mematuhi etika profesi dan peraturan perusahaan yang telah ditetapkan oleh **PIHAK PERTAMA.**
// 6. Berkewajiban menjalankan transaksi pemasaran properti melalui sistem yang ditentukan oleh **PIHAK PERTAMA.**
// 7. Berkewajiban dan bertanggungjawab atas keabsahan, kelengkapan dan akurasi data listing dan mendapatkan persetujuan dari pemilik properti kepada **PIHAK KEDUA** untuk memasarkan properti.

// 1. Berkewajiban memberikan laporan tentang perkembangan dan kemajuan pemasaran properti yang dilakukan oleh **PIHAK KEDUA** kepada **PIHAK PERTAMA** termasuk Surat Perjanjian Pengikatan Jual Beli (SPPJB) atau Perjanjian Sewa Menyewa (PSM) yang telah ditandatangani oleh pemilik properti dan pembeli/penyewa.
// 2. Berkewajiban hadir dalam meeting bulanan dan setiap pelatihan-pelatihan yang diadakan oleh **PIHAK PERTAMA** dan/atau Leader.
// 3. Berkewajiban menjaga kerahasiaan data yang berkaitan dengan properti yang dipasarkan dan/atau di lelang oleh **PIHAK PERTAMA** dari pihak manapun.

// <h3 style="text-align:center;">Pasal 6<div>TRANSAKSI DAN KOMISI PENJUALAN</div></h3>

// 1. Guna menghitung komisi yang seharusnya diterima oleh **PIHAK PERTAMA** dan **PIHAK KEDUA** , maka seluruh transaksi penjualan/penyewaan properti wajib dilakukan melalui rekening Bank **PIHAK PERTAMA** , kecuali telah mendapatkan persetujuan dari **PIHAK PERTAMA** untuk di transfer ke rekening lain.

// 1. **PIHAK PERTAMA** dan **PIHAK KEDUA** berhak atas komisi dari hasil penjualan/persewaan properti Primary dan Secondary dari pemilik properti serta hasil penjualan aset Lelang oleh **PIHAK KEDUA**.
// 2. Pembagian atau komposisi komisi atas hasil penjualan/persewaan/lelang properti antara **PIHAK PERTAMA** dan **PIHAK KEDUA** dihitung secara proporsional dan dituangkan dalam kesepakatan tersendiri sesuai dengan kebijakan perusahaan.
// 3. Pembagian dari komisi untuk provisi bank atas transaksi Jual-Beli melalui KPR Bank antara **PIHAK PERTAMA** dan **PIHAK KEDUA** dihitung secara proporsional dan dituangkan dalam kesepakatan tersendiri sesuai dengan kebijakan perusahaan.
// 4. Pembayaran komisi kepada **PIHAK KEDUA** dilakukan oleh **PIHAK PERTAMA** selambat-lambatnya 7 (tujuh) hari kerja terhitung sejak komisi diterima oleh **PIHAK PERTAMA.**

// <h3 style="text-align:center;">Pasal 7<div>FORCE MAJEURE</div></h3>

// 1. Terhadap pembatalan Perjanjian akibat Force Majeure, **PIHAK PERTAMA** dan **PIHAK KEDUA** sepakat untuk menanggung kerugian masing-masing.
// 2. Force Majeure yang dimaksud dalam Perjanjian ini adalah suatu keadaan memaksa di luar batas kemampuan kedua belah pihak yang dapat mengganggu bahkan menggagalkan terlaksananya Perjanjian ini, seperti bencana alam, epidemik, peperangan, pemogokan, sabotase, pemberontakan, huru-hara, kebijakan pemerintah di bidang moneter, kecelakaan atau keterlambatan yang disebabkan oleh keadaan di luar kemampuan manusia.

// <h3 style="text-align:center;">Pasal 8<div>PENYELESAIAN SENGKETA</div></h3>

// 1. **PARA PIHAK** sepakat untuk menyelesaikan setiap perselisihanyang terjadi terkait dengan Perjanjian ini secara musyawarah untuk mufakat.
// 2. Apabila musyawarah untuk mufakat tidak tercapai, **PARA PIHAK** sepakat untuk menyelesaikan secara hukum yang berlaku di Republik Indonesia dan memilih domisili di Pengadilan Negeri Jakarta Barat.

// <h3 style="text-align:center;">Pasal 9<div>PENUTUP</div></h3>

// 1. Perjanjian ini merupakan kesepakatan yang mengikat antara **PIHAK PERTAMA** dengan **PIHAK KEDUA** dan diatur menurut hukum Indonesia.
// 2. Perjanjian ini dibuat dalam rangkap 2(dua), mempunyai kekuatan hukum yang sama setelah ditandatangani oleh **PIHAK PERTAMA** dan **PIHAK KEDUA.**
// 3. Hal-hal yang belum diatur di dalam Perjanjian ini, akan diatur lebih lanjut di dalam Perjanjian tambahan (_addendum_) dan merupakan bagian yang tidak terpisahkan dari Perjanjian ini.

// Demikian Perjanjian Kerjasama Agen Properti ini dibuat dan ditandatangani dengan sebenar-benarnya dalam keadaan sehat jasmani dan rohani serta tanpa adanya pengaruh atau paksaan dari pihak manapun.`;
//   }
// };

const AgentContract = ({ data }) => {
  const [ready, setReady] = useState(false);

  const hasref = !!data.reference?.code;

  const {
    data: agent,
    loading,
    loaded,
    error,
  } = useGetOne("users", data.reference?.code, {
    enabled: hasref,
  });

  const reference_name = useMemo(
    () => (!loading && loaded && !error ? agent?.name : "-"),
    [loading, loaded, agent, error]
  );

  console.log(data);

  useEffect(() => {
    const loadMou = async () => {
      try {
        const scr = await axios.get(`/source-mou/${data.branch ?? "PUSAT"}`, {
          responseType: "document",
        });

        const screl = document.createElement("script");
        screl.id = "mouscript";
        screl.innerHTML = scr.data.getElementById("mou").innerHTML;

        document.getElementById("mouscript")?.remove();
        document.body.append(screl);

        setTimeout(() => {
          setReady(true);
        }, 500);
      } catch (error) {
        console.error(error);
      }
    };

    if (hasref) {
      if (!loading && loaded && !error) {
        loadMou();
      }
    } else {
      loadMou();
    }
  }, [hasref, loading, loaded, agent, error]);

  return (
    <>
      {ready ? (
        <Markdown>
          {window._getContent({
            ...data,
            reference_name,
            has: {
              ktp: data?.has?.ktp || !!data?.docs?.ktp,
              npwp: data?.has?.npwp || !!data?.docs?.npwp,
            },
            localdate: moment(data.created?.at || new Date())
              .toDate()
              .toLocaleDateString("id-ID", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              }),
          })}
        </Markdown>
      ) : (
        <Box minHeight={"100vh"}>
          {/* <CircularProgress variant="indeterminate" size={64} /> */}
          {[...Array(100).keys()].map((k) => (
            <Box
              clone
              key={k}
              width={`calc(50vh + ${Math.round(Math.random() * 50)}vh)`}
            >
              <Skeleton height={48} />
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default AgentContract;
