import React, { useState } from "react";
import { useLogin, useNotify, Notification } from "react-admin";
import {
  createMuiTheme,
  fade,
  styled,
  ThemeProvider,
} from "@material-ui/core/styles";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
} from "@material-ui/core";
import theme from "./theme";
import { Container } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";

const BgBox = styled(Box)(({ theme }) => ({
  backgroundImage: `linear-gradient(${fade(
    theme.palette.secondary.dark,
    0.95
  )}, ${fade(
    theme.palette.secondary.light,
    0.25
  )}), url(https://source.unsplash.com/1600x900/?home,apartment,office,building)`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
}));

const ForgotPasswordPage = () => {
  const history = useHistory();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const login = useLogin();
  const notify = useNotify();

  const submit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `/forgot-password`,
        { email },
        {
          headers: {
            Accept: "application/json",
          },
        }
      );

      notify("Instruksi lupa password telah dikirim ke email anda", "info");
    } catch (error) {
      console.error(error);
      notify(error.message, "error");
    }
  };

  const goTo = (link) => (event) => {
    event.preventDefault();

    history.push(link, {
      internal: location.state?.internal || true,
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <BgBox width="100vw" height="100vh" bgcolor="secondary.dark" py={4}>
        <Container maxWidth="sm">
          <Card component="form" onSubmit={submit}>
            <CardHeader
              title="MYPRO Admin"
              subheader="Lupa Password?"
              avatar={<Avatar src={"/storage/logo.jpg"} />}
            />
            <CardContent>
              <TextField
                label="Email"
                name="email"
                type="email"
                value={email}
                variant="outlined"
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
              />
            </CardContent>
            <CardActions>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Kirim instruksi
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    onClick={goTo("/login")}
                  >
                    Kembali
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Container>
      </BgBox>
      <Notification />
    </ThemeProvider>
  );
};

export default ForgotPasswordPage;
