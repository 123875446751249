import React from "react";
import {
  useGetIdentity,
  Edit,
  SimpleForm,
  FormDataConsumer,
  required,
  PasswordInput,
  Toolbar,
  SaveButton,
  ImageInput,
  ImageField,
  BooleanInput,
} from "react-admin";
import { Field } from "react-final-form";

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const ProfilePage = (props) => {
  const { identity, loading } = useGetIdentity();

  return (
    <>
      {!loading && (
        // <UserEdit
        //   id={identity.id}
        //   resource="users"
        //   basePath="/profile"
        //   title="Pengaturan Akun"
        //   {...props}
        // />
        <Edit
          resource="users"
          id={identity.id}
          title="Pengaturan Akun"
          basePath="/profile"
          mutationMode="pessimistic"
        >
          <SimpleForm toolbar={<CustomToolbar />}>
            {/* <Field
              component={"input"}
              type="hidden"
              name="change_password"
              defaultValue={"true"}
            /> */}
            <ImageInput source="avatar" label="Foto Profil" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
            <BooleanInput source="change_password" label="Ganti Password?" />
            <FormDataConsumer>
              {({ formData }) =>
                formData.change_password === true && (
                  <PasswordInput
                    source="new_password"
                    validate={[required()]}
                    label="Ganti Password"
                  />
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData }) =>
                formData.change_password === true && (
                  <PasswordInput
                    source="confirm_password"
                    label="Konfirmasi Password Baru"
                    validate={[
                      required(),
                      (v, values) => {
                        if (v && values.new_password) {
                          return v !== values.new_password
                            ? "Konfirmasi password harus sesuai"
                            : undefined;
                        }
                        return undefined;
                      },
                    ]}
                  />
                )
              }
            </FormDataConsumer>
          </SimpleForm>
        </Edit>
      )}
    </>
  );
};

export default ProfilePage;
