import { Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import useAxios from "axios-hooks";

export const R123DevMode = () => {
  const [{ data, loading, error }] = useAxios({
    url: `/api/rumah123/dev-mode`,
  });

  return !loading && !error && data.devMode === true ? (
    <Box clone my={1}>
      <Alert severity="warning">
        Masih dalam tahap ujicoba, data tidak akan tampil di rumah 123
      </Alert>
    </Box>
  ) : (
    <></>
  );
};
