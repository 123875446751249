import MomentUtils from "@date-io/moment";
import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import {
  BooleanField,
  BooleanInput,
  DateField,
  Edit,
  FormTab,
  ImageInput,
  ImageField,
  PasswordInput,
  RadioButtonGroupInput,
  TabbedForm,
  TextField,
  TextInput,
  SelectInput,
  SaveButton,
  DeleteButton,
  Toolbar,
  required,
  regex,
  FormDataConsumer,
  ReferenceInput,
  AutocompleteInput,
  email,
} from "react-admin";
import { DateInput } from "react-admin-date-inputs-refactor";
import { useFormState } from "react-final-form";
import { NumberInput } from "../CustomInputs";

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const CustomToolbar = (props) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
    {props.basePath !== "/profile" && (
      <DeleteButton mutationMode="pessimistic" />
    )}
  </Toolbar>
);

const ChangePassword = () => {
  const { values } = useFormState();

  return values.change_password === true ? (
    <Grid container spacing={1}>
      <Grid item>
        <PasswordInput source="new_password" validate={[required()]} />
      </Grid>
      <Grid item>
        <PasswordInput
          source="confirm_password"
          validate={[
            required(),
            (v, values) => {
              if (v && values.new_password) {
                return v !== values.new_password
                  ? "Konfirmasi password harus sesuai"
                  : undefined;
              }
              return undefined;
            },
          ]}
        />
      </Grid>
    </Grid>
  ) : null;
};

const UserEdit = (props) => (
  <Edit {...props} mutationMode="pessimistic">
    <TabbedForm toolbar={<CustomToolbar />}>
      <FormTab label="Identitas Diri">
        <TextInput
          source="name"
          label="Nama Lengkap"
          fullWidth
          validate={[required()]}
        />
        <TextInput
          type="email"
          source="email"
          label="email"
          validate={[required(), email()]}
        />
        {props.basePath !== "/profile" && (
          <SelectInput
            choices={[
              { id: "USER", name: "Pengguna" },
              { id: "ADMIN", name: "Admin" },
              { id: "SALES", name: "Sales / Agen" },
            ]}
            source="role"
            label="Jenis Pengguna"
            validate={[required()]}
          />
        )}
        {props.basePath !== "/profile" ? (
          <TextInput source="code" type="Kode Karyawan" />
        ) : (
          <TextField source="code" />
        )}
        <TextInput source="alias" label="Nama Alias" fullWidth />
        <SelectInput
          source="gender"
          label="Jenis Kelamin"
          choices={[
            { id: "male", name: "Laki-laki" },
            { id: "female", name: "Perempuan" },
          ]}
        />
        <ReferenceInput source="branch" reference="branches" label="Cabang">
          <SelectInput optionText="name" />
        </ReferenceInput>
        {/* <TextInput source="reference.code" type="Kode Ref" /> */}
        {props.basePath !== "/profile" && (
          <ReferenceInput
            source="reference.code"
            reference="users"
            label="Sales Pembawa"
            filter={{
              active: +true,
              role: "SALES",
              show_all: +true,
            }}
            fullWidth
          >
            <AutocompleteInput
              optionText={(record) => `[${record.code}] ${record.name}`}
            />
          </ReferenceInput>
        )}
        {/* <TextInput source="spv.code" type="Kode SPV" /> */}
        <TextInput
          source="phone"
          label="Telepon/WA"
          validate={[
            required(),
            regex(/[0-9]+/, "Harap masukan nomor telpon dengan angka saja"),
          ]}
        />
        <DateInput
          source="birthdate"
          label="Tanggal Lahir"
          options={{
            format: "DD/MM/YYYY",
            inputVariant: "filled",
            margin: "dense",
          }}
          providerOptions={{ utils: MomentUtils }}
          validate={[required()]}
        />
        <TextInput
          source="birthplace"
          label="Tempat Lahir"
          validate={[required()]}
        />
        <TextInput
          source="address"
          label="Alamat Tinggal Sekarang"
          multiline
          fullWidth
          validate={[required()]}
        />
        <TextInput
          source="nik"
          label="NIK"
          validate={[
            required(),
            regex(/[0-9]+/, "Harap masukan nomor KTP dengan angka saja"),
          ]}
        />
        <TextInput source="npwp" label="NPWP" validate={[required()]} />
        <RadioButtonGroupInput
          source="marital"
          choices={[
            { id: "single", name: "Belum Menikah" },
            { id: "married", name: "Menikah" },
            { id: "divorced", name: "Bercerai" },
          ]}
          label="Status Nikah"
          validate={[required()]}
        />
        <NumberInput source="children" label="Jumlah Anak" />
        <SelectInput
          source="category.type"
          label="Kategori"
          choices={[
            { id: "fresh", name: "Fresh Property" },
            { id: "senior", name: "Senior Property Consultant" },
          ]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.category?.type === "fresh" ? (
              <TextInput
                source="category.detail"
                label="Background"
                fullWidth
              />
            ) : formData.category?.type === "senior" ? (
              <TextInput source="category.detail" label="Ex Agency" fullWidth />
            ) : null
          }
        </FormDataConsumer>
        {/* <RadioButtonGroupInput
          source="employment"
          choices={[
            { id: "internal", name: "Hanya di MYPRO" },
            {
              id: "external",
              name: "Bekerja di Tempat Lain",
            },
          ]}
          label="Status Pekerjaan"
          validate={[required()]}
        /> */}
        <TextInput source="occupation" label="Pekerjaan Lain" fullWidth />
      </FormTab>
      <FormTab label="Rekening">
        <TextInput source="bank.name" label="Bank" validate={[required()]} />
        <TextInput source="bank.number" label="No." validate={[required()]} />
        <TextInput
          source="bank.account"
          label="Atas Nama"
          validate={[required()]}
        />
        <TextInput
          source="bank.branch"
          label="Cabang"
          validate={[required()]}
        />
      </FormTab>
      <FormTab label="Media Sosial">
        <TextInput source="facebook" label="Facebook" />
        <TextInput source="instagram" label="Instagram" />
        <TextInput source="tiktok" label="Tiktok" />
      </FormTab>
      <FormTab label="Dokumen Referensi">
        <ImageInput source="docs.ktp" label="Foto KTP" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageInput source="docs.npwp" label="Foto NPWP" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageInput source="docs.selfie" label="Foto Selfie" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
      </FormTab>
      <FormTab label="Pengaturan">
        <DateField source="mail.verified_at" label="Email Terverifikasi Pada" />
        {props.basePath !== "/profile" && (
          <BooleanInput source="active" label="Aktifkan akun?" />
        )}
        <ImageInput source="avatar" label="Foto Profil" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
        <BooleanInput source="change_password" label="Ganti Password?" />
        <ChangePassword />
      </FormTab>
    </TabbedForm>
  </Edit>
);

export default UserEdit;
