import React, { useEffect } from "react";
import {
  AutocompleteInput,
  Show,
  RadioButtonGroupInput,
  ReferenceField,
  SelectField,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TopToolbar,
  Button,
  EditButton,
  DateField,
  NumberField,
  useShowController,
  useUpdate,
} from "react-admin";
import PrintIcon from "@material-ui/icons/Print";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router";

const NotificationShow = (props) => {
  const history = useHistory();
  const { loading, loaded, record } = useShowController(props);
  const [update, state] = useUpdate();

  useEffect(() => {
    if (!loading && loaded) {
      update(
        "notifications",
        record.id,
        {
          ...record,
          read_at: new Date(),
        },
        record
      );
      history.replace(`/${record.reference.type}s/${record.reference.id}/show`);
    }
  }, [loading, loaded, record]);

  return "Loading...";
};

export default NotificationShow;
