import { Tab, Tabs, useMediaQuery } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import {
  Datagrid,
  DateField,
  List,
  ListContextProvider,
  ReferenceField,
  SimpleList,
  TextField,
  useListContext,
} from "react-admin";
import { NumberInput } from "../CustomInputs";

const TabbedGrid = (props) => {
  const listContext = useListContext();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  // const [tab, setTab] = useState("sell");

  // const { setFilters, filterValues } = listContext;

  // useEffect(() => {
  //   setFilters({ ...filterValues, type: tab });
  // }, [tab]);

  // const handleTabChange = (event, value) => setTab(value);

  return (
    <>
      {/* <Tabs
        value={tab}
        onChange={handleTabChange}
        variant="fullWidth"
        indicatorColor="primary"
      >
        <Tab value="sell" label="Dijual" />
        <Tab value="auction" label="Dilelang" />
        <Tab value="rent" label="Disewakan" />
      </Tabs> */}
      <ListContextProvider value={listContext}>
        {isMobile ? (
          <SimpleList
            primaryText={(record) => record.code}
            secondaryText={(record) => record.customer?.name}
            linkType={"show"}
          />
        ) : (
          <Datagrid {...props} rowClick="show">
            {/* <TextField source="id" /> */}
            <TextField source="code" label="Nomor" />
            <ReferenceField
              label="PAP"
              source="listing.id"
              reference="listings"
            >
              <TextField source="code" />
            </ReferenceField>
            <TextField source="customer.name" label="Pihak 2" />
            <DateField
              source="last.draft_download"
              label="Tanggal Cetak Draft"
            />
          </Datagrid>
        )}
      </ListContextProvider>
    </>
  );
};

const Filters = [
  <NumberInput source="like__code" label="Kode Kontrak" />,
  <NumberInput source="like__listing.code" label="Kode PAP" />,
];

const type_label = {
  sell: "Jual",
  rent: "Sewa",
  auction: "Lelang",
};

const ContractList = (props) => {
  const { search } = props.location;

  const name = useMemo(() => {
    const query = new URLSearchParams(search);
    const filters = JSON.parse(query.get("filter") || "{}");

    return type_label[filters.type] || "";
  }, [search]);

  return (
    <List
      title={`${props.options.label} ${name}`.trim()}
      bulkActionButtons={false}
      filterDefaultValues={{ type: "sell" }}
      filters={Filters}
      exporter={false}
      {...props}
    >
      <TabbedGrid />
    </List>
  );
};

export default ContractList;
