import React from "react";
import {
  AutocompleteInput,
  Show,
  RadioButtonGroupInput,
  ReferenceField,
  SelectField,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TopToolbar,
  Button,
  EditButton,
  DateField,
  NumberField,
} from "react-admin";
import PrintIcon from "@material-ui/icons/Print";
import { Box } from "@material-ui/core";

const Actions = ({ permissions, basePath, data, resource }) => (
  <TopToolbar>
    <Box clone marginRight={1}>
      <Button
        // onClick={goTo("/login")}
        component="a"
        href={`/api/receipts/${data?.id}?print=1`}
        target="_blank"
        download
        label="Cetak Draft"
      >
        <PrintIcon />
      </Button>
    </Box>
    <EditButton basePath={basePath} record={data} />
    {/* {permissions === "admin" && (
      <DeleteButton basePath={basePath} record={data} resource={resource} />
    )} */}
  </TopToolbar>
);

const ReceiptShow = (props) => (
  <Show actions={<Actions />} {...props}>
    <SimpleShowLayout>
      <SelectField
        source="type"
        choices={[
          { id: "secondary", name: "Secondary" },
          { id: "auction", name: "Lelang" },
          {
            id: "rent",
            name: "Sewa",
          },
        ]}
        label="Jenis Kwitansi"
      />
      <ReferenceField
        source="contract.id"
        reference="contracts"
        label="Kontrak"
      >
        <TextField source="code" />
      </ReferenceField>
      <NumberField source="nominal" label="Nominal" />
      <TextField source="from" label="Terima Dari" />
      <TextField source="description" label="Deskripsi" />
      <DateField source="date" />
    </SimpleShowLayout>
  </Show>
);

export default ReceiptShow;
