import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  EmailField,
  TextInput,
  SelectField,
  useRecordContext,
  TopToolbar,
  CreateButton,
  SimpleList,
  BooleanInput,
  useGetIdentity,
  Button,
  useNotify,
  FilterButton,
} from "react-admin";
import React from "react";
import { Avatar, useMediaQuery, Box } from "@material-ui/core";
import { useACL } from "ra-access-control-lists";
import JsFileDownloader from "js-file-downloader";
import useAxios from "axios-hooks";
import { useEffect } from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import { stringify } from "query-string";
import { generateFilter } from "../dataProvider";

const AvatarField = (props) => {
  const record = useRecordContext(props);

  return <Avatar src={record?.avatar?.src} />;
};

const Filters = [
  <TextInput source="like__name" label="Nama" />,
  <TextInput source="like__email" label="Email" />,
  <TextInput source="like__nik" label="NIK" />,
  <TextInput source="like__npwp" label="NPWP" />,
  <BooleanInput
    source="active"
    label="Data Aktif"
    alwaysOn
    parse={(v) => +v}
  />,
];

const ListActions = (props) => {
  const { exporter, filterValues } = props;

  const exportData = () => {
    return new JsFileDownloader({
      url: `/api/user-export?${stringify({
        filter: generateFilter(filterValues),
      })}`,
    });
  };

  return (
    <TopToolbar>
      <FilterButton />
      <CreateButton />
      {exporter && (
        <Button onClick={exportData} label="Export">
          <GetAppIcon />
        </Button>
      )}
    </TopToolbar>
  );
};

const SyncR123Button = () => {
  const notify = useNotify();
  const [{ data, loading, error }, refetch] = useAxios(
    { url: "/api/rumah123/agents" },
    { manual: true }
  );

  useEffect(() => {
    if (!loading && error) {
      notify(error.message, "error");
    }
  }, [loading, error]);

  const syncNow = async () => {
    try {
      await refetch();

      notify("Sinkronisasi telah selesai");
    } catch (error) {}
  };

  return (
    <Box marginTop={1}>
      <Button
        label="Sinkron dengan Rumah123"
        disabled={loading}
        variant="outlined"
        onClick={syncNow}
      />
    </Box>
  );
};

const UserList = (props) => {
  const { identity, loading } = useGetIdentity();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <>
      <List
        bulkActionButtons={false}
        actions={<ListActions />}
        filters={Filters}
        sort={{ field: "id", order: "DESC" }}
        filterDefaultValues={{ active: +true }}
        exporter={identity?.role === "ADMIN"}
        {...props}
      >
        {isMobile ? (
          <SimpleList
            leftAvatar={(record) => <Avatar src={record?.avatar?.src} />}
            primaryText={(record) => record.name}
            secondaryText={(record) =>
              `${record.email} | ${record.nik} | ${record.npwp} | ${record.code}`
            }
            tertiaryText={(record) => record.role}
            linkType={"show"}
          />
        ) : (
          <Datagrid rowClick="show">
            <AvatarField source="avatar.src" label="Foto" />
            <TextField source="code" />
            <TextField source="name" label="Nama" />
            <EmailField source="email" />
            <TextField source="nik" label="NIK" />
            <TextField source="npwp" label="NPWP" />
            <TextField source="phone" label="Telepon/WA" />
            <SelectField
              choices={[
                { id: "USER", name: "Pengguna" },
                { id: "ADMIN", name: "Admin" },
                { id: "SALES", name: "Sales / Agen" },
              ]}
              source="role"
              type="Jenis Pengguna"
            />
            <DateField source="created.at" label="Tanggal Buat" />
            <BooleanField source="mail.verified" label="Terverifikasi" />
            <BooleanField source="active" label="Aktif" />
          </Datagrid>
        )}
      </List>
      <SyncR123Button />
    </>
  );
};

export default UserList;
