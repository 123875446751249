import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Box,
  CardActionArea,
  CardMedia,
  styled,
  Paper,
  fade,
  ButtonBase,
  Container,
  Button,
  useTheme,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import moment from "moment";
import {
  useAuthenticated,
  Title,
  ListBase,
  useListContext,
  useGetIdentity,
  useAuthState,
} from "react-admin";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import useAxios from "axios-hooks";
import LanguageIcon from "@material-ui/icons/Language";

const ListingGrid = () => {
  const { ids, data, basePath, loading } = useListContext();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();

  const goTo = (link) => (event) => {
    event.preventDefault();

    history.push(link, {
      internal: location.state?.internal || true,
    });
  };

  return (
    <Grid container spacing={1} alignItems="stretch" alignContent="center">
      {loading &&
        [...Array(6).keys()].map((k) => (
          <Grid item key={k} xs={12} sm={6} md={4}>
            <Skeleton width={"100%"} height={256} />
          </Grid>
        ))}
      {ids.map((id) => {
        const d = data[id];
        console.log(d);
        return (
          <Grid
            item
            key={id}
            xs={12}
            sm={6}
            md={4}
            // lg={3}
            // component={GridCard}
          >
            <Card variant="outlined">
              <CardHeader
                avatar={
                  <Avatar src={d.sales.avatar?.src || "/storage/logo.jpg"} />
                }
                title={d.sales.name || "BELUM DITENTUKAN"}
                titleTypographyProps={{ variant: "subtitle1" }}
              />
              <Box clone>
                <CardActionArea
                  component="a"
                  href={`/listings/${d.id}/show`}
                  onClick={goTo(`/listings/${d.id}/show`)}
                >
                  <Box
                    clone
                    height={200}
                    bgcolor={`${theme.palette.primary.light} !important`}
                  >
                    <CardMedia
                      image={
                        d.photo?.primary?.src ||
                        // "/storage/listing_placeholder.png"
                        "/storage/sale.png"
                      }
                      title={d.title}
                    />
                  </Box>
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="h2">
                      {d.title}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="textSecondary"
                      gutterBottom
                    >
                      Telah dilihat {d.hit.count}x
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Box>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

const Statistic = () => {
  const { identity, loading: identityLoading } = useGetIdentity();

  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: `/api/monthly-stats`,
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (!identityLoading) {
      refetch();
    }
  }, [identity, identityLoading]);

  if (error) {
    return (
      <Typography color="error">
        Error, {JSON.stringify(error.message)}
      </Typography>
    );
  }

  return (
    <Grid container spacing={1}>
      {loading &&
        [...Array(4).keys()].map((k) => (
          <Grid item xs={3} key={k}>
            <Skeleton width={"100%"} height={100} />
          </Grid>
        ))}
      {!loading && !error && data && (
        <>
          {"new_listing" in data && (
            <Grid item xs={12} md={6} xl={2}>
              <Card variant="outlined">
                <Box clone bgcolor="secondary.dark" color="white">
                  <CardHeader
                    title="PAP Baru"
                    titleTypographyProps={{ variant: "body1" }}
                  />
                </Box>
                <CardContent>
                  <Typography
                    component="div"
                    variant="h3"
                    align="center"
                    color="primary"
                  >
                    {data.new_listing}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )}
          {"active_listing" in data && (
            <Grid item xs={12} md={6} xl={2}>
              <Card variant="outlined">
                <Box clone bgcolor="primary.light">
                  <CardHeader
                    title="Total PAP Aktif"
                    titleTypographyProps={{ variant: "body1" }}
                  />
                </Box>
                <CardContent>
                  <Typography
                    component="div"
                    variant="h3"
                    align="center"
                    color="secondary"
                  >
                    {data.active_listing}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )}
          {"new_user" in data && (
            <Grid item xs={12} md={6} xl={2}>
              <Card variant="outlined">
                <Box clone bgcolor="secondary.dark" color="white">
                  <CardHeader
                    title="Pengguna Baru"
                    titleTypographyProps={{ variant: "body1" }}
                  />
                </Box>
                <CardContent>
                  <Typography
                    component="div"
                    variant="h3"
                    align="center"
                    color="primary"
                  >
                    {data.new_user}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )}
          {"active_user" in data && (
            <Grid item xs={12} md={6} xl={2}>
              <Card variant="outlined">
                <Box clone bgcolor="primary.light">
                  <CardHeader
                    title="Total Pengguna Aktif"
                    titleTypographyProps={{ variant: "body1" }}
                  />
                </Box>
                <CardContent>
                  <Typography
                    component="div"
                    variant="h3"
                    align="center"
                    color="secondary"
                  >
                    {data.active_user}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )}
          {"pending_pap" in data && (
            <Grid item xs={12} md={6} xl={2}>
              <Card variant="outlined">
                <Box clone bgcolor="secondary.dark" color="white">
                  <CardHeader
                    title="Total PAP Pending"
                    titleTypographyProps={{ variant: "body1" }}
                  />
                </Box>
                <CardContent>
                  <Typography
                    component="div"
                    variant="h3"
                    align="center"
                    color="primary"
                  >
                    {data.pending_pap}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )}
          {"pending_contract" in data && (
            <Grid item xs={12} md={6} xl={2}>
              <Card variant="outlined">
                <Box clone bgcolor="primary.light">
                  <CardHeader
                    title="Total Kontrak Pending"
                    titleTypographyProps={{ variant: "body1" }}
                  />
                </Box>
                <CardContent>
                  <Typography
                    component="div"
                    variant="h3"
                    align="center"
                    color="secondary"
                  >
                    {data.pending_contract}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

const GradientPaperOne = styled(Paper)(({ theme }) => ({
  backgroundImage: `linear-gradient(45deg, ${fade(
    theme.palette.secondary.light,
    0.85
  )}, ${fade(theme.palette.secondary.dark, 0.85)})`,
  padding: theme.spacing(1, 2),
  margin: theme.spacing(1, 0),
  color: "white",
  width: "100%",
}));

const GradientPaperTwo = styled(Paper)(({ theme }) => ({
  backgroundImage: `linear-gradient(135deg, ${fade(
    theme.palette.primary.light,
    0.85
  )}, ${fade(theme.palette.primary.dark, 0.85)})`,
  padding: theme.spacing(1, 2),
  margin: theme.spacing(1, 0),
  color: "white",
  width: "100%",
}));

const greetings = {
  morning: "Pagi",
  noon: "Siang",
  afternoon: "Sore",
  night: "Malam",
};

const Greetings = ({ currentTime }) => {
  const { identity, loading } = useGetIdentity();

  return (
    <>
      <Typography variant="h4" component="h1">
        {`Selamat ${greetings[currentTime]},`}
      </Typography>
      {!loading && identity && (
        <Typography variant="h5" component="h2">
          {identity?.name}
        </Typography>
      )}
    </>
  );
};

const good = {
  morning: "jog",
  noon: "sun",
  afternoon: "motivation",
  night: "dream",
};

const Dashboard = () => {
  useAuthenticated();
  const { loading, authenticated } = useAuthState();

  const currentTime = moment().isBetween(
    moment().hour(3).minute(0),
    moment().hour(10).minute(59)
  )
    ? "morning"
    : moment().isBetween(
        moment().hour(11).minute(0),
        moment().hour(14).minute(59)
      )
    ? "noon"
    : moment().isBetween(
        moment().hour(15).minute(0),
        moment().hour(17).minute(59)
      )
    ? "afternoon"
    : "night";

  return (
    <>
      <Title>Selamat Datang</Title>
      {!loading && authenticated && (
        <Container maxWidth="xl">
          <Grid container spacing={2} justify="center">
            <Grid item xs={12} md={4}>
              <GradientPaperOne elevation={1}>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  alignItems="center"
                  alignContent="center"
                  justify="flex-start"
                >
                  {/* <Box clone marginLeft={`-72px !important`}> */}
                  <Grid item>
                    <Box clone margin={1}>
                      <Paper elevation={2}>
                        <Box
                          clone
                          height={128}
                          borderRadius="borderRadius"
                          p={1}
                        >
                          <img src={`/storage/${good[currentTime]}.png`} />
                        </Box>
                      </Paper>
                    </Box>
                  </Grid>
                  {/* </Box> */}
                  <Grid item>
                    <Greetings currentTime={currentTime} />
                  </Grid>
                </Grid>
              </GradientPaperOne>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box clone width={"100%"}>
                <ButtonBase component="a" href="/catalog">
                  <GradientPaperTwo elevation={1}>
                    <Grid
                      container
                      spacing={3}
                      direction="row"
                      alignItems="center"
                      alignContent="center"
                      justify="flex-end"
                    >
                      <Grid item>
                        <Typography variant="h4" component="h1" align="right">
                          Kunjungi Katalog
                        </Typography>
                      </Grid>
                      {/* <Box clone marginRight={`-72px !important`}> */}
                      <Grid item>
                        <Box clone margin={1}>
                          <Paper elevation={2}>
                            <Box
                              clone
                              height={128}
                              borderRadius="borderRadius"
                              p={1}
                            >
                              {/* <img src={`/storage/catalog.png`} /> */}
                              <img src={`/storage/seo.png`} />
                            </Box>
                          </Paper>
                        </Box>
                      </Grid>
                      {/* </Box> */}
                    </Grid>
                  </GradientPaperTwo>
                </ButtonBase>
              </Box>
            </Grid>
          </Grid>
          <Box marginTop={1} marginBottom={2}>
            <Container maxWidth="sm">
              <Button
                fullWidth
                variant="contained"
                startIcon={<LanguageIcon />}
                endIcon={<LanguageIcon />}
                size="large"
                component="a"
                href="http://myproindonesia.com"
              >
                Kunjungi Website
              </Button>
            </Container>
          </Box>
          <Box clone my={1} px={1} py={3}>
            <Paper elevation={1}>
              <Grid
                container
                spacing={1}
                alignContent="center"
                alignItems="center"
                justify="space-around"
              >
                <Grid item>
                  <Box clone width="auto" height={200}>
                    {/* <img src={`/storage/stats.png`} /> */}
                    <img src={`/storage/team.png`} />
                  </Box>
                </Grid>
                <Grid item md={9}>
                  <Typography variant="h5" gutterBottom color="textSecondary">
                    Statistik bulan ini
                  </Typography>
                  <Statistic />
                </Grid>
              </Grid>
            </Paper>
          </Box>
          <Box clone my={1} px={1} py={3}>
            <Paper elevation={2}>
              <Grid
                container
                spacing={1}
                alignContent="center"
                alignItems="center"
                justify="space-around"
              >
                <Grid item>
                  <Box clone width="auto" height={200}>
                    {/* <img src={`/storage/popular.png`} /> */}
                    <img src={`/storage/marketing.png`} />
                  </Box>
                </Grid>
                <Grid item md={9}>
                  <Typography variant="h5" gutterBottom color="textSecondary">
                    Populer bulan ini
                  </Typography>
                  <ListBase
                    resource="listings"
                    basePath="/"
                    filter={{
                      view_range: moment().format("YYYY-MM-DD"),
                      active: +true,
                    }}
                    perPage={6}
                    sort={{ field: "hit_count", order: "DESC" }}
                  >
                    <ListingGrid />
                  </ListBase>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Container>
      )}
    </>
  );
};

export default Dashboard;
