import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  BooleanInput,
  EmailField,
  ListContextProvider,
  useListContext,
  useRecordContext,
  TextInput,
  Filter,
  SelectInput,
  ImageField,
  NumberField,
  SimpleList,
  useGetIdentity,
  TopToolbar,
  Button,
  CreateButton,
  FilterButton,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import React, { useState } from "react";
import { Tab, Tabs } from "@material-ui/core";
import { listingType } from "../mapping";
import { Avatar } from "@material-ui/core";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import { useEffect, useMemo } from "react";
import { useMediaQuery } from "@material-ui/core";
import { NumberInput } from "../CustomInputs";
import JsFileDownloader from "js-file-downloader";
import GetAppIcon from "@material-ui/icons/GetApp";
import { stringify } from "query-string";
import { generateFilter } from "../dataProvider";

const TypeField = (props) => {
  const record = useRecordContext(props);

  const type = listingType.find((l) => l.id === record.type);

  return <span>{type?.name || "-"}</span>;
};

const ThumbnailField = (props) => {
  const record = useRecordContext(props);

  return (
    <Avatar variant="rounded" src={record.photo?.primary?.src}>
      <HomeWorkIcon />
    </Avatar>
  );
};

const ListActions = (props) => {
  const { exporter, filterValues } = props;

  const exportData = () => {
    return new JsFileDownloader({
      url: `/api/listing-export?${stringify({
        filter: generateFilter(filterValues),
      })}`,
    });
  };

  return (
    <TopToolbar>
      <FilterButton />
      <CreateButton />
      {exporter && (
        <Button onClick={exportData} label="Export">
          <GetAppIcon />
        </Button>
      )}
    </TopToolbar>
  );
};

const TabbedGrid = (props) => {
  const listContext = useListContext();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [tab, setTab] = useState("sell");

  const { setFilters, filterValues } = listContext;

  useEffect(() => {
    let marketing_type = {
      is_sell: null,
      is_rent: null,
      is_auction: null,
    };
    if (tab === "sell") {
      marketing_type = {
        is_sell: +true,
        is_rent: null,
        is_auction: null,
      };
    } else if (tab === "rent") {
      marketing_type = {
        is_sell: null,
        is_rent: +true,
        is_auction: null,
      };
    } else if (tab === "auction") {
      marketing_type = {
        is_sell: null,
        is_rent: null,
        is_auction: +true,
      };
    }

    setFilters({ ...filterValues, ...marketing_type });
  }, [tab]);

  const handleTabChange = (event, value) => setTab(value);

  return (
    <>
      {/* <Tabs
        value={tab}
        onChange={handleTabChange}
        variant="fullWidth"
        indicatorColor="primary"
      >
        <Tab value="sell" label="Dijual" />
        <Tab value="auction" label="Dilelang" />
        <Tab value="rent" label="Disewakan" />
      </Tabs>
      <ListContextProvider value={listContext}> */}
      {isMobile ? (
        <SimpleList
          primaryText={(record) => record.code}
          secondaryText={(record) => record.title}
          tertiaryText={(record) => record.property?.address}
          linkType={"show"}
        />
      ) : (
        <Datagrid {...props} rowClick="show">
          {/* <TextField source="id" /> */}
          <DateField source="created.at" label="Tanggal Buat" locales="id-ID" />
          <TextField source="code" label="Nomor" />
          <TextField source="title" label="Judul" />
          <TypeField source="type" label="Tipe" />
          <TextField source="property.address" label="Alamat" />
          <TextField source="property.city" label="Kota/Kabupaten" />
          <NumberField source="price.sell" label="Harga Jual" />
          <NumberField source="price.rent" label="Harga Sewa" />
          <TextField source="owner.name" label="Pemilik" />
          <ThumbnailField source="photo.primary.src" label="Foto" />
          <BooleanField source="verified.is" label="Terverifikasi" />
        </Datagrid>
      )}
      {/* </ListContextProvider> */}
    </>
  );
};

// const PapFilters = (props) => (
//   <Filter {...props}>
//     <TextInput source="code" label="Kode" />
//     <TextInput source="property.address" label="Alamat" />
//     <SelectInput source="type" label="Tipe" choices={listingType} />
//   </Filter>
// );

const adminFilters = [
  <TextInput source="like__code" label="Kode PAP" />,
  // <TextInput source="like__title" label="Judul" />,
  <ReferenceInput
    source="sales.user_id"
    reference="users"
    label="Agen"
    filter={{ role: "SALES" }}
  >
    <AutocompleteInput optionText="name" fullWidth />
  </ReferenceInput>,
  <TextInput source="like__property_address" label="Alamat" />,
  <SelectInput source="type" label="Tipe" choices={listingType} />,
  // <NumberInput source="gte__price_sell" label="Harga Jual Mulai" />,
  // <NumberInput source="lte__price_sell" label="Harga Jual Sampai" />,
  // <NumberInput source="gte__price_rent" label="Harga Sewa Mulai" />,
  // <NumberInput source="lte__price_rent" label="Harga Sewa Sampai" />,
  <BooleanInput source="verified.is" label="Terverifikasi?" />,
  <BooleanInput
    source="active"
    label="Data Aktif"
    alwaysOn
    parse={(v) => +v}
  />,
];

const salesFilters = [
  <TextInput source="like__code" label="Kode PAP" />,
  <TextInput source="like__property_address" label="Alamat" />,
  <SelectInput source="type" label="Tipe" choices={listingType} />,
  <BooleanInput source="verified.is" label="Terverifikasi?" />,
  <BooleanInput
    source="active"
    label="Data Aktif"
    alwaysOn
    parse={(v) => +v}
  />,
];

const type_label = {
  is_sell: "Jual",
  is_rent: "Sewa",
  is_auction: "Lelang",
};

const PapList = (props) => {
  const { search } = props.location;
  const { identity } = useGetIdentity();

  const name = useMemo(() => {
    const query = new URLSearchParams(search);
    const filters = JSON.parse(query.get("filter") || "{}");

    return (
      type_label[Object.keys(filters).find((k) => k.startsWith("is_"))] || ""
    );
  }, [search]);

  return (
    <List
      title={`${props.options.label} ${name}`.trim()}
      bulkActionButtons={false}
      actions={<ListActions />}
      filterDefaultValues={{ is_sell: +true, active: +true }}
      // filters={<PapFilters />}
      filters={identity?.role === "ADMIN" ? adminFilters : salesFilters}
      exporter={identity?.role === "ADMIN"}
      {...props}
    >
      <TabbedGrid />
    </List>
  );
};

export default PapList;
