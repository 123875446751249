import {
  Avatar,
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import DraftsIcon from "@material-ui/icons/Drafts";
import EmailIcon from "@material-ui/icons/Email";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SettingsIcon from "@material-ui/icons/Settings";
import DefaultIcon from "@material-ui/icons/ViewList";
import useInterval from "@use-it/interval";
import useAxios from "axios-hooks";
import moment from "moment";
import React, { forwardRef, useMemo, useState } from "react";
import {
  AppBar,
  DashboardMenuItem,
  getResources,
  Layout,
  List,
  MenuItemLink,
  SimpleList,
  useGetIdentity,
  UserMenu,
  Menu as AdminMenu,
} from "react-admin";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

const ConfigurationMenu = forwardRef(({ onClick }, ref) => (
  <MenuItemLink
    ref={ref}
    to="/profile"
    primaryText="Profil Saya"
    leftIcon={<SettingsIcon />}
    onClick={onClick} // close the menu on click
  />
));

const Notifications = () => {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [{ data, loading, error }, refetch] = useAxios({
    url: `/api/notification-count`,
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  });

  useInterval(() => {
    refetch();
  }, 10000 * 5);

  return (
    <>
      <IconButton color="inherit" onClick={() => setOpen(true)}>
        <Badge
          badgeContent={!loading && !error ? data?.total || 0 : 0}
          color="primary"
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle>Daftar Notifikasi</DialogTitle>
        <DialogContent>
          <List
            basePath={"/notifications"}
            resource="notifications"
            exporter={false}
            syncWithLocation={false}
            title=" "
            actions={null}
            component="div"
            sort={{ field: "created_at", order: "DESC" }}
          >
            <SimpleList
              primaryText={(record) => record.content}
              secondaryText={(record) =>
                moment(record.created.at).format("DD MMM YYYY HH:mm")
              }
              tertiaryText={(record) =>
                record.read?.at ? <DraftsIcon /> : <EmailIcon />
              }
              onClick={() => setOpen(false)}
              linkType="show"
            />
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Tutup</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const MyUserMenu = (props) => {
  const { identity, loading } = useGetIdentity();

  return (
    <Box clone width="auto !important">
      <Grid container>
        <Grid item>
          <Notifications />
        </Grid>
        <Grid item>
          <UserMenu
            {...props}
            icon={
              <Box clone width={24} height={24}>
                <Avatar src={identity?.avatar?.src} />
              </Box>
            }
          >
            <ConfigurationMenu />
          </UserMenu>
        </Grid>
      </Grid>
    </Box>
  );
};

const MyAppBar = (props) => <AppBar {...props} userMenu={<MyUserMenu />} />;

const type_label = {
  sell: "Jual",
  rent: "Sewa",
  auction: "Lelang",
};

const MyAppMenu = (props) => {
  const { identity } = useGetIdentity();
  const { search, pathname } = useLocation();
  const [expand, setExpand] = useState(pathname.substr(1));
  const resources = useSelector(getResources);
  const open = useSelector((state) => state.admin.ui.sidebarOpen);

  const activeFilter = useMemo(() => {
    const query = new URLSearchParams(search);
    return JSON.parse(query.get("filter") || "{}");
  }, [search]);

  return (
    <div>
      <DashboardMenuItem />
      {resources
        .filter((r) => r.hasList)
        .map((resource) =>
          ["listings", "contracts", "receipts"].includes(resource.name) ? (
            <>
              <MenuItemLink
                key={resource.name}
                to={`/${resource.name}`}
                primaryText={
                  <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    alignContent="center"
                    spacing={1}
                  >
                    <Grid item>
                      {(resource.options && resource.options.label) ||
                        resource.name}
                    </Grid>
                    <Grid item>
                      {expand !== resource.name ? (
                        <ArrowDropDownIcon fontSize="small" />
                      ) : (
                        <ArrowDropUpIcon fontSize="small" />
                      )}
                    </Grid>
                  </Grid>
                }
                leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                onMouseDown={(event) => {
                  event.preventDefault();
                  event.stopPropagation();

                  setExpand(expand !== resource.name ? resource.name : null);
                }}
                sidebarIsOpen={open}
              />
              {expand === resource.name &&
                ["sell", "rent", "auction"]
                  .filter((k) =>
                    identity?.role === "SALES" ? k !== "auction" : true
                  )
                  .map((k) => (
                    <MenuItemLink
                      key={`${resource.name}-${k}`}
                      to={{
                        pathname: `/${resource.name}`,
                        search: `filter=${JSON.stringify(
                          resource.name === "listings"
                            ? {
                                [`is_${k}`]: +true,
                                active: +true,
                              }
                            : {
                                type: k,
                              }
                        )}`,
                      }}
                      leftIcon={
                        <ArrowRightIcon
                          color={
                            resource.name === "listings"
                              ? activeFilter[`is_${k}`] === +true
                                ? "secondary"
                                : "inherit"
                              : activeFilter["type"] === k
                              ? "secondary"
                              : "inherit"
                          }
                        />
                      }
                      primaryText={
                        <Typography
                          variant="subtitle2"
                          color={
                            resource.name === "listings"
                              ? activeFilter[`is_${k}`] === +true
                                ? "secondary"
                                : "textSecondary"
                              : activeFilter["type"] === k
                              ? "secondary"
                              : "textSecondary"
                          }
                        >
                          {type_label[k]}
                        </Typography>
                      }
                      onClick={props.onMenuClick}
                      sidebarIsOpen={open}
                    />
                  ))}
              {expand === "receipts" && resource.name === "receipts" && (
                <MenuItemLink
                  to={{
                    pathname: `/receipts`,
                    search: `filter=${JSON.stringify({
                      type: "primary",
                    })}`,
                  }}
                  leftIcon={
                    <ArrowRightIcon
                      color={
                        activeFilter["type"] === "primary"
                          ? "secondary"
                          : "inherit"
                      }
                    />
                  }
                  primaryText={
                    <Typography
                      variant="subtitle2"
                      color={
                        activeFilter["type"] === "primary"
                          ? "secondary"
                          : "textSecondary"
                      }
                    >
                      Primary
                    </Typography>
                  }
                  onClick={props.onMenuClick}
                  sidebarIsOpen={open}
                />
              )}
            </>
          ) : (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={
                (resource.options && resource.options.label) || resource.name
              }
              leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
              onClick={props.onMenuClick}
              sidebarIsOpen={open}
            />
          )
        )}
    </div>
  );
};

const AdminLayout = (props) => (
  <Layout
    {...props}
    appBar={MyAppBar}
    menu={MyAppMenu}
    // notification={MyNotification}
  />
);

export default AdminLayout;
