import { Tab, Tabs, useMediaQuery } from "@material-ui/core";
import moment from "moment";
import ns from "number-string";
import React, { useEffect, useMemo, useState } from "react";
import {
  Datagrid,
  DateField,
  List,
  ListContextProvider,
  ReferenceField,
  SimpleList,
  TextField,
  useListContext,
} from "react-admin";
import { NumberInput } from "../CustomInputs";

const TabbedGrid = (props) => {
  const listContext = useListContext();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  // const [tab, setTab] = useState("secondary");

  // const { setFilters, filterValues } = listContext;

  // useEffect(() => {
  //   setFilters({ ...filterValues, type: tab });
  // }, [tab]);

  // const handleTabChange = (event, value) => setTab(value);

  return (
    <>
      {/* <Tabs
        value={tab}
        onChange={handleTabChange}
        variant="fullWidth"
        indicatorColor="primary"
      >
        <Tab value="secondary" label="Secondary" />
        <Tab value="auction" label="Lelang" />
        <Tab value="rent" label="Sewa" />
      </Tabs> */}
      <ListContextProvider value={listContext}>
        {isMobile ? (
          <SimpleList
            primaryText={(record) => record.code}
            secondaryText={(record) =>
              moment(record.date).format("DD MMM YYYY")
            }
            tertiaryText={(record) =>
              ns.toMoney(record.nominal || 0, {
                decimalMark: ",",
                thousandSeparator: ".",
                symbol: "Rp. ",
                minPrecision: 0,
                maxPrecision: 0,
              })
            }
            linkType={"show"}
          />
        ) : (
          <Datagrid {...props} rowClick="show">
            {/* <TextField source="id" /> */}
            <TextField source="code" label="Nomor" />
            <ReferenceField
              label="Kontrak"
              source="contract.id"
              reference="contracts"
            >
              <TextField source="code" />
            </ReferenceField>
            <DateField source="date" label="Tanggal Buat" />
            <TextField source="nominal" label="Nominal" />
            <TextField source="description" label="Keterangan" />
          </Datagrid>
        )}
      </ListContextProvider>
    </>
  );
};

const Filters = [
  <NumberInput source="like__code" label="Kode Kwitansi" />,
  <NumberInput source="like__contract.code" label="Kode Kontrak" />,
];

const type_label = {
  sell: "Jual",
  rent: "Sewa",
  auction: "Lelang",
};

const ReceiptList = (props) => {
  const { search } = props.location;

  const name = useMemo(() => {
    const query = new URLSearchParams(search);
    const filters = JSON.parse(query.get("filter") || "{}");

    return type_label[filters.type] || "";
  }, [search]);

  return (
    <List
      title={`${props.options.label} ${name}`.trim()}
      bulkActionButtons={false}
      filterDefaultValues={{ type: "secondary" }}
      filters={Filters}
      exporter={false}
      {...props}
    >
      <TabbedGrid />
    </List>
  );
};

export default ReceiptList;
