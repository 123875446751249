import TextField from "@material-ui/core/TextField";

import { useField } from "react-final-form";
import NumberFormat from "react-number-format";
import { useInput } from "react-admin";

const NumberFormatCustom = ({ inputRef, onChange, ...props }) => {
  return (
    <NumberFormat
      getInputRef={inputRef}
      // onChange      = {onChange}
      onValueChange={({ value, floatValue, ...rest }) => onChange(floatValue)}
      decimalSeparator=","
      thousandSeparator="."
      // isNumericString
      {...props}
    />
  );
};

export const NumberInput = ({ label, className, fullWidth, ...props }) => {
  const {
    input: { name, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props);

  return (
    <TextField
      fullWidth={fullWidth}
      className={className}
      variant="filled"
      margin="dense"
      name={name}
      label={label}
      error={!!(touched && error)}
      helperText={touched && error}
      required={isRequired}
      InputProps={{
        inputComponent: NumberFormatCustom,
      }}
      {...rest}
    />
  );
};
