import { Typography, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useEffect } from "react";
import {
  AutocompleteInput,
  Create,
  RadioButtonGroupInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
  useGetOne,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  useGetIdentity,
} from "react-admin";
import { useFormState, useForm } from "react-final-form";

import arrayMutators from "final-form-arrays";
import { CompactForm, RaBox, RaGrid } from "ra-compact-ui";
import { expenseType } from "../mapping";
import { useNewACL } from "../acl";
import { NumberInput } from "../CustomInputs";

const mutators = {
  ...arrayMutators,
  setPrice: ([name, nominal], state, { changeValue }) => {
    changeValue(state, name, () => nominal);
  },
};

const ListingToNominal = ({}) => {
  const form = useForm();
  const { values } = useFormState();

  const { data, loading, error } = useGetOne("listings", values?.listing?.id, {
    enabled: !!values?.listing?.id,
  });

  useEffect(() => {
    if (!loading && !error && data) {
      form.mutators.setPrice(
        "nominal",
        data.marketing.type.includes("rent")
          ? data.price.rent
          : data.price.sell || 0
      );
    }
  }, [loading, error, data]);

  return null;
};

const ContractCreate = (props) => {
  const receipt = useNewACL("receipts");
  const { identity } = useGetIdentity();

  return (
    <Create {...props} redirect="show">
      <CompactForm mutators={mutators} layoutComponents={[RaBox, RaGrid]}>
        <RaGrid container spacing={3}>
          <RaGrid item xs={12} md="auto">
            <ReferenceInput
              source="listing.id"
              reference="listings"
              label="PAP"
              validate={[required()]}
              filter={{ active: +true }}
            >
              <AutocompleteInput
                optionText={(record) => `[${record.code}] ${record.title}`}
              />
            </ReferenceInput>
            <RadioButtonGroupInput
              source="type"
              choices={[
                { id: "sell", name: "SPPJB" },
                identity?.role === "ADMIN"
                  ? { id: "auction", name: "SPBL" }
                  : null,
                {
                  id: "rent",
                  name: "Sewa",
                },
              ].filter(Boolean)}
              label="Jenis Kontrak"
              validate={[required()]}
            />
            <ListingToNominal />
            <NumberInput source="nominal" label="Nominal" />
            <TextInput
              source="customer.name"
              label="Nama Pihak Pembeli/Penyewa"
            />
            <TextInput
              source="customer.address"
              label="Alamat Pihak Pembeli/Penyewa"
            />
            <TextInput
              source="customer.email"
              label="Email Pihak Pembeli/Penyewa"
            />
            <TextInput
              source="customer.phone"
              label="Telepon Pihak Pembeli/Penyewa"
            />
            <TextInput
              source="customer.nik"
              label="NIK Pihak Pembeli/Penyewa"
            />
            <TextInput
              source="customer.npwp"
              label="NPWP Pihak Pembeli/Penyewa"
            />
          </RaGrid>
          <RaGrid item xs={12} md="auto">
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.type === "sell" ? (
                  <RadioButtonGroupInput
                    source="transaction"
                    choices={[
                      { id: "cash", name: "Cash" },
                      { id: "kpr", name: "KPR" },
                      {
                        id: "both",
                        name: "Cash & KPR",
                      },
                      { id: "installment", name: "Pembayaran Bertahap" },
                    ]}
                    label="Jenis Transaksi"
                    validate={[required()]}
                  />
                ) : (
                  <></>
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.type === "sell" ? (
                  <NumberInput source="downpayment" label="Nominal UTJ" />
                ) : (
                  <></>
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.type === "sell" ? (
                  <NumberInput source="settlement" label="Nominal Pelunasan" />
                ) : (
                  <></>
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.type === "sell" ? (
                  <TextInput source="shm" label="No. SHM" />
                ) : (
                  <></>
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.type === "rent" ? (
                  <NumberInput source="downpayment" label="Nominal UTJ" />
                ) : (
                  <></>
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.type === "rent" ? (
                  <NumberInput source="deposit" label="Nominal Deposit" />
                ) : (
                  <></>
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.type === "rent" ? (
                  <NumberInput source="fine" label="Denda Keterlambatan" />
                ) : (
                  <></>
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.type === "rent" ? (
                  <RadioButtonGroupInput
                    source="settlement"
                    choices={[
                      { id: "full", name: "Pembayaran Langsung Penuh" },
                      { id: "installment", name: "Dibagi Beberapa Termin" },
                    ]}
                    label="Jenis Pelunasan"
                    validate={[required()]}
                  />
                ) : (
                  <></>
                )
              }
            </FormDataConsumer>
            {receipt.create ? (
              <ArrayInput
                source="additional.bill"
                label="Potongan / Pengeluaran"
              >
                <SimpleFormIterator>
                  <SelectInput
                    source="name"
                    choices={expenseType}
                    label="Jenis"
                    validate={[required()]}
                  />
                  <NumberInput
                    source="nominal"
                    label="Nominal"
                    validate={[required()]}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            ) : (
              <></>
            )}
            {receipt.create ? (
              <ArrayInput source="marketers" label="Sales lain yang terlibat">
                <SimpleFormIterator>
                  <ReferenceInput
                    source="user_id"
                    reference="users"
                    label="Cari Agen"
                    filter={{ role: "SALES" }}
                  >
                    <AutocompleteInput optionText="name" fullWidth />
                  </ReferenceInput>
                </SimpleFormIterator>
              </ArrayInput>
            ) : (
              <></>
            )}
          </RaGrid>
        </RaGrid>
      </CompactForm>
    </Create>
  );
};

export default ContractCreate;
