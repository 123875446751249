import React from "react";
import { Box } from "@material-ui/core";
import { GiBed } from "@react-icons/all-files/gi/GiBed";
import { GiBathtub } from "@react-icons/all-files/gi/GiBathtub";
import { GiSofa } from "@react-icons/all-files/gi/GiSofa";
import { GiSpoon } from "@react-icons/all-files/gi/GiSpoon";
import { GiTv } from "@react-icons/all-files/gi/GiTv";
import { GiChickenOven } from "@react-icons/all-files/gi/GiChickenOven";
import { GiFlowerPot } from "@react-icons/all-files/gi/GiFlowerPot";
import { GiElectric } from "@react-icons/all-files/gi/GiElectric";
import { GiPhone } from "@react-icons/all-files/gi/GiPhone";
import { GiSpikedFence } from "@react-icons/all-files/gi/GiSpikedFence";
import { GiStairs } from "@react-icons/all-files/gi/GiStairs";
import { GiDrinking } from "@react-icons/all-files/gi/GiDrinking";
import { GiWaterDrop } from "@react-icons/all-files/gi/GiWaterDrop";
import { GiPipes } from "@react-icons/all-files/gi/GiPipes";
import { GiFrozenOrb } from "@react-icons/all-files/gi/GiFrozenOrb";
import { GiWaterTank } from "@react-icons/all-files/gi/GiWaterTank";
import { GiPoolDive } from "@react-icons/all-files/gi/GiPoolDive";
import { GiHomeGarage } from "@react-icons/all-files/gi/GiHomeGarage";
import { GiCityCar } from "@react-icons/all-files/gi/GiCityCar";
import { GiArcTriomphe } from "@react-icons/all-files/gi/GiArcTriomphe";

export const internalSpecList = [
  {
    id: "bed",
    name: "Kamar Tidur",
    icon: (
      <Box fontSize={"1.5rem"}>
        <GiBed />
      </Box>
    ),
  },
  {
    id: "bath",
    name: "Kamar Mandi",
    icon: (
      <Box fontSize={"1.5rem"}>
        <GiBathtub />
      </Box>
    ),
  },
  {
    id: "guest",
    name: "Ruang Tamu",
    icon: (
      <Box fontSize={"1.5rem"}>
        <GiSofa />
      </Box>
    ),
  },
  {
    id: "dining",
    name: "Ruang Makan",
    icon: (
      <Box fontSize={"1.5rem"}>
        <GiSpoon />
      </Box>
    ),
  },
  {
    id: "family",
    name: "Ruang Keluarga",
    icon: (
      <Box fontSize={"1.5rem"}>
        <GiTv />
      </Box>
    ),
  },
  {
    id: "kitchen",
    name: "Dapur",
    icon: (
      <Box fontSize={"1.5rem"}>
        <GiChickenOven />
      </Box>
    ),
  },
  {
    id: "garden",
    name: "Teras / Taman",
    icon: (
      <Box fontSize={"1.5rem"}>
        <GiFlowerPot />
      </Box>
    ),
  },
  {
    id: "electicity",
    name: "Listrik",
    icon: (
      <Box fontSize={"1.5rem"}>
        <GiElectric />
      </Box>
    ),
  },
  {
    id: "telephone",
    name: "Telepon (Line)",
    icon: (
      <Box fontSize={"1.5rem"}>
        <GiPhone />
      </Box>
    ),
  },
  {
    id: "fence",
    name: "Pagar",
    icon: (
      <Box fontSize={"1.5rem"}>
        <GiSpikedFence />
      </Box>
    ),
  },
  {
    id: "floor",
    name: "Lantai",
    icon: (
      <Box fontSize={"1.5rem"}>
        <GiStairs />
      </Box>
    ),
  },
  {
    id: "lounge",
    name: "Lounge",
    icon: (
      <Box fontSize={"1.5rem"}>
        <GiDrinking />
      </Box>
    ),
  },
  {
    id: "water",
    name: "Air PAM / Sumur",
    icon: (
      <Box fontSize={"1.5rem"}>
        <GiWaterDrop />
      </Box>
    ),
  },
  {
    id: "wtp",
    name: "WTP",
    icon: (
      <Box fontSize={"1.5rem"}>
        <GiPipes />
      </Box>
    ),
  },
  {
    id: "ac_split",
    name: "AC Split",
    icon: (
      <Box fontSize={"1.5rem"}>
        <GiFrozenOrb />
      </Box>
    ),
  },
  {
    id: "ac_central",
    name: "AC Central",
    icon: (
      <Box fontSize={"1.5rem"}>
        <GiFrozenOrb />
      </Box>
    ),
  },
  {
    id: "watertank",
    name: "Toren / Tangki Air",
    icon: (
      <Box fontSize={"1.5rem"}>
        <GiWaterTank />
      </Box>
    ),
  },
];

export const externalSpecList = [
  {
    id: "pool",
    name: "Kolam Renang",
    icon: (
      <Box fontSize={"1.5rem"}>
        <GiPoolDive />
      </Box>
    ),
  },
  {
    id: "garage",
    name: "Garasi",
    icon: (
      <Box fontSize={"1.5rem"}>
        <GiHomeGarage />
      </Box>
    ),
  },
  {
    id: "carport",
    name: "Carport",
    icon: (
      <Box fontSize={"1.5rem"}>
        <GiCityCar />
      </Box>
    ),
  },
  {
    id: "pergola",
    name: "Pergola",
    icon: (
      <Box fontSize={"1.5rem"}>
        <GiArcTriomphe />
      </Box>
    ),
  },
];

export const communitySpecList = [
  {
    id: "pool",
    name: "Kolam Renang",
    icon: (
      <Box fontSize={"1.5rem"}>
        <GiPoolDive />
      </Box>
    ),
  },
];

export const listingType = [
  { id: "house", name: "Rumah", r123: "ho" },
  {
    id: "land",
    name: "Kavling / Tanah",
    r123: "la",
  },
  { id: "shop", name: "Ruko / Rukan", r123: "sh" },
  { id: "kiosk", name: "Kios", r123: "sh" },
  { id: "apartment", name: "Apartemen", r123: "ap" },
  { id: "villa", name: "Villa", r123: "ho" },
  { id: "warehouse", name: "Gudang", r123: "wa" },
  { id: "office", name: "Kantor", r123: "of" },
  { id: "other", name: "Lainnya", r123: "ho" },
];

export const certificateType = [
  { id: "shm", name: "SHM" },
  { id: "shgb", name: "SHGB" },
  { id: "hak", name: "Hak Pakai" },
  { id: "lain", name: "Lainnya" },
];

export const expenseType = [
  "Komisi Bank",
  "Komisi Balai Lelang",
  "Komisi Broker (Perorangan)",
  "Komisi Broker (Perusahaan)",
  "Budget Pengosongan",
].map((m) => ({ id: m, name: m }));
